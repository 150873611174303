import {aicoreBackendPetition} from "@/utils/aicore_petition";
import {showNotification} from "@/utils/aux_functions";
import store from "@/store";

export async function translateTextWS(type, text, language_from, language_to){
    let params = {
        text: text,
        source_language_iso: language_from,
        target_language_iso: language_to,
        translator: "GoogleCloudTranslations",
        is_html: (type !== 'texto'),
        is_xml: false
    }

    try {
        return await aicoreBackendPetition('GET', 'Translations/TextTranslations/BasicTextTranslator/translate_text', params, {content_type: 'application/json', nlp: true} )
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/Translator/translate_text -- /Translator/translate_html');
    }
}