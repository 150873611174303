<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" sm="9" md="9" lg="10">
                <span class="main-title-page">Generación de campos para ficha de producto</span>
                <p>El fichero que debe adjuntarse debe seguir la estructura de la plantilla que podrás encontrar
                    <a style="color:#F4A849;" href='/plantilla_fichas_producto.xlsx'
                       download='plantilla_fichas_producto.xlsx'
                    >
                        aquí
                    </a>
                    , recuerda eliminar el curso de ejemplo.
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form class="editor" @submit.prevent="">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <span>Importe el documento: </span>
                                <v-file-input
                                    label="Importe el documento"
                                    v-model="form_data.imported_file"
                                    hint="Solo se admiten ficheros .csv, .xls, .xlsx"
                                    persistent-hint
                                    accept=".csv,.xls,.xlsx"
                                    class="text-field-primary field-with-label"
                                    variant="underlined"
                                    prepend-icon="mdi-paperclip"
                                    :class="{error :form_error.imported_file}"
                                    @click:clear='clearInput()'
                                ></v-file-input>
                                <span v-if="form_error.imported_file" class="input-error msg-error">
                                    Introduzca un fichero válido
                                </span>
                            </v-col>
                            <v-col cols="12">
                                <span>Prioridad del elemento: </span>
                                <v-text-field
                                    placeholder="Prioridad"
                                    v-model="form_data.priority"
                                    variant="underlined"
                                    class="text-field-primary field-with-label"
                                    :class="{error :form_error.priority}"
                                    type="number"
                                    min="0"
                                    hint="A mayor el número mayor será su prioridad a la hora de procesarse"
                                    persistent-hint
                                ></v-text-field>
                                <span v-if="form_error.priority" class="input-error msg-error">
                                    Introduzca un número entero mayor que 0
                                </span>
                            </v-col>
                            <v-col cols="4">
                                <span>Idioma en el que generar: </span>
                                <SelectTertiary
                                    :items="all_languages"
                                    placeholder="Idioma"
                                    v-model="form_data.language_id"
                                    :class="{error :form_error.language_id}"
                                    item-title="nombre"
                                    item-value="id_idioma"
                                >
                                </SelectTertiary>
                                <span v-if="form_error.language_id" class="input-error msg-error">
                                    Seleccione un idioma
                                </span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-center">
                                <v-btn @click="sendForm"
                                       class="btn button-primary btn-xl ml-3"
                                       :loading="loading">
                                    Generar
                                </v-btn>
                                <p v-if="loading">Generando contenido... Esto puede tardar varios minutos</p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {generateCourseFieldsWS} from "@/utils/courses";
import {fireInfoAlert, fireSweetAlert, initInfoAlert, initSweetAlert} from "@/utils/sweetalert";
import {viewHtml} from "@/utils/full_sreen_notification";
import SelectTertiary from "@/components/Generic/SelectTertiary.vue";
import {useStore} from "vuex";
import {getAllLanguagesWS} from "@/utils/language";

export default {
    name:'GenerateCourseFieldsView',
    components: {SelectTertiary},
    methods: {variableIsDefined},
    setup(){
        const store = useStore();

        let form_data = ref({threads: true, imported_file: null});
        let form_error = ref({});
        let loading = ref(false);
        let all_languages = ref([]);


        onBeforeMount(async () => {
            initInfoAlert();
            await getAllLanguages()
        })

        async function getAllLanguages() {
            let response = await getAllLanguagesWS({'active': 1})
            if (response['result']) {
                all_languages.value = response['data']
                form_data.value.language_id = 3
            } else {
                showNotification('error', response['errors'])
            }
        }

        const clearInput = () => {
            form_data.value.imported_file = null
        }

        const sendForm = async () => {
            let correct = true;
            loading.value = true
            form_error.value = {}

            Object.keys(form_data.value).forEach((item_key)=> {
                if (!variableIsDefined(form_data.value[item_key]) ){
                    correct = false
                    form_error.value[item_key] = true
                }
            })

            if (form_data.value.priority <= 0){
                correct = false
                form_error.value['priority'] = true
            }

            if (correct){
                let response = await generateCourseFieldsWS(form_data.value)

                if(response['status'] === 200){
                    if (response['data']['result']){
                        showNotification('success', "Elemento añadido a la cola de procesamiento")
                    }else{
                        showNotification('success', response['data']['errors'])
                    }

                }else{
                    let html_text = `<div style="text-align: left">
                        <p style="font-size: 16px">El fichero no se ha subido correctamente</p>
                        </div>`
                    await fireInfoAlert('Error en el fichero', html_text);
                }
            }
            loading.value = false
        }
        return{
            clearInput,
            sendForm,

            loading,
            form_data,
            form_error,
            all_languages,
        }
    }
}

</script>

<style scoped lang="scss">

</style>