<template>
    <v-container>
        <div id="header">
            <v-row class="inverter-position">
                <v-col cols="12" sm="4" md="3" class="text-end">
                    <a class="btn button-primary" href="/manual_generar_mapa_conceptual.pdf"
                       download="Manual_uso_mapa_conceptual">
                        MANUAL DE USO </a>
                </v-col>
                <v-col cols="12" sm="8" md="9">
                    <span class="main-title-page">
                        <img :src="require('@/assets/icons/navbar/diagram.svg')" alt="icono de mapa conceptual" width="28" height="28">
                        Generador mapa conceptual
                    </span>
                </v-col>

            </v-row>
        </div>

        <div id="structure-map">
            <v-row>
                <v-col cols="12">
                    <v-form>
                        <div>
                            <textarea
                                id="treeEntry"
                                placeholder="Introduce Índice"
                                class="form-control textarea textarea-map personalice-text-area"
                                :class="{personalize_text_area_error: error_textarea}"
                            >
                            </textarea>
                        </div>
                    </v-form>
                    <span v-if="error_textarea" class="input-error msg-error">
                        {{error_message['only_errors'] || error_message['only_line_errors'] || error_message['empty_error'] }}
                    </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <div class="col-btn-create-and-clean">
                        <v-btn id="limpiarTexto" class="btn button-primary btn-personalize-clean" type="reset"
                               @click="cleanTextMaps">
                            Limpiar Mapa
                        </v-btn>
                        <v-btn id="crear-mapa" class="btn button-bg-blue btn-personalize-create" @click="generateMaps">
                            Crear Mapa
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div class="map-view">
            <div id="horizontal-map">
                <v-row>
                    <v-col cols="12" sm="6">
                        <span class="title-map">Mapa Vertical</span>
                    </v-col>
                    <v-col cols="12" md="6" class="text-end">
                        <v-btn class="btn button-bg-white" id="descargar-mapa-vertical"
                               @click="printMapsV">Descargar
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div class="container-map" id="vertical-primary-diagram">
                            <div id="vertical-second-diagram"></div>
                        </div>
                    </v-col>
                </v-row>

            </div>

            <div id="vertical-map">
                <v-row>
                    <v-col cols="12" sm="6">
                        <span class="title-map">Mapa Horizontal</span>
                    </v-col>
                    <v-col cols="12" md="6" class="text-end">
                        <v-btn class="btn button-bg-white" id="descargar-mapa-horizontal"
                               @click="printMapsH">Descargar
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div class="container-map" id="horizontal-primary-diagram">
                            <div id="horizontal-second-diagram"></div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>
    </v-container>
</template>

<script>
import {onBeforeMount, onMounted, ref} from "vue";
import {
    runMapsVertical,
    runMapsHorizontal,
    cleanTextMaps, printMapsV, printMapsH, showError
} from "@/utils/conceptual_maps";

import {variableIsDefined} from "@/utils/aux_functions";
import {hasPermission} from "@/utils/auth";
import {useRoute} from "vue-router";


export default {
    name: "ConceptualMapView",
    setup() {
        const route = useRoute();
        const error_textarea = ref(false)
        const error_message = ref({})

        onBeforeMount(async () => {
            await hasPermission(route);
        })

        onMounted(async () => {
            $(".textarea-map").keydown(function (e) {
                if (e.keyCode === 9) { // Se presiona el tabulador
                    // obtener posición/selección del cursor
                    let start = this.selectionStart;
                    let end = this.selectionEnd;
                    let $this = $(this);
                    /*establecer el valor del área de text en: text antes del signo de intercalación + tabulador + text después del signo de intercalación*/
                    $this.val($this.val().substring(0, start) +
                        "\t" +
                        $this.val().substring(end));
                    /*volver a poner el signo de intercalación en la posición correcta*/
                    this.selectionStart = this.selectionEnd = start + 1;
                    // prevent the focus lose
                    return false;
                }
            });
        })

        function generateMaps() {
            let content_text_area = $(".textarea-map")[0].value.trim();
            error_textarea.value = false
            error_message.value = {}

            if (content_text_area.length > 0) {
                $('.map-view').show()
                runMapsVertical()
                runMapsHorizontal()

                //OBTENER LOS ERRORES
                const {only_errors, only_line_errors} = showError()
                error_message.value['only_errors'] = only_errors
                error_message.value['only_line_errors'] = only_line_errors

                if(variableIsDefined(only_errors) || variableIsDefined(only_line_errors)){
                    error_textarea.value = true
                }
            } else {
                error_message.value['empty_error'] = 'El texto está vacío'
                error_textarea.value = true
            }

        }

        return {
            cleanTextMaps,
            error_message,
            error_textarea,
            generateMaps,
            printMapsH,
            printMapsV,
        }
    },
}
</script>

<style lang="scss">

@import "@/styles/views/conceptual-map.scss";
</style>

