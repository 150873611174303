<template>
    <v-select
        v-model="model_value"
        v-model:menu="menu_model_value"
        :menu-icon="null"
        :placeholder="placeholder"
        :items="items"
        :item-title="itemTitle"
        :item-value="itemValue"
        :multiple="multiple"
        :menu-props="select_menu_props"
        :disabled="disabled"
        :chips="multiple"
        :closable-chips="multiple"
        class="select-secondary chips-selector"
        hide-details
        bg-color="white"
        no-data-text="No hay datos disponibles"
    >
        <template #append-inner>
            <v-icon>{{ menu_model_value ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
        </template>

        <template #chip="{props, item}" v-if="multiple">
            <slot name="chip" :item="item" :props="props">
                <v-chip
                    v-bind="props"
                    class="custom-chip"
                >
                    <template #close>
                        <v-icon icon="mdi-close"></v-icon>
                    </template>

                    <span> {{ item.title }} </span>
                </v-chip>
            </slot>
        </template>

        <template #selection="{props, item}">
            <slot name="selection" :item="item" :props="props">
                <span> {{ item.title }} </span>
            </slot>
        </template>

        <template #item="{props, item}">
            <slot name="item" :item="item" :props="props">
                <v-list-item
                    v-bind="props"
                >
                    <template #title>
                        <span> {{ item.title }} </span>
                    </template>
                    <template #append v-if="itemIsSelected(item) && multiple">
                        <v-icon color="blue" icon="mdi-check"></v-icon>
                    </template>
                </v-list-item>
            </slot>

        </template>
    </v-select>

</template>

<script setup>
import {ref, defineModel} from "vue";

const props = defineProps({
    placeholder: String,
    itemTitle: String,
    itemValue: String,
    select_menu_props: {
        type: Object,
        default: {
            maxWidth: '100%',
            minWidth: 'auto'
        }
    },
    items: Array,
    prepend_image: String,
    multiple: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

let menu_model_value = ref(false)
let model_value = defineModel()

const itemIsSelected = (item) => {
    return Array.isArray(model_value.value) ?
        model_value.value.includes(item.value) :
        model_value.value === item.value
}
</script>

<style lang="scss">
.chips-selector {
    .custom-chip {
        border: 1px solid $general-elements-hover;

        .v-chip__underlay {
            background: transparent !important;
        }

        .v-chip__close {
            .v-icon {
                transform: none !important;
                color: $general-elements-hover !important;
            }
        }
    }
}
</style>