<template>
    <v-row v-if="all_img_active.length>0 || all_img_desactive.length>0">
        <v-col>
            <v-expansion-panels>
                <v-expansion-panel class="expansion-personalized">
                    <v-expansion-panel-title>Seleccione una imagen *</v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-row class="box-general-img personalize-scroll">
                            <v-col cols="12">
                                <span class="input-title">Imágenes activas:</span>
                            </v-col>
                            <v-col v-if="all_img_active.length > 0" v-for="image in all_img_active" cols="12" sm="6"
                                   md="4" lg="3" class="box-files">
                                <v-checkbox v-model="form_data.img_selected" class="check-active"
                                            :value="image.id_file" hide-details>
                                    <template v-slot:label>
                                        <v-row>
                                            <v-col cols="12" class="box-title">
                                                <span class="title-check-customization"> {{ image.fileName }}</span>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-tooltip location="bottom">
                                                    <template v-slot:activator="{ props }">
                                                        <div class="box-img">
                                                            <v-img class="image-check-customization"
                                                                   :src=image.public_route></v-img>
                                                        </div>
                                                    </template>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-checkbox>
                            </v-col>
                            <v-col v-else><span>No hay imágenes activadas</span></v-col>

                            <v-col cols="12">
                                <span class="input-title">Imágenes inactivas:</span>
                            </v-col>
                            <v-col v-if="all_img_desactive.length>0" v-for="image in all_img_desactive" cols="12" sm="6"
                                   md="4" lg="3"
                                   class="box-files">
                                <v-checkbox v-model="form_data.img_selected" class="check-active"
                                            :value="image.id_file" hide-details :disabled="true">
                                    <template v-slot:label>
                                        <v-row>
                                            <v-col cols="12" class="box-title">
                                                <span class="title-check-customization"> {{ image.fileName }}</span>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-tooltip location="bottom">
                                                    <template v-slot:activator="{ props }">
                                                        <div class="box-img">
                                                            <v-img class="image-check-customization"
                                                                   :src=image.public_route></v-img>
                                                        </div>
                                                    </template>
                                                </v-tooltip>
                                            </v-col>

                                        </v-row>

                                    </template>
                                </v-checkbox>

                            </v-col>
                            <v-col v-else><span>No hay imágenes desactivadas</span></v-col>
                        </v-row>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            <span v-if="form_error.img_selected"
                  class="input-error msg-error">Debe seleccionar una imagen</span>
        </v-col>
    </v-row>
    <v-row v-else>
        <v-col>
            <span>No hay imágenes de {{ type_entity.related_entity }} para el portal seleccionado</span>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="6">
            <span class="input-title">Título *</span>
            <v-text-field variant="underlined"
                          :class="{error :form_error.title}"
                          class="input-error text-field-primary"
                          v-model.trim="form_data.title"
                          :error="form_error.title"
                          hide-details
            ></v-text-field>
            <span v-if="form_error.title"
                  class="input-error msg-error">El campo no puede estar vacío</span>
        </v-col>
        <v-col cols="12" md="6">
            <span class="input-title">Texto alternativo *</span>
            <v-text-field variant="underlined"
                          :class="{error :form_error.txt_alt}"
                          class="input-error text-field-primary"
                          v-model.trim="form_data.txt_alt"
                          :error="form_error.txt_alt"
                          hide-details
            ></v-text-field>
            <span v-if="form_error.txt_alt"
                  class="input-error msg-error">El campo no puede estar vacío</span>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="6">
          <span class="input-title">Tags *</span>
          <v-select
              class="input-error select-primary"
              v-model="form_data.id_tag"
              :items="all_tags"
              item-title="tag"
              item-value="id_tag"
              hide-details
              bg-color="white"
              color="transparent"
              variant="underlined"
              :error="form_error.id_tag"
              :menu-props="{
                  minWidth: 'auto',
                  maxWidth: '100%'
              }"
              no-data-text="No hay datos disponibles"
          ></v-select>
          <span v-if="form_error.id_tag"
                class="input-error msg-error">El campo no puede estar vacío</span>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="6">
            <span><b>Portal: </b> {{ getNamePortal(store.getters.getPortalID) }}</span>
            <span class="ms-4"><b>Idioma: </b> {{ getNameLanguage(store.getters.getLanguageID) }}</span>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <div style="display: inline-block; width: auto ">
                <v-checkbox label="Añadir portales e idiomas"
                            v-model="open_multiple_portal"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                            @click="openMultiplePortal"
                            hide-details></v-checkbox>
            </div>
        </v-col>
        <v-col v-if="contains_data">
            <div style="display: inline-block; width: auto ">
                <v-checkbox label="Estado"
                            v-model="form_data.status"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                            hide-details></v-checkbox>
            </div>
        </v-col>
    </v-row>
    <template v-if="multiple_portal_customize">
        <div id="new_degree" v-for="field in fields.keys()" class="box-options">
            <DegreeCustomization
                :all_img_active="all_img_active"
                :type_entity="type_entity"
                :increaseID="increaseID"
                :id_field="field"
                :key="field"
                :fields="fields"
                :all_portals="all_portals"
                :contains_data="contains_data"
                :degree_data="degree_data"
                :all_tags="all_tags"
                ref="customization_ref"
            />
        </div>
    </template>

    <v-row>
        <v-col cols="12" class="text-end">
            <v-btn class="btn button-bg-blue" @click="saveDegree" :loading="loading_save_degree">
                Guardar
            </v-btn>
        </v-col>
    </v-row>
</template>


<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {computed, onBeforeMount, ref} from "vue";
import {manageWSResponseMultiple, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {getFilesByEntityWS} from "@/utils/image_library";
import Loading from "@/components/Generic/Loading.vue";
import ImageCard from "@/components/ImagesLibrary/ImageCard.vue";
import {getNameLanguage, getNamePortal} from "@/utils/portal";
import DegreeCustomization from "@/components/CrudDegrees/DegreeCustomization.vue";
import RowFormRedirection from "@/components/Redirections/RowFormRedirection.vue";
import CheckBoxesImage from "@/components/Generic/CheckBoxesImage.vue";
import * as Yup from "yup";
import {getDegreeDataWS, getDegreeTagsWS, insertDegreeWS, updateDegreeWS} from "@/utils/crud_degrees";
import {fireInfoAlert, initInfoAlert} from "@/utils/sweetalert";
import {viewHtml} from "@/utils/full_sreen_notification";
import {useStore} from "vuex";
import store from "../../store";

export default {
    name: "ScreenCrudDegree",
    computed: {
        store() {
            return store
        }
    },
    methods: {getNameLanguage, getNamePortal, variableIsDefined},
    components: {CheckBoxesImage, RowFormRedirection, DegreeCustomization, ImageCard, Loading, BaseModalTemplate},
    props: {
        openCloseDialog: Function,
        getDegreesSelected: Function,
        data_degree: Object,
        type_entity: Object,
    },
    setup(props) {
        let store = useStore();
        let all_portals = computed(() => store.getters.getStoredPortals).value
        let general_loading = ref(false)
        let loading_save_degree = ref(false)
        let form_data = ref({})
        let form_error = ref({})
        let all_img_active = ref([])
        let all_img_desactive = ref([])
        let fields = ref(new Map())
        let degree_data = ref({})
        let key_count = 0
        let contains_data = ref(false)
        let open_multiple_portal = ref(false)
        let multiple_portal_customize = ref(false)
        let response_true_save_degree = []
        let customization_ref = ref(null)
        let all_tags = ref([]);

        onBeforeMount(async () => {
            general_loading.value = true
            initInfoAlert()
            if (variableIsDefined(props.data_degree)) {
                await getParentData()
            } else {
                contains_data.value = false
                clearMap()
                await getDegreeTags()
            }
            await getAllImage()
            general_loading.value = false
        })

        /*OBTIENE LOS DATOS EN EL CASO QUE SEA EDITAR*/
        async function getParentData() {
            await getDegreeData()
            await getDegreeTags()

            contains_data.value = true
            form_data.value = {
                'title': degree_data.value.title,
                'txt_alt': degree_data.value.alt_text,
                'img_selected': degree_data.value.id_file,
                'status': degree_data.value.activo,
                'id_tag': degree_data.value.id_tag
            }

            if (variableIsDefined(degree_data.value['personalizaciones'])) {
                open_multiple_portal.value = 1
                multiple_portal_customize.value = true
                let map_multiple_degree = []
                let multiple_degree = degree_data.value['personalizaciones']
                multiple_degree.forEach((degree) => {
                    map_multiple_degree.push({
                        "id_portal": degree.id_portal,
                        "language": degree.language,
                        "id_file": degree.id_file,
                        "alt_text": degree.alt_text,
                        "title": degree.title,
                        "activo": degree.activo,
                        "id_tag": degree.id_tag,
                        "locked": true,
                    })
                })
                map_multiple_degree.forEach((degree_obj) => {
                    fields.value.set(key_count, degree_obj)
                    key_count++
                })
                fields.value.set(key_count, '');
            }
        }

        /*OBTIENE LOS DATOS DE UN DEGREE CONCRETO*/
        async function getDegreeData() {
            let id_degree
            if (props.type_entity.related_entity === 'logos') {
                id_degree = props.data_degree.id_logo
            } else {
                id_degree = props.data_degree.id_diploma
            }
            let response = await getDegreeDataWS(id_degree, props.type_entity.related_entity)
            if (response['result']) {
                degree_data.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
        }

        /*OBTIENE TODAS LAS IMAGENES*/
        async function getAllImage() {
            let response = await getFilesByEntityWS(props.type_entity.related_entity);
            if (response['result']) {
                if (variableIsDefined(response['data'])) {
                    response['data'].forEach((file) => {
                        if (file.active === 1) {
                            all_img_active.value.push(file)
                        }
                        if (file.active === 0) {
                            all_img_desactive.value.push(file)
                        }
                    })
                }
            } else {
                showNotification('error', response['errors']);
            }
        }

      /*OBTIENE LOS TAGS DE UN DEGREE*/
      async function getDegreeTags() {
        let response = await getDegreeTagsWS()
        if (response['result']) {
          all_tags.value = response['data']
        } else {
          showNotification('error', response['errors'])
        }
      }

        /*INCREMENTA LA CLAVE DEL MAPA*/
        function increaseID() {
            let count_before = key_count;
            key_count++;

            return count_before;
        }

        /*BORRA EL MAPA*/
        const clearMap = () => {
            fields.value.clear()
            fields.value.set(0, '');
            key_count = 0
        }

        function validateFormErrorCustomization() {
            if (variableIsDefined(customization_ref.value)) {
                return !customization_ref.value.some(customization => Object.keys(customization.form_error).length > 0);
            } else {
                return true
            }
        }

        /*GUARDA LOS DATOS*/
        async function saveDegree() {
            let customization_degree = []
            response_true_save_degree = []
            if (await validate()) {
                if (validateFormErrorCustomization()) {
                    loading_save_degree.value = true
                    /*SI TIENE PERSONALIZACIONES*/
                    if (fields.value.size !== 0) {
                        for (let [key, value] of fields.value.entries()) {
                            if (variableIsDefined(value)) {
                                customization_degree.push({
                                    'id_portal': value.id_portal,
                                    'language': value.language,
                                    'title': value.title ?? null,
                                    'id_file': value.id_file ?? null,
                                    'alt_text': value.alt_text ?? null,
                                    'active': value.active ?? 1,
                                    'id_tag': value.id_tag ?? null
                                })
                            }
                        }
                    }
                    let main_form = {
                        'id_file': form_data.value.img_selected,
                        'alt_text': form_data.value.txt_alt,
                        'title': form_data.value.title,
                        'id_tag': form_data.value.id_tag
                    }
                    if (contains_data) {
                        main_form['active'] = form_data.value.status
                    }


                    /*UPDATE*/
                    if (variableIsDefined(props.data_degree)) {
                        let id_degree
                        if (props.type_entity.related_entity === 'logos') {
                            id_degree = props.data_degree.id_logo
                        } else {
                            id_degree = props.data_degree.id_diploma
                        }
                        let response = await updateDegreeWS(id_degree, main_form, JSON.stringify(customization_degree), props.type_entity.related_entity)
                        let managed_response = await manageWSResponseMultiple(response)

                        loading_save_degree.value = false

                        if (managed_response.length > 0) {
                            /*LLAMAMOS A LA VISTA DE LA NOTIFICACIÓN COMPLETA*/
                            await fireInfoAlert('Aviso', viewHtml(managed_response))
                        }
                    }

                    /*INSERT*/
                    else {
                        let response = await insertDegreeWS(main_form, JSON.stringify(customization_degree), props.type_entity.related_entity)
                        if (response['result']) {
                            if (response['data']['success']) {
                                showNotification('success', response['data']['message'])
                            } else {
                                showNotification('error', response['data']['message'])
                            }
                        } else {
                            showNotification('error', response['errors'])
                        }
                        loading_save_degree.value = false
                    }

                    props.openCloseDialog()
                    props.getDegreesSelected()
                    clearMap()

                }
            }
        }


        /*VALIDACIÓN*/
        async function validate() {
            form_error.value = {}
            const schemaForm = Yup.object().shape({
                img_selected: Yup.mixed()
                    .nullable()
                    .notOneOf([false], true)
                    .notOneOf([undefined], true),
                title: Yup.string().required(true),
                txt_alt: Yup.string().required(true),
                id_tag: Yup.string().required(true),
            });
            try {
                await schemaForm.validate(form_data.value, {abortEarly: false});
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = error.message;
                })
            }
            return Object.keys(form_error.value).length <= 0;
        }

        function openMultiplePortal() {
            multiple_portal_customize.value = open_multiple_portal.value !== 1;
            fields.value.set(key_count, '');
        }

        return {
            openMultiplePortal,
            increaseID,
            saveDegree,

            general_loading,
            loading_save_degree,
            all_portals,
            form_data,
            form_error,
            all_img_active,
            all_img_desactive,
            all_tags,
            fields,
            contains_data,
            degree_data,
            open_multiple_portal,
            multiple_portal_customize,
            customization_ref,
        }
    }
}
</script>


<style scoped lang="scss">
@import "@/styles/components/degree-customization.scss";
</style>