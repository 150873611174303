<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" sm="9" md="9" lg="10">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/course-version.svg')" alt="icono de gestion versiones" width="28" height="28">
                    Gestión versiones cursos
                </span>
            </v-col>
        </v-row>
        <template v-if="general_loading">
            <Loading/>
        </template>
        <template v-else>
            <v-row align="center">
                <v-col cols="12" md="8">
                    <v-form @submit.prevent="searchURL">
                        <v-text-field
                            class="text-field-secondary"
                            v-model="filter_search.url_search"
                            label="Buscar por url..."
                            append-inner-icon="mdi-magnify"
                            single-line
                            bg-color="white"
                            color="transparent"
                            hide-details
                        ></v-text-field>
                    </v-form>
                </v-col>
                <v-col cols="12" md="4">
                    <SelectTertiary
                        label="Tipo de acción"
                        class="field-with-label"
                        v-model="filter_search.action_type"
                        @update:modelValue="searchActionType"
                        :items="['--Todos--', 'Actualizar', 'Nuevo', 'Desactivar']"
                        :disabled="loading"
                        :default_values="{title: '--Todos--'}"
                    >
                    </SelectTertiary>
                </v-col>
              <v-col cols="12" md="4">
              <SelectTertiary
                  label="Filtrar por portal"
                  class="field-with-label"
                  v-model="filter_search.selected_portal"
                  @update:modelValue="searchActionType"
                  :items="portals"
                  item-title = "nombre"
                  item-value = "id_portal"
                  :disabled="loading"
              >
              </SelectTertiary>
            </v-col>
<!--                            <v-col cols="4" xs="3">-->
<!--                                <v-btn variant="outlined" class="btn button-primary" @click="exportData"-->
<!--                                       :disabled="disabled_export">-->
<!--                                    Exportar página actual-->
<!--                                </v-btn>-->
<!--                            </v-col>-->
            </v-row>
            <v-row>
                <v-col cols="12">
                    <Vue3EasyDataTable
                        table-class-name="primary-table"
                        theme-color="#F4A649"
                        buttons-pagination
                        :headers="headers"
                        :items="items"
                        v-model:server-options="server_options"
                        :server-items-length="server_items_length"
                        :loading="loading"
                        rowsPerPageOptions="10"
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                    >

                        <template #item-icon_show="{id_cambios_curso, id_portal}">

                            <img :src="require('@/assets/icons/eye-simple.svg')"
                                 alt="Icono ojo"
                                 class="icon-data-table"
                                 title="Ver cambios"
                                 @click="showChanges(id_cambios_curso, id_portal)"
                            >
                        </template>

                    </Vue3EasyDataTable>
                </v-col>
            </v-row>
            <v-dialog
                v-model="open_modal" persistent>
                <DataComparative
                    :new_data="new_data"
                    :old_data="old_data"
                    :displayModal="displayModal"
                    :item_changes="item_changes"
                    :getURLsByPortal="getURLsByPortal"
                />
            </v-dialog>
        </template>
    </v-container>

</template>

<script>
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import {onBeforeMount, ref, watch,computed} from "vue";
import {exportXLSX, showNotification} from "@/utils/aux_functions";
import {
    getCourseChanges,
    getDuplicatedURLsWS,
} from "@/utils/courses";
import {useRoute} from "vue-router";
import {hasPermission} from "@/utils/auth";
import ModalUrl from "@/components/UrlList/ModalUrl.vue";
import DataComparative from "@/components/Course/DataComparative.vue";
import Loading from "@/components/Generic/Loading.vue";
import {useStore} from "vuex";
import SelectTertiary from "@/components/Generic/SelectTertiary.vue";



export default {
    name: "CoursesVersionView",
    components: {
      SelectTertiary,
        Loading,
        DataComparative,
        ModalUrl,
        Vue3EasyDataTable,
    },

    setup() {
        const route = useRoute();
        const store = useStore();
        let general_loading = ref(false)
        let loading = ref(false);
        let server_items_length = ref(0);
        let items = ref([]);
        let filter_search = ref({url_search: "",selected_portal : null, action_type: "--Todos--"});
        let open_modal = ref(false);
        let new_data = ref([])
        let old_data = ref([])
        let item_changes = ref()
        let disabled_export = ref(true);
        let portals = computed(() => store.getters.getStoredPortals).value;

        const headers = ref([
            {text: 'Código Innotutor', value: 'codigo_innotutor'},
            {text: 'ID Simo', value: 'id_simo'},
            {text: 'ID Cambio', value: 'id_cambios_curso'},
            {text: 'ID Portal', value: 'id_portal'},
            {text: 'Identificador Texto URL', value: 'identificador_texto_url'},
            {text: 'Fecha', value: 'fecha_accion'},
            {text: 'Tipo accion', value: 'tipo_accion'},
            {text: 'VER CAMBIOS', value: 'icon_show'},
        ]);

        let server_options = ref({
            page: 1,
            rowsPerPage: 20
        });

        onBeforeMount(async () => {
            general_loading.value = true
            await hasPermission(route);
            await getURLsByPortal();
            general_loading.value = false
        });

        watch(server_options, async () => {
                await getURLsByPortal();
            },
            {deep: true}
        );

        async function getURLsByPortal() {
            loading.value = true;
            let response_all_urls = await getDuplicatedURLsWS(server_options.value.page, server_options.value.rowsPerPage, filter_search.value);
            if (response_all_urls['data'] && response_all_urls['result']) {
                items.value = response_all_urls['data'].data;
                server_items_length.value = response_all_urls['data'].total;
            } else {
                showNotification("error", response_all_urls['errors'])
            }
            disabled_export.value = server_items_length.value === 0
            loading.value = false
        }

        async function searchURL() {
            server_options.value.page = 1;
            await getURLsByPortal();
        }

        async function searchActionType() {
            await getURLsByPortal();
        }

        async function showChanges(id_change, id_portal) {
            new_data.value = [];
            old_data.value = [];
            item_changes.value = {
                'id_change': id_change,
                'id_portal': id_portal
            }
            let response = await getCourseChanges(item_changes.value);
            if (response['result']) {
                new_data.value = response['data']['new'];
                old_data.value = response['data']['old'];
                displayModal();
            } else {
                showNotification('error', response['errors'])
            }
        }

        function displayModal() {
            open_modal.value = !open_modal.value
        }


        function exportData() {
            exportXLSX("Cursos", "Cursos duplicados.xlsx", items.value)
        }

        return {
            searchURL,
            getURLsByPortal,
            displayModal,
            showChanges,
            exportData,
            searchActionType,
            portals,

            general_loading,
            open_modal,
            headers,
            items,
            loading,
            filter_search,
            server_options,
            server_items_length,
            new_data,
            old_data,
            item_changes,
            disabled_export
        }
    }
}
</script>

<style lang="scss">
.icon-data-table {
    width: 30px;
    height: auto;
    cursor: pointer;

    &:hover {
        filter: brightness(0.5);
    }
}

</style>