<template>
    <BaseModalTemplate
        title="Urls encoladas en el documento"
        :openCloseDialog="openCloseDialog"
        :hide_actions="true"
    >
        <template #body-modal-content>
            <Vue3EasyDataTable
                class="primary-table"
                :headers="headers"
                :items="items"
                :loading="loading"
                v-model:server-options="server_options"
                :server-items-length="server_items_length"
                buttons-pagination
                rows-per-page-message="Elementos por página: "
                rows-of-page-separator-message="de"
                empty-message="No hay datos disponibles"
            >
                <template #item-generated_at="{generated_at}">
                    <span id="prueba">{{ formatDate(generated_at) }}</span>
                </template>

                <template #item-updated_at="{updated_at}">
                    <span>{{ formatDate(updated_at) }}</span>
                </template>
            </Vue3EasyDataTable>
        </template>
    </BaseModalTemplate>
</template>

<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {onBeforeMount, ref, watch} from "vue";
import {queueCourseFieldsElementTableWS} from "@/utils/courses";
import {formatDate, showNotification} from "@/utils/aux_functions";
import Loading from "@/components/Generic/Loading.vue";
export default {
    name: "QueueCourseFieldsUrls",
    methods: {
        formatDate
    },
    components: {Loading, Vue3EasyDataTable, BaseModalTemplate},
    props: {
        openCloseDialog: Function,
        selected_element: Number,
    },
    setup(props) {
        let headers = ref([
            {text: 'ID lote', value: 'id_lote'},
            {text: 'ID cola', value: 'id_queue', sortable: true},
            {text: 'URL', value: 'identificador_texto_url', sortable: true},
            {text: 'Código', value: 'codigo', sortable: true},
            {text: 'Generado', value: 'generated_at', sortable: true},
            {text: 'Actualizado', value: 'updated_at', sortable: true},
            {text: 'Reintentos', value: 'reintentos', sortable: true},
        ])
        let loading = ref(false)
        let items = ref([])
        let server_items_length = ref(0)
        let server_options = ref({
            page: 1,
            rowsPerPage: 20,
            sortBy: 'reintentos',
            sortType: 'desc',
        })

        let filter_search = ref({})

        onBeforeMount(async () => {
            await loadQueueElementData()
        })

        watch(server_options, async () => {
                await loadQueueElementData()
            },
            {deep: true}
        );

        const loadQueueElementData = async () => {
            loading.value = true
            const {sortBy, sortType} = server_options.value

            filter_search.value['orderBy'] = sortBy
            filter_search.value['orderDirection'] = sortType
            let response = await queueCourseFieldsElementTableWS(server_options.value.page, server_options.value.rowsPerPage, props.selected_element, filter_search.value)

            if (Object.hasOwn(response, 'status')){
                response = response['data'];
            }

            if (response['result'] && response['data']) {
                items.value = response['data'].data;
                server_items_length.value = response['data'].total;

            } else {
                showNotification("error", response['errors'])
            }

            loading.value = false
        }

        return {
            loading,
            headers,
            items,
            server_options,
            server_items_length
        }
    }
}
</script>

<style scoped lang="scss">

</style>