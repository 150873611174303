<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" sm="8" md="8" lg="9">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/library.svg')" alt="icono de traducción" width="28" height="28">
                    {{ screen_title }}
                </span>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="3" class="text-end" v-if="!pending_language && !general_loading">
                <v-btn class="btn button-bg-blue" @click="selectImage(null,'create')">
                    {{ btn_screen_title }}
                </v-btn>
            </v-col>
        </v-row>
        <template v-if="general_loading">
            <Loading/>
        </template>
        <template v-else>
            <template v-if="pending_language">
                <v-row>
                    <v-col cols="12">
                        <span>Seleccione un idioma para continuar.</span>
                    </v-col>
                </v-row>
            </template>
            <template v-else>
                <template v-if="!display_dialog_modal_crud">
                    <v-row>
                        <v-col cols="12" xs="12" md="4" lg="4">
                            <SelectTertiary
                                :items="select_options"
                                v-model="img_entity"
                                item-title="title"
                                return-object
                                placeholder="Seleccione un tipo de imagen"
                                @update:modelValue="getDegreesSelected"
                                :default_values="img_entity"
                            >
                            </SelectTertiary>
                        </v-col>
                    </v-row>
                    <template v-if="loading_get_degrees">
                        <Loading class_loading="loading-gif-s"/>
                    </template>
                    <template v-else>
                        <v-row v-if="all_degrees_selected.length>0">
                            <v-col cols="12" sm="4" lg="3" v-for="image in all_degrees_selected">
                                <ImageCard
                                    :image="image"
                                    :show_btns="true"
                                    :selectImage="selectImage"
                                >
                                    <template #actions-imagen-card>
                                        <v-row>
                                            <v-col cols="12" class="btn-col">
                                                <v-btn class="btn-edit" @click="selectImage(image,'edit')"></v-btn>
                                                <v-btn :class="image.activo === 0 ? 'btn-enable' : 'btn-disable'"
                                                       @click="selectImage(image,'status')"></v-btn>
                                                <v-btn class="btn-delete" @click="selectImage(image,'delete')"></v-btn>

                                            </v-col>
                                        </v-row>
                                    </template>
                                </ImageCard>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col>
                                <span>No hay {{ img_entity.related_entity }} para el portal seleccionado</span>
                            </v-col>
                        </v-row>
                    </template>
                </template>
            </template>
            <template v-if="display_dialog_modal_crud">
                <ScreenCrudDegree
                    :openCloseDialog="displayDialogModalCrud"
                    :data_degree="selected_image"
                    :type_entity="img_entity"
                    :getDegreesSelected="getDegreesSelected"
                ></ScreenCrudDegree>
            </template>
        </template>
    </v-container>
</template>

<script>
import Loading from "@/components/Generic/Loading.vue";
import {computed, onBeforeMount, ref, watch} from "vue";
import {deleteDegreeWS, getAllDegreesWS, updateDegreeWS} from "@/utils/crud_degrees";
import {showNotification} from "@/utils/aux_functions";
import ImageCard from "@/components/ImagesLibrary/ImageCard.vue";
import FormImage from "@/components/Forms/FormImage.vue";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import ScreenCrudDegree from "@/components/CrudDegrees/ScreenCrudDegree.vue";
import {hasPermission} from "@/utils/auth";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {getNameLanguage, getNamePortal} from "@/utils/portal";
import SelectTertiary from "@/components/Generic/SelectTertiary.vue";

export default {
    name: "ManagerDegreesView",
    components: {SelectTertiary, ScreenCrudDegree, FormImage, ImageCard, Loading},

    setup() {
        let general_loading = ref(false)
        let route = useRoute();
        let router = useRouter()
        let store = useStore();
        let pending_language = ref(false);
        let language_active_iso = computed(() => store.getters.getLanguageISO);
        let img_entity = ref({title: 'Logos', related_entity: 'logos', singular: 'logo'});
        let select_options = ref(
            [
                {title: 'Logos', related_entity: 'logos', singular: 'logo'},
                {title: 'Diplomas', related_entity: 'diplomas', singular: 'diploma'}
            ])
        let loading_get_degrees = ref(false)
        let all_degrees_selected = ref([])
        let display_dialog_modal_crud = ref(false)
        let selected_image = ref(false)
        let screen_title = ref('Gestión de logos y diplomas')
        let btn_screen_title = ref('')


        onBeforeMount(async () => {
            general_loading.value = true
            await hasPermission(route);
            await router.isReady()
            language_active_iso.value !== 0 ? await getDegreesSelected() : pending_language.value = true
            initSweetAlert()
            btn_screen_title.value = `Crear un ${img_entity.value.singular}`
            general_loading.value = false
        })

        watch(language_active_iso, async () => {
            pending_language.value = true;
            if (language_active_iso.value !== 0) {
                pending_language.value = false;
                await getDegreesSelected();
            }
        });

        /*OBTIENE TODOS LOS LOGOS O DIPLOMAS*/
        async function getDegreesSelected() {
            loading_get_degrees.value = true
            btn_screen_title.value = `Crear un ${img_entity.value.singular}`
            let response = await getAllDegreesWS(img_entity.value)
            if (response['result']) {
                all_degrees_selected.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
            loading_get_degrees.value = false
        }


        /*ACCIONES*/
        function selectImage(image, action) {
            selected_image.value = image;
            if (action === 'create') {
                screen_title.value = `Creación de ${img_entity.value.singular}`
                btn_screen_title.value = `Crear un ${img_entity.value.singular}`
                displayDialogModalCrud()

            } else if (action === 'edit') {
                screen_title.value = `Edición de ${img_entity.value.singular}`
                displayDialogModalCrud()

            } else if(action === 'status') {
                let txt_status = ''
                if (image.activo === 1) {
                    txt_status = 'desactivar'
                } else {
                    txt_status = 'activar'
                }
                let title_status = `¿Desea ${txt_status} el ${img_entity.value.singular} ${selected_image.value.title} para el portal ${getNamePortal(store.getters.getPortalID)} e idioma ${getNameLanguage(store.getters.getLanguageID)} ?`;
                fireSweetAlert(title_status, changeStatus)

            } else if(action === 'delete'){
                let title_delete = `¿Desea eliminar el ${img_entity.value.singular} ` + selected_image.value.title + ` para el portal ${getNamePortal(store.getters.getPortalID)} e idioma ${getNameLanguage(store.getters.getLanguageID)} ?`;
                fireSweetAlert(title_delete, deleteDegree, 'Las personalizaciones no se eliminan')

            }
        }

        /*ELIMINA UN LOGO O DIPLOMA EXISTENTE*/
        async function deleteDegree(){
            let id_degree
            if(img_entity.value.related_entity==='logos'){
                id_degree = selected_image.value.id_logo
            }else{
                id_degree = selected_image.value.id_diploma
            }
            let response = await deleteDegreeWS(id_degree, selected_image.value.id_portal, selected_image.value.language, img_entity.value.related_entity )
            if(response['result']){
                showNotification('success', response['data'])
            }else{
                showNotification('error', response['errors'])
            }
            await getDegreesSelected();
        }


        /*MODAL CRUD DIPLOMAS LOGOS*/
        function displayDialogModalCrud() {
            if (display_dialog_modal_crud.value) {
                screen_title.value = 'Gestión de logos y diplomas'
                btn_screen_title.value = `crear un ${img_entity.value.singular}`
            } else {
                btn_screen_title.value = 'Volver atrás'
            }
            display_dialog_modal_crud.value = !display_dialog_modal_crud.value
        }


        //ACTUALIZA EL ESTADO
        async function changeStatus() {
            let date_degree = []
            let status_preview
            if (selected_image.value.activo === 1) {
                status_preview = 0
            } else {
                status_preview = 1
            }
            let id_degree
            if (img_entity.value.related_entity === 'logos') {
                id_degree = selected_image.value.id_logo
            } else {
                id_degree = selected_image.value.id_diploma
            }
            date_degree = [{
                'title': selected_image.value.title ?? null,
                'id_file': selected_image.value.id_file ?? null,
                'alt_text': selected_image.value.alt_text ?? null,
                'active':  status_preview ?? 0
            }]

            let response = await updateDegreeWS(id_degree, JSON.stringify(date_degree[0]), JSON.stringify([]), img_entity.value.related_entity);
            if (response['result']) {
                if (selected_image.value.activo === 1) {
                    selected_image.value.activo = 0
                } else {
                    selected_image.value.activo = 1
                }
                showNotification('success', response['data']['success'][0][0]['title']);
            } else {
                showNotification('error', response['errors']);
            }
            await getDegreesSelected();

        }

        return {
            selectImage,
            getDegreesSelected,
            displayDialogModalCrud,

            general_loading,
            loading_get_degrees,
            pending_language,
            select_options,
            all_degrees_selected,
            img_entity,
            selected_image,
            screen_title,
            btn_screen_title,
            display_dialog_modal_crud,

        }
    }
}
</script>

<style scoped lang="scss">
</style>