<template>
    <BaseModalTemplate
        :hide_actions="true"
        :open-close-dialog="displayDialog"
        :loading="loading"
        :title="title"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12" sm="8" lg="9" xl="10">
                    <SelectTertiary
                        :items="all_teacher"
                        v-model="all_teacher.id_docente"
                        item-title="nombre"
                        item-value="id_docente"
                        placeholder="Selecciona a un docente"
                        class="input-error"
                        :error="teacher_error"
                    >
                    </SelectTertiary>
                    <span
                        v-if="teacher_error"
                        class="input-error msg-error">No ha seleccionado ningún docente
                    </span>
                </v-col>
                <v-col cols="12" sm="4" lg="3" xl="2" class="align-self-center">
                    <v-btn variant="outlined" class="btn button-primary w-100" @click="setCourseSpecificTeacher">
                        <img :src="require('@/assets/icons/plus.svg')" class="icons-add-teacher" alt="Plus">
                        Añadir docente
                    </v-btn>
                </v-col>
                <!--DOCENTES ESPECÍFICOS DEL CURSO -->
                <v-col cols="12">
                    <span class="txt-title">Claustro especifico del curso</span>
                </v-col>

                <v-col cols="12" v-if="teachers_specific.length === 0">
                    <span class="not-teacher">No hay docentes asociados al curso</span>
                </v-col>
                <v-col v-else xs="12" sm="4" md="3" lg="2" v-for="teacher_specific in teachers_specific">
                    <GenericUserProfile
                        :profile="teacher_specific"
                        :displayDialogConfirmationMessage="preRemove"
                        :user_image="teacher_specific.imagen"
                        :user_name="teacher_specific.nombre"
                        :user_active="teacher_specific.activo"
                        :can_delete="true"
                        :can_edit="false"
                        :can_activate="false"
                        :can_view="false"
                    ></GenericUserProfile>
                </v-col>
                <!--DOCENTES POR CATEGORÍA-->
                <v-col cols="12">
                    <span class="txt-title">Claustro basado en las áreas formativas del curso</span>
                </v-col>
                <v-col cols="12" v-if="teachers_areas.length === 0">
                    <span class="not-teacher">No hay docentes asociados al curso</span>
                </v-col>
                <v-col v-else xs="12" sm="4" md="3" lg="2" v-for="teacher_areas in teachers_areas">
                    <GenericUserProfile
                        :profile="teacher_areas"
                        :displayDialogConfirmationMessage="preRemove"
                        :user_image="teacher_areas.imagen"
                        :user_name="teacher_areas.nombre"
                        :user_active="teacher_areas.activo"
                        :can_delete="false"
                        :can_edit="false"
                        :can_activate="false"
                        :can_view="false"
                    ></GenericUserProfile>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import GenericUserProfile from "@/components/Generic/GenericUserProfile.vue";
import {onBeforeMount, ref} from "vue";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import {getAllTeachersWS, removeSpecificTeacherFromCourseWS, setTeacherToCourseWS} from "@/utils/teachers";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import SelectTertiary from "@/components/Generic/SelectTertiary.vue";

export default {
    name: "TeachersModal",
    methods: {variableIsDefined},
    components: {SelectTertiary, GenericUserProfile, BaseModalTemplate},
    props: {
        getCourseTeachers: Function,
        getCourses: Function,
        teachers_specific: Array,
        teachers_areas: Array,
        title: String,
        displayDialog: Function,
        indentifier_course: String,
    },

    setup(props){
        let all_teacher = ref([]);
        let loading = ref(false);
        let title_msg_drop = ref("")
        let id_teacher_select;
        let can_delete = ref(false);

        let teacher_error = ref(false)

        onBeforeMount(async () => {
            loading.value = true
            await getTeachersForAutocomplete();
            initSweetAlert()
            loading.value = false
        })

        /*RELLENA EL AUTOCOMPLETE CON TODOS LOS DOCENTES DISPONIBLES*/
        async function getTeachersForAutocomplete() {
            const response = await getAllTeachersWS();
            let final_teachers = [];
            if (response['result']) {
                Object.entries(response['data']).forEach(teacher => {
                    let teacher_obj = {
                        'nombre': teacher[1].datos_innocualificados.nombre_completo,
                        'id_docente': teacher[1].datos_personalizaciones[0].id_tecnico,
                        'activo': teacher[1].datos_personalizaciones[0].activo,
                        'imagen': teacher[1].datos_personalizaciones[0].imagen,
                    }
                    final_teachers.push(teacher_obj)
                })
                all_teacher.value = final_teachers;
            } else {
                showNotification('error', response['errors'])
            }
        }

        /*PREGUNTA SI ESTÁ SEGURO DE QUERER BORRAR AL USUARIO*/
        async function preRemove(id_docente) {
            title_msg_drop.value = "¿Deseas eliminar el docente?"
            id_teacher_select = id_docente

            await fireSweetAlert(title_msg_drop.value, removeSpecificTeacherFromCourse)

        }

        // Eliminar un docente especifico de un curso
        async function removeSpecificTeacherFromCourse() {
            try {
                const response = await removeSpecificTeacherFromCourseWS(props.indentifier_course, id_teacher_select);

                if (response['result'] && response['data']) {
                    showNotification('success', 'Docente eliminado del curso')
                    loading.value = true;
                    await props.getCourses()
                    await props.getCourseTeachers(props.indentifier_course)
                    loading.value = false;
                } else {
                    showNotification('error', response['errors'])
                }
            } catch (e) {
                showNotification('error', 'Error al hacer la petición')
            }
        }

        async function setCourseSpecificTeacher() {
            let ids_teacher = []
            teacher_error.value = false

            if (!variableIsDefined(all_teacher.value.id_docente)) {
                teacher_error.value = true
            } else {
                loading.value = true
                props.teachers_specific.forEach(teacher_course => {
                    ids_teacher.push(teacher_course.id_docente)
                })
                if (ids_teacher.includes(all_teacher.value.id_docente)) {
                    teacher_error.value = true
                } else {
                    let response = await setTeacherToCourseWS(props.indentifier_course, all_teacher.value.id_docente);
                    if (response['result'] && response['data']) {
                        showNotification('success', 'Docente añadido al curso')
                        await props.getCourseTeachers(props.indentifier_course)
                        await props.getCourses()

                    } else {
                        showNotification('error', response['errors'])
                    }
                }
                loading.value = false
            }
            all_teacher.value.id_docente = null

        }

        return {
            setCourseSpecificTeacher,
            preRemove,
            loading,
            all_teacher,
            can_delete,
            teacher_error,

        }
    }
}
</script>

<style lang="scss">
    @import "@/styles/components/docentes-by-course.scss";
</style>