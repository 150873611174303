<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <span class="main-title-page">Visualización de usuarios por rol</span>
            </v-col>
        </v-row>
        <template v-if="loading_page">
            <Loading/>
        </template>
        <template v-else>
            <v-row>
                    <v-col cols="12" sm="7" md="6" lg="4">
                    <SelectTertiary
                        class="input-error"
                        placeholder="Roles disponibles"
                        item-title="name"
                        item-value="id_rol"
                        v-model="rol_selected"
                        :items="rol_list"
                        @update:modelValue="getAllUsersRol(rol_selected.id_rol)"
                        return-object
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item
                                v-bind="props"
                                :title="item?.raw?.name"
                            >
                            </v-list-item>
                        </template>
                        <template v-slot:selection="{ item }">
                            <div>
                                <span>{{ item?.raw?.name }}</span>
                            </div>
                        </template>
                    </SelectTertiary>
                </v-col>
                <v-col cols="10" sm="4" md="5" lg="3">
                    <v-form @submit.prevent="searchUser">
                        <v-text-field
                            v-model="filter_user"
                            class="text-field-secondary"
                            label="Buscar usuario..."
                            append-inner-icon="mdi-magnify"
                            single-line
                            bg-color="white"
                            color="transparent"
                            hide-details
                        ></v-text-field>
                    </v-form>
                </v-col>
                <v-col cols="2" sm="1" class="align-self-center">
                    <img :src="require('@/assets/icons/delete-left-solid.svg')" alt="Eliminar campo usuario"
                         class="delete-filter" @click="cleanNameUser(rol_selected.id_rol)">
                </v-col>
            </v-row>
            <v-row justify="start">
                <v-col cols="12" md="11" lg="9" class="users-col">
                    <div class="list-title">Lista usuarios</div>
                    <template v-if="loading">
                        <img class="loading-gif-m" id="loading-gif" :src="require('@/assets/img/loading.gif')"
                             alt="Loading Gif">
                    </template>
                    <template v-else>
                        <template v-if="show_users">
                            <template v-if="user_rol_list.length > 0">
                                <v-list class="personalize-scroll list-users">
                                    <v-list-item
                                        class="text-list"
                                        v-for="user in user_rol_list"
                                        :key="user.id"
                                        :title="user.name"
                                    ></v-list-item>
                                </v-list>
                            </template>
                            <template v-else>
                                <span>No hay usuarios asignados en este rol</span>
                            </template>
                        </template>
                        <template v-else>
                            <div class="default-text">
                                <span>Selecciona un rol para mostrar los usuarios</span>
                            </div>
                        </template>
                    </template>

                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import {useRoute} from "vue-router";
import {onBeforeMount, ref} from "vue";
import {hasPermission} from "@/utils/auth";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {getAllRolesWS, getAllUsersRolWS} from "@/utils/admin_functions";
import Loading from "@/components/Generic/Loading.vue";
import SelectTertiary from "@/components/Generic/SelectTertiary.vue";

export default {
    name: "ManagerUserRolesView",
    components: {SelectTertiary, Loading},

    setup() {
        const route = useRoute();
        let rol_list = ref([]);
        let rol_selected = ref([]);
        let show_users = ref(false);
        let user_rol_list = ref([]);
        let all_users = ref([]);
        let filter_user = ref('');
        let loading = ref(false);
        let loading_page = ref(false);

        onBeforeMount(async () => {
            loading_page.value = true;
            await hasPermission(route);
            await loadAllRoles();
            loading_page.value = false;
        })


        async function loadAllRoles() {
            let response = await getAllRolesWS();
            if (response['result']) {
                rol_list.value = response['data'];
            } else {
                showNotification('error', response['errors'])
            }
        }

        async function getAllUsersRol(rol_id) {
            loading.value = true;
            let response = await getAllUsersRolWS(rol_id)
            if (response['result']) {
                all_users.value = response['data'];
                user_rol_list.value = all_users.value;
                if (variableIsDefined(filter_user.value)) {
                    searchUser()
                }
            } else {
                showNotification('error', response['errors'])
            }
            loading.value = false;
            show_users.value = true;
        }

        function searchUser() {
            let list_user_filtered = [];
            all_users.value.forEach((user) => {
                if (user.name.toUpperCase().includes(filter_user.value.toUpperCase())) {
                    list_user_filtered.push(user);
                }
            });

            user_rol_list.value = list_user_filtered;
        }

        function cleanNameUser(rol_selected) {
            if (variableIsDefined(filter_user.value)) {
                filter_user.value = ""
                getAllUsersRol(rol_selected)
            }
        }

        return {
            getAllUsersRol,
            searchUser,
            cleanNameUser,

            rol_list,
            user_rol_list,
            rol_selected,
            show_users,
            filter_user,
            loading,
            loading_page
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/views/admin/manager-user-roles.scss";
</style>