<template>
    <v-container class="all-containers">
        <v-row>
            <v-col cols="12">
                <span class="main-title-page">Gestión de roles por grupo</span>
            </v-col>
        </v-row>
        <template v-if="loading">
            <Loading/>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12">
                    <v-form @submit.prevent="searchGroup">
                        <v-text-field
                            v-model="filter_group"
                            class="text-field-secondary"
                            label="Buscar grupo..."
                            append-inner-icon="mdi-magnify"
                            single-line
                            bg-color="white"
                            color="transparent"
                            hide-details
                        ></v-text-field>
                    </v-form>
                </v-col>
            </v-row>
            <v-row class="box-group-rol">
                <v-col cols="12" md="6" lg="8" class="col-list-group">
                    <v-row no-gutters>
                        <v-col>
                            <span class="list-title">Lista de grupos</span>
                        </v-col>
                    </v-row>
                    <Loading  v-if="loading_group"></Loading>
                    <v-row v-else>
                        <v-col v-if="not_group_selected">
                            <span>No se ha encontrado ningún grupo</span>
                        </v-col>
                        <v-col v-else>
                            <v-list class="personalize-scroll">
                                <v-list-item
                                    v-for="group in group_list"
                                    :key="group.id_grupo_ldap"
                                    :title="`${group.nombre_grupo} (${group.id_grupo_ldap})`"
                                    append-icon="mdi-chevron-right"
                                    :value="group.nombre_grupo"
                                    color="blue"
                                    class="active-list"
                                    @click="getAllGroupRoles(group.id_grupo_ldap,group.nombre_grupo)"
                                ></v-list-item>
                            </v-list>
                        </v-col>

                    </v-row>

                </v-col>

                <v-col cols="12" md="6" lg="4" class="col-edit-rol" >
                    <v-row no-gutters>
                        <v-col>
                            <span class="list-title" v-if="!see_roles">Añadir rol</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="col-edit-rol" v-if="see_roles">
                            <span>{{ msg_error_rol }}</span>
                        </v-col>

                        <v-col v-else>
                            <img v-if="loading_rol" class="loading-gif-xs" id="loading-gif"
                                 :src="require('@/assets/img/loading.gif')"
                                 alt="Loading Gif">
                            <v-card class="list-roles" v-if="show_edit && !loading_rol">
                                <v-card-title class="roles-card-title">
                                    <span> {{ group_selected.nombre_grupo }}</span>
                                </v-card-title>
                                <v-card-text class="group-roles personalize-scroll">
                                    <v-row>
                                        <v-col>
                                            <v-checkbox v-for="rol in rol_list"
                                                        class="checkbox"
                                                        v-model="selected_roles"
                                                        :label="rol.name"
                                                        :value="rol.name"
                                                        color="blue"
                                                        hide-details
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions class="roles-card-actions">
                                    <v-row>
                                        <v-col class="align-self-center text-end">
                                            <v-btn class="btn button-primary"
                                                   @click="addRolToGroup"
                                                   :disabled="state_loading"
                                            >
                                                Guardar
                                                <v-progress-circular
                                                    v-if="state_loading"
                                                    class="icons-loading ms-4"
                                                    indeterminate
                                                    :size="20"
                                                    :width="2"
                                                    color="white"
                                                ></v-progress-circular>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>
        </template>
    </v-container>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {onBeforeMount, ref} from "vue";
import {hasPermission} from "@/utils/auth";
import {getAicoreToken, showNotification} from "@/utils/aux_functions";
import {getAllGroupRolesWS, loadAllGroupsWS, addRolToGroupWS} from "@/utils/admin_functions"
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "ManagerGroupView",
    components: {Loading},

    setup() {
        const route = useRoute();
        const router = useRouter();
        const all_groups = ref([]);
        let rol_list = ref([]);
        let group_list = ref([]);
        let group_selected = ref({});
        let show_edit = ref(false);
        let selected_roles = ref([]);
        let filter_group = ref('');
        let loading = ref(false);
        let loading_rol = ref(false);
        let state_loading = ref(false);
        let not_group_selected = ref(false)
        let see_roles = ref(false)
        let loading_group= ref(false)
        let msg_error_rol = ref('Selecciona un grupo para mostrar sus roles')

        onBeforeMount(async () => {
            loading.value = true;
            await hasPermission(route);
            await loadAllGroups();
            see_roles.value = true
            loading.value = false;

        })

        async function loadAllGroups() {
            //let response = await loadAllGroupsWS();
            const response_token = await getAicoreToken({ext: true});
            let token = response_token['access_token']

            let base_url = process.env.VUE_APP_AICORE_URL_API_EXT;

            let response = await $.ajax({
                type: 'GET',
                url: base_url + "/EmbedLooker/get_all_grupos_ldap",
                data: {},
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'Authorization': 'Bearer ' + token
                }
            });

            if (response['result']) {
                all_groups.value = response['data']
                group_list.value = all_groups.value;
            } else {
                showNotification('error', response['errors'])
            }
        }

        async function getAllGroupRoles(id_grupo_ldap, nombre_grupo) {
            see_roles.value = false
            not_group_selected.value = false
            group_selected.value = {
                'id_grupo_ldap': id_grupo_ldap,
                'nombre_grupo': nombre_grupo,
            }
            loading_rol.value = true;
            let response = await getAllGroupRolesWS(id_grupo_ldap);
            if (response['result']) {
                let final_rol_list = [];
                response['data'].forEach((rol) => {
                    if (rol.active === 1) {
                        final_rol_list.push(rol.name);
                    }
                })
                selected_roles.value = final_rol_list;
                rol_list.value = response['data'];
                if (rol_list.value.length===0){
                    see_roles.value = true
                    msg_error_rol.value = "No hay roles creados para el grupo seleccionado"
                }

            } else {
                showNotification('error', response['errors'])
                see_roles.value = true
                msg_error_rol.value=""
            }
            show_edit.value = true;
            loading_rol.value = false;
        }

        async function addRolToGroup() {
            let roles_list = [];
            let active = 0;
            state_loading.value = true;

            rol_list.value.forEach((rol) => {
                if (selected_roles.value.find(element => element === rol.name)) {
                    active = 1;
                } else {
                    active = 0;
                }
                roles_list.push({'id_rol': rol.id_rol, 'active': active,})
            })

            let rol_group_data = {
                'roles_list': roles_list,
            }

            let response = await addRolToGroupWS(group_selected.value['id_grupo_ldap'], rol_group_data)
            if (response['result']) {
                showNotification('success', 'Se han guardado correctamente los roles');
            } else {
                showNotification('error', response['errors'])
            }
            state_loading.value = false;
        }

        function searchGroup() {
            loading_group.value = true
            not_group_selected.value = false
            let list_group_filtered = [];
            all_groups.value.forEach((group) => {
                if (group.nombre_grupo.toUpperCase().includes(filter_group.value.toUpperCase())) {
                    list_group_filtered.push(group);
                }
            });
            group_list.value = list_group_filtered;
            if (group_list.value.length === 0) {
                not_group_selected.value = true
                see_roles.value = true
                msg_error_rol.value=""
                rol_list.value = []

            }else{
                see_roles.value = true
                msg_error_rol.value="Selecciona un grupo para mostrar sus roles"
            }
            loading_group.value = false

        }

        return {
            getAllGroupRoles,
            addRolToGroup,
            searchGroup,
            group_list,
            rol_list,
            group_selected,
            show_edit,
            selected_roles,
            filter_group,
            loading,
            loading_rol,
            state_loading,
            not_group_selected,
            see_roles,
            loading_group,
            msg_error_rol,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/views/admin/manager-group-roles.scss";
</style>