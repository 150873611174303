<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" sm="8" md="8" lg="9">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/training-program.svg')" alt="icono de programas formativos" width="28" height="28">
                    Listado programas formativos
                </span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <span>Seleccione un portal para obtener los programas formativos</span>
            </v-col>
            <v-col cols="12" sm="9" md="6" lg="5" class="align-self-center">
                <SelectTertiary
                    v-model="form_data.id_portal"
                    :items="all_portals"
                    item-title="nombre"
                    item-value="id_portal"
                    placeholder="Seleccione un portal"
                />
                <span v-if="portal_selected_error"
                      class="input-error msg-error">Debe seleccionar un portal</span>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="2" class="align-self-center">
                <v-btn class="btn button-primary" @click="getTrainingProgram"
                       :loading="loading_get_training_program">SELECCIONAR
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="show_table_training">
            <v-col v-if="item_all_training_program.length>0">
                <Vue3EasyDataTable
                    table-class-name="primary-table"
                    theme-color="#F4A649"
                    :headers="headers"
                    :items="item_all_training_program"
                    :loading="loading_get_training_program"
                    buttons-pagination
                    :rows-per-page="10"
                    rows-per-page-message="Elementos por página: "
                    rows-of-page-separator-message="de"
                    empty-message="No hay datos disponibles"
                >
                    <template #item-updated_at="{updated_at}">
                        {{ formatDate(updated_at) }}
                    </template>
                    <template #item-btn_actions="item_all_training_program">
                        <v-menu class="table-blog">
                            <template v-slot:activator="{ props }">
                                <img :src="require('@/assets/icons/more-options.svg')"
                                     class="btn button-bg-white icon-actions"
                                     alt="Icono puntos"
                                     v-bind="props">
                            </template>
                            <v-list>
                                <router-link :to=editLogosDiplomas(item_all_training_program)
                                             target="_blank"
                                             class="link-unestyled"
                                >
                                    <v-list-item>
                                        <v-list-item-title class="text-center">
                                            <span style="cursor: pointer">Logos y diplomas</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                </router-link>
                            </v-list>
                        </v-menu>
                    </template>
                </Vue3EasyDataTable>
            </v-col>
            <v-col v-else>
                <span>No hay datos disponibles</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Loading from "@/components/Generic/Loading.vue";
import {computed, ref} from "vue";
import {getPortalFormativeProgramsWS} from "@/utils/training_program";
import {formatDate, showNotification, variableIsDefined} from "@/utils/aux_functions";
import Vue3EasyDataTable from "vue3-easy-data-table";
import ModalURLCheck from "@/components/SEOEdition/ModalURLCheck.vue";
import {useStore} from "vuex";
import SelectTertiary from "@/components/Generic/SelectTertiary.vue";

export default {
    name: "TrainingProgramView",
    methods: {formatDate},
    components: {SelectTertiary, ModalURLCheck, Vue3EasyDataTable, Loading},

    setup() {
        let store = useStore();
        let all_portals = computed(() => store.getters.getStoredPortals).value;
        let form_data = ref({})
        let loading_get_training_program = ref(false)
        let item_all_training_program = ref([])
        let headers = ref([
            {text: 'ID', value: 'id'},
            {text: 'NOMBRE', value: 'nombre'},
            {text: 'CÓDIGO', value: 'codigo'},
            {text: 'TOTAL LOGOS', value: 'count_logos'},
            {text: 'TOTAL DIPLOMAS', value: 'count_diplomas'},
            {text: 'TOTAL CURSOS', value: 'count_cursos'},
            {text: 'FECHA MODIFICACIÓN', value: 'updated_at'},
            {text: 'ACCIONES', value: 'btn_actions'},
        ])
        let training_program_selected = ref({})
        let portal_selected_error = ref(false)
        let show_table_training = ref(false)


        async function getTrainingProgram() {
            loading_get_training_program.value = true
            if (!await validateForm()) {
                let response = await getPortalFormativeProgramsWS(form_data.value.id_portal)
                if (response['result']) {
                    show_table_training.value = true
                    if (variableIsDefined(response['data'])) {
                        item_all_training_program.value = response['data']
                    } else {
                        item_all_training_program.value = []
                    }
                } else {
                    showNotification('error', response['errors'])
                }
            }
            loading_get_training_program.value = false
        }

        async function validateForm() {
            portal_selected_error.value = !variableIsDefined(form_data.value.id_portal);
            return portal_selected_error.value
        }

        function editLogosDiplomas(item_training_program) {
            return '/bridge/asignacion-logo-diploma-programa-formativo?programa-formativo=' + item_training_program.nombre + '&id-pf=' + item_training_program.id + '&portal=' + form_data.value.id_portal
        }

        return {
            getTrainingProgram,
            editLogosDiplomas,

            loading_get_training_program,
            all_portals,
            form_data,
            item_all_training_program,
            headers,
            show_table_training,
            training_program_selected,
            portal_selected_error,


        }
    }
}
</script>


<style scoped lang="scss">

</style>