<template>
    <v-table fixed-header height="42vh">
        <thead>
        <tr>
            <th class="text-left">Imagen
            </th>
            <th class="text-left">
                Nombre
            </th>
            <th class="text-left">
                Selecciona una fecha
            </th>
        </tr>
        </thead>
        <tbody>
        <tr class="td-general"
            v-for="item in copy_date"
            :key="item.name_upload">
            <td v-if="item.type === 'image'" class="td-img-video">
                <template v-if="item.name_upload.split(['.'])[1]==='svg'">
                    <img
                        :src="require('@/assets/icons/icon_svg.svg')"
                        class="icon-video">
                </template>
                <template v-else><img
                    :src="item.upload_url" alt="Image" type="img/svg"
                    class="img-file"></template>
            </td>
            <td v-else-if="item.type === 'video'" class="td-img-video"><img
                :src="require('@/assets/icons/icon-video.svg')" alt="Video"
                class="icon-video">
            </td>
            <td v-else-if="item.type === 'audio'" class="td-img-video"><img
                :src="require('@/assets/icons/icon-audio.svg')"
                alt="Audio"
                class="icon-video">
            </td>
            <td v-else-if="item.type === 'application'" class="td-img-video"><img
                :src="require('@/assets/icons/file.svg')"
                alt="Archivo"
                class="icon-video">
            </td>
            <td v-else class="td-img-video"><img
                :src="require('@/assets/icons/icon-push.svg')"
                class="icon-video">
            </td>
            <td> {{ item.name_upload }}</td>
            <td>
                <SelectTertiary
                    :items="date_attribute"
                    class="select-date"
                    v-model="item.dateAttribute"
                    @update:model-value="clickAttribute(item.id_upload, item.dateAttribute)"
                ></SelectTertiary>
            </td>
        </tr>
        </tbody>
    </v-table>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import SelectTertiary from "@/components/Generic/SelectTertiary.vue";

export default {
    name: 'TableAddFilesAttributes',
  components: {SelectTertiary},
    props: {
        date_file: Object,
        date_attribute: Array,
        addAttributesFiles: Function,
    },
    setup(props) {
        let copy_date = ref()
        let attributes = ref({})
        let error_attribute = []

        onBeforeMount(() => {
            copy_date.value = props.date_file
        })

        async function clickAttribute() {
            error_attribute = []
            copy_date.value.forEach((file) => {
                if (!file.hasOwnProperty('dateAttribute')) {
                    error_attribute.push(file)
                }
            })
            if (error_attribute.length === 0) {
                props.addAttributesFiles(copy_date.value, true)
            }
        }


        return {
            clickAttribute,

            copy_date,
            attributes,
        }
    }
}
</script>

<style scoped lang="scss">
.td-general {
    height: 10vh;

    .td-img-video {
        width: 10vw;
        text-align: left;
    }

    .icon-video {
        filter: $filter-primary-color-1;
        width: 30px;
        height: auto;
    }

    .img-file {
        width: auto;
        max-height: 7vh;
    }

    .select-date {
        width: 15vw;
    }
}


@media (max-width: 800px) {
    .td-general {
        .icon-video {
            width: 20px;
            height: auto;
        }

        .img-file {
            width: auto;
            max-height: 5vh;
        }

        .select-date {
            width: 30vw;
        }
    }
}

</style>