import {aicoreBackendPetition} from "@/utils/aicore_petition";
import {getAicoreToken, showNotification} from "@/utils/aux_functions";
import store from "@/store";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {getUserRolesWS, isUserUTE} from "@/utils/auth";

export async function loadAllGroupsWS() {
    try {

        const response = await getAicoreToken({ext: true});
        let token = response['access_token']

        let base_url = process.env.VUE_APP_AICORE_URL_API_EXT;

        return $.ajax({
            type: 'GET',
            url: base_url + "/EmbedLooker/get_all_grupos_ldap",
            data: data,
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': 'Bearer ' + token
            }
        });
        //return await aicoreBackendPetition('GET', "/EmbedLooker/get_all_grupos_ldap", {})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/EmbedLooker/get_all_grupos_ldap');
    }
}

export async function getAllRolesWS() {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    try {
        return await aiseoBackendPetition('POST', 'users/getAllRoles', {}, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'users/getAllRoles');
    }
}

export async function getAllGroupRolesWS(id_group) {
    let params = {
        'id_group': id_group,
    }

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    try {
        return await aiseoBackendPetition('POST', 'users/getAllGroupRoles', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'users/getAllGroupRoles');
    }
}

export async function getAllGroupsByRolWS(id_rol) {
    let params = {
        'id_rol': id_rol,
    }

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    try {
        return await aiseoBackendPetition('POST', 'users/getAllGroupsRol', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'users/getAllGroupsRol');
    }
}

export async function getAllUsersRolWS(rol_id) {
    let params = {
        'id_rol': rol_id,
    }

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    try {
        return await aiseoBackendPetition('POST', 'users/getAllUsersRol', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'users/getAllUsersRol');
    }
}

export async function addRolToGroupWS(id_group_ladp, rol_group_data) {

    let params = {
        'id_group': id_group_ladp,
        'roles_group_data': JSON.stringify(rol_group_data),
    }

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    try {
        return await aiseoBackendPetition('POST', 'users/assignRolesGroup', params, params_token)

    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'users/assignRolesGroup');
    }
}

export async function updateRolWS(rol_data) {
    let params = {
        'rol_data': JSON.stringify(rol_data),
    }

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    try {
        return await aiseoBackendPetition('POST', 'users/updateRol', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'users/updateRol');
    }
}

export async function insertRolWS(name, alias, description_rol) {

    let params = {
        'rol_name': name.toUpperCase(),
        'rol_alias': alias.toLowerCase(),
        'description_rol': description_rol.toLowerCase(),
    }
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    try {
        return await aiseoBackendPetition('POST', 'users/insertRol', params, params_token);

    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'users/insertRol');
    }
}

export async function deleteRolWS(id_rol) {
    let params = {
        'id_rol': id_rol,
    }

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'users/deleteRol', params, params_token);

    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'users/deleteRol');
    }

}


/*
*
* OBTIENE TODOS LOS USUARIOS
* QUE PERTENECEN A UN GRUPO
* */
export async function getAllUsersByGroupWS(group_name) {
    let params = {
        'nombre_grupo': group_name,
    }

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    try {
        return await aiseoBackendPetition('POST', 'users/getAllUsersByGroup', params, params_token);

    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/api/users/getAllUsersByGroup');
    }
}

export async function getUserRoles() {
    let user_roles_response = await getUserRolesWS();
    let id_user_roles = [];

    if (user_roles_response['result']) {
        user_roles_response['data'].forEach((rol) => {
            id_user_roles.push(rol.id_rol);
        });
    } else {
        if (!isUserUTE()){
            showNotification("error", user_roles_response['errors'])
        }
    }
}

export async function getAllRolesDataWS() {
    let roles = [];
    let response = await getAllRolesWS();
    if (response['result']) {
        response['data'].forEach((rol) => {
            roles.push({'alias':rol.alias.toUpperCase(), 'id': rol.id_rol});
        });
    }else{
        showNotification('error', response['errors'])
    }
    return roles;
}

export async function getIDRolWS(rol_name) {
    let id_rol = null;
    let response = await getAllRolesWS();

    if (response['result']) {
        response['data'].forEach((rol) => {
            if (rol.name.toLowerCase() === rol_name.toLowerCase()) {
                id_rol = rol.id_rol;
            }
        });
    }
    return id_rol
}

export function localGetIDRolByAlias(alias) {
    let roles = store.getters.getRoles;
    let id_rol = 0;

    roles.forEach(rol => {
        if (rol.alias.toUpperCase() === alias.toUpperCase()){
            id_rol = rol.id
        }
    })
    return id_rol
}

export async function getAllGroupsByUserWS(id_user){
    let params = {
        'usuario': id_user,
        'usuario_logueado': store.getters.getUsername,
    }

    let params_token = {
        'usuario_logueado': store.getters.getUsername
    }

    try {
        return await aiseoBackendPetition('POST', 'users/getAllGroupsByUser', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'users/getAllGroupsByUser');
    }
}

export async function getRolesByUrlWS(form_filter) {

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    let params = {
        'type': form_filter?.type ?? null,
        'url': form_filter?.url ?? null,
        'rol': form_filter?.rol ?? null,
        'location_of_code': form_filter?.location_of_code ?? null,
        'description_of_rol': form_filter?.description_of_rol ?? null,
        'individual_description': form_filter?.individual_description ?? null,

    }

    try {
        return await aiseoBackendPetition('POST', 'rolesByUrl/getRolesByUrl', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'rolesByUrl/getRolesByUrl');
    }
}

export async function getCatalogTypeAcceseslWS() {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    try {
        return await aiseoBackendPetition('POST', 'catalogTypeAcceses/getCatalogTypeAcceses', {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'catalogTypeAcceses/getCatalogTypeAcceses');
    }
}
