<template>
    <div v-if="!loading_script" id="scrollable-content" data-bs-spy="scroll" data-bs-target="#list-timeline">
        <form class="editor" @submit.prevent="">
            <v-row class="text-end justify-content-end">
                <v-col cols="12" md="4" class="text-end">
                    <v-switch
                        color="blue"
                        v-model="autosaved"
                        label="Autoguardado"
                    ></v-switch>
                </v-col>
            </v-row>
            <div class="card container-link" id="general-info">
                <div class="card-header">
                    <p class="group-title">
                        Información general
                    </p>
                </div>
                <div class="card-body custom-card-body">
                    <v-checkbox
                        label="Marcar para publicar"
                        v-model="form_data.publishCourse"
                        :true-value="1"
                        :false-value="0"
                        color="blue"
                    ></v-checkbox>

<!--                    <v-checkbox
                            label="Curso activado"
                            v-model="form_data.status"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                    ></v-checkbox>-->

                    <v-text-field
                        counter
                        persistent-counter
                        label="Título"
                        v-model="form_data.titulo_curso" variant="underlined"
                        class="text-field-primary field-with-label"
                    ></v-text-field>

                    <v-text-field disabled="true"
                            counter
                            persistent-counter
                            label="Identificador texto Url"
                            v-model="form_data.identificador_texto_url" variant="underlined"
                            class="text-field-primary field-with-label"
                    ></v-text-field>

                    <v-text-field
                            counter
                            persistent-counter
                            label="Precio"
                            v-model="form_data.price" variant="underlined"
                            class="text-field-primary field-with-label"
                            disabled
                    ></v-text-field>

                    <v-text-field
                            counter
                            persistent-counter
                            label="Precio bonificado"
                            v-model="form_data.subsidizedPrice" variant="underlined"
                            class="text-field-primary field-with-label"
                            disabled
                    ></v-text-field>

                    <v-text-field
                            counter
                            persistent-counter
                            label="Campaña SIMO"
                            v-model="form_data.campaignID" variant="underlined"
                            class="text-field-primary field-with-label"
                            disabled
                    ></v-text-field>

                    <v-text-field
                            counter
                            persistent-counter
                            label="ID de SIMO"
                            v-model="form_data.simoID" variant="underlined"
                            class="text-field-primary field-with-label"
                            disabled
                    ></v-text-field>

                    <v-text-field
                            counter
                            persistent-counter
                            label="Código innotutor"
                            v-model="form_data.innotutorCode" variant="underlined"
                            class="text-field-primary field-with-label"
                            disabled
                    ></v-text-field>

                    <v-checkbox
                            label="Mostrar imagen 'próximamente'"
                            v-model="form_data.checkShowImage"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                    ></v-checkbox>
                </div>
            </div>

            <div class="card container-link" id="course-discounts">
                <div class="card-header">
                    <p class="group-title">
                        Descuentos del curso
                    </p>
                </div>
                <div class="card-body custom-card-body">
                    <v-checkbox
                            label="Descuento habilitado"
                            v-model="form_data.checkDiscount"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                            :disabled="form_data.checkDoesntAllowDiscount || form_data.checkFreeCourse"
                    ></v-checkbox>

                    <v-text-field
                            counter
                            persistent-counter
                            label="Finalización del descuento"
                            class="text-field-primary field-with-label"
                            v-model="form_data.discountEndDate" variant="underlined"
                            :disabled="form_data.checkDoesntAllowDiscount || form_data.checkFreeCourse"
                    ></v-text-field>

                    <v-text-field
                            counter
                            persistent-counter
                            label="Porcentaje descuento"
                            class="text-field-primary field-with-label"
                            v-model="form_data.discountPercentage" variant="underlined"
                            :disabled="form_data.checkDoesntAllowDiscount || form_data.checkFreeCourse"
                    ></v-text-field>

                    <v-checkbox
                            label="Curso gratis sin titulación"
                            v-model="form_data.checkFreeCourse"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                    ></v-checkbox>

                    <v-checkbox
                            label="Este curso no permite descuento"
                            v-model="form_data.checkDoesntAllowDiscount"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                    ></v-checkbox>
                </div>
            </div>

            <div class="card container-link" id="detailed-info">
                <div class="card-header">
                    <p class="group-title">
                        Información detallada
                    </p>
                </div>
                <div class="card-body custom-card-body">
                    <div class="field-group">
                        <label for="dirigido" class="form-label">¿A quién va dirigido?</label>
                        <TinyEditor
                                :model="form_data.courseTarget"
                                :id="'editor-courseTarget'"
                                :height="200"
                                @customChange="form_data.courseTarget = $event"
                        >
                        </TinyEditor>
                    </div>

                    <div class="field-group">
                        <label for="titulacion" class="form-label">Titulación</label>
                        <TinyEditor
                                :model="form_data.degreeDescription"
                                :id="'editor-degreeDescription'"
                                :height="200"
                                @customChange="form_data.degreeDescription = $event"
                        >
                        </TinyEditor>
                    </div>

                    <div class="field-group">
                        <label for="para_que_prepara" class="form-label">¿Para qué te prepara?</label>
                        <TinyEditor
                                :model="form_data.preparation"
                                :id="'editor-preparation'"
                                :height="200"
                                @customChange="form_data.preparation = $event"
                        >
                        </TinyEditor>
                    </div>

                    <div class="field-group">
                        <label for="salidas_laborales" class="form-label">Salidas laborales</label>
                        <TinyEditor
                                :model="form_data.jobOpportunities"
                                :id="'editor-jobOpportunities'"
                                :height="200"
                                @customChange="form_data.jobOpportunities = $event"
                        >
                        </TinyEditor>
                    </div>

                    <div class="field-group">
                        <label for="resumen" class="form-label">Resumen (Si quiere ampliar el contenido, añada XXX al
                            final del parrafo a expandir)</label>

                        <TinyEditor
                                :model="form_data.summary"
                                :id="'editor-summary'"
                                :height="200"
                                @customChange="form_data.summary = $event"

                        >
                        </TinyEditor>

                        <v-row class="text-center">
                            <v-col cols="12" sm="6">
                                <v-btn
                                        class="btn button-bg-white mt-5"
                                        @click="chooseGPTWS('Iniciar curso')"
                                >
                                    Iniciar curso
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-btn
                                        class="btn button-bg-white mt-5"
                                        @click="chooseGPTWS('Continuar curso')"
                                >
                                    Continuar curso
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="field-group">
                        <label for="temario" class="form-label">Temario</label>
                        <TinyEditor
                                :model="form_data.courseAgenda"
                                :id="'editor-courseAgenda'"
                                :height="200"
                                @customChange="form_data.courseAgenda = $event"
                        >
                        </TinyEditor>
                    </div>

                    <div class="field-group">
                        <label for="convalidaciones" class="form-label">Convalidaciones</label>
                        <TinyEditor
                                :model="form_data.courseValidations"
                                :id="'editor-courseValidations'"
                                :height="200"
                                @customChange="form_data.courseValidations = $event"
                        >
                        </TinyEditor>

                    </div>

                    <div class="field-group">
                        <label for="requisitos_acceso" class="form-label">Requisitos acceso</label>
                        <TinyEditor
                                :model="form_data.accessRequirements"
                                :id="'editor-accessRequirements'"
                                :height="200"
                                @customChange="form_data.accessRequirements = $event"
                        >
                        </TinyEditor>
                    </div>

                    <div class="field-group" v-if="received_fields.presentacion">
                        <label for="presentacion" class="form-label">Presentación</label>
                        <TinyEditor
                            :model="form_data.presentacion"
                            :id="'editor-presentacion'"
                            :height="200"
                            @customChange="form_data.presentacion = $event"
                        >
                        </TinyEditor>
                    </div>

                    <div class="field-group" v-if="received_fields.campo_objetivos">
                        <label for="objectives" class="form-label">Objetivos</label>
                        <TinyEditor
                            :model="form_data.objectives"
                            :id="'editor-objectives'"
                            :height="200"
                            @customChange="form_data.objectives = $event"
                        >
                        </TinyEditor>
                    </div>

                    <v-text-field
                            counter
                            persistent-counter
                            label="Créditos ECTS"
                            class="text-field-primary field-with-label"
                            v-model="form_data.ectsCredits"
                            variant="underlined"
                    ></v-text-field>

                    <v-text-field
                            counter
                            persistent-counter
                            label="Créditos CFC"
                            class="text-field-primary field-with-label"
                            v-model="form_data.cfcCredits"
                            variant="underlined"
                    ></v-text-field>

                    <v-text-field
                            counter
                            persistent-counter
                            label="Versión nueva"
                            class="text-field-primary field-with-label"
                            v-model="form_data.newVersion"
                            variant="underlined"
                    ></v-text-field>
                </div>
            </div>

            <div class="card container-link" id="actions">
                <div class="card-header">
                    <p class="group-title">
                        Acciones
                    </p>
                </div>
                <div class="card-body custom-card-body">
                    <Actions
                        :draft="draft"
                        :data_url="data.url"
                        :form_data="form_data"
                        draft-type="course"
                    >
                    </Actions>
                </div>
            </div>

            <div class="text-center">
                <p class="error-msg" v-if="general_error_msg">Se han encontrado errores. Por favor, revise todos los
                    campos
                    <v-icon icon="mdi-arrow-up-circle-outline" @click="navigateToTop()"></v-icon>
                </p>
<!--                <div class="mt-4">
                    <v-btn v-if="editor_admin"
                           @click="sendForm()"
                           class="btn button-primary btn-xl"
                           :disabled="loading_small">
                        Guardar y publicar
                    </v-btn>
                    <v-btn @click="sendForm(true)"
                           class="btn button-secondary btn-xl ml-3"
                           :disabled="loading_small">
                        Guardar
                        borrador
                    </v-btn>
                </div>-->
            </div>
            <v-row class="align-center">
                <v-col cols="12" md="6" class="text-center text-md-right">
                    <v-btn v-if="editor_admin"
                           @click="sendForm()"
                           class="btn button-bg-blue btn-xl ms-md-3"
                           :disabled="loading_small">
                        Guardar y publicar
                    </v-btn>
                </v-col>
                <v-col cols="12" md="6" class="text-center text-md-left">
                    <v-btn @click="sendForm(true)"
                           class="btn button-primary btn-xl  ms-md-3"
                           :disabled="loading_small">
                        Guardar
                        borrador
                    </v-btn>
                </v-col>
            </v-row>

        </form>
    </div>

    <v-dialog v-model="display_dialog_seo">
        <FormChatGPT
                :show-dialog="displayDialog"
                :selection="gpt_selecction"
                :updateFunction="updateCourseData"
                :html_doc="form_data.summary"
                :message_history="message_history"
        >
        </FormChatGPT>
    </v-dialog>
</template>

<script>

import Editor from '@tinymce/tinymce-vue'
import {onBeforeMount, onMounted, onUnmounted, ref, watch} from 'vue';
import {htmlDecode as auxHtmlDecode, showNotification, variableIsDefined} from "@/utils/aux_functions.js";
import {useStore} from "vuex";
import {userHaveAllowedRol} from "@/utils/auth";
import * as Yup from "yup";
import {getFieldsToPortalWS, saveUrlDataWS} from "@/utils/editor_course_blog";
import {useRoute, useRouter} from "vue-router";
import FormChatGPT from "@/components/Forms/FormChatGPT.vue";
import TinyEditor from "@/components/Editor/TinyEditor.vue";
import DiscardDrafts from "@/components/Editor/Buttons/DiscardDrafts.vue";
import Actions from "@/components/Editor/Buttons/Actions.vue";

export default {
    name: "FormCourse",
    components: {Actions, DiscardDrafts, TinyEditor, FormChatGPT, Editor},
    props: {
        data: Object,
        draft: Boolean,
        updateForm: Function,
    },

    setup(props) {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        let form_data = ref();
        let control_position = ref();
        let root = ref();
        let form_error = ref({});
        let received_data;
        let general_error_msg = ref(false);
        let loading_small = ref(false);
        let editor_admin = ref(false);

        let display_dialog_seo = ref(false);
        let gpt_selecction = ref('');
        let message_history = ref([]);
        let auto_save;
        let autosaved = ref(true);

        let received_fields = ref([]);

        root.value = $('#root')[0];


        let loading_script = ref(true)

        onMounted(async () => {
            received_data = _.cloneDeep(props.data);
            form_data.value = props.data;
            form_data.value.finalizacion_descuento = new Date(form_data.value.finalizacion_descuento * 1000)
            let results = await getFieldsToPortalWS();
            if(results){
                received_fields.value = results.data.fields;
            }

            editor_admin.value = userHaveAllowedRol("PERMISO_ADMIN_EDITOR_CURSO")

            control_position.value = new IntersectionObserver(onElementObserved, {
                root: null,
                threshold: 0.22,
            });

            $('#root')[0].querySelectorAll('.container-link[id]').forEach(section => {
                control_position.value.observe(section)
            })
            auto_save = setInterval(() => {sendForm(true, false, 1)}, 300000 /*5min*/, true, true)

            loadScript('https://cdn.educa.net/tinymce/js/tinymce/tinymce.min.js')
                .then(() => {
                    loading_script.value = false
                    // Tu lógica aquí, después de que el script se haya cargado
                })
                .catch((error) => {
                    console.error('Error cargando el script:', error);
                });

        })

        function loadScript(src){
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.onload = () => resolve();
                script.onerror = () => reject(new Error(`Error cargando el script: ${src}`));
                document.head.appendChild(script);
            });
        }

        onUnmounted(() => {
            clearInterval(auto_save)
        })

        onBeforeMount( () => {
            // received_data = _.cloneDeep(props.data);
            // form_data.value = props.data;
            // form_data.value.finalizacion_descuento = new Date(form_data.value.finalizacion_descuento * 1000)
            //
            // editor_admin.value = userHaveAllowedRol("PERMISO_ADMIN_EDITOR_CURSO")
        })

        watch(autosaved, () => {
            if (!autosaved.value){
                clearInterval(auto_save)
            }else{
                auto_save = setInterval(() => {sendForm(true, false, 1)}, 300000 /*5min*/, true, true)
            }
        })


        function displayDialog() {
            display_dialog_seo.value = !display_dialog_seo.value;
        }

        function updateCourseData(new_text) {
            if (variableIsDefined(new_text)) {
                if (variableIsDefined(new_text['response'])) {
                    form_data.value.summary = new_text['response'];
                    message_history.value = new_text['message_history'];
                } else {
                    form_data.value.summary = form_data.value.summary.replace('XXX', new_text['response_continue']);
                    message_history.value = new_text['message_history'];
                }
            }
        }

        function chooseGPTWS(service) {
            gpt_selecction.value = service;

            displayDialog();
        }

        function onElementObserved(entries) {
            entries.forEach(({target, isIntersecting}) => {
                const id = target.getAttribute('id')
                const el = $('a.timeline-item[href="#' + id + '"]');
                if (isIntersecting) {
                    $(el[0]).parent().addClass('active')
                } else {
                    $(el[0]).parent().removeClass('active')
                }
            })
        }

        const validateForm = async () => {
            form_error.value = [];
            general_error_msg.value = false;

            const schemaForm = Yup.object().shape({})

            try {
                await schemaForm.validate(form_data.value, {abortEarly: false})
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = error.message;
                })
                general_error_msg.value = true;
            }
        }

        function htmlDecode(input) {
            return auxHtmlDecode(input)
        }


        async function sendForm(draft = false, auto = false, is_autosave = 0) {
            let changed = false;
            loading_small.value = true;

            if (hasDifferentData(draft)) {
                form_data.value['url'] = route.query.url;
                form_data.value['is_autoguardado'] = is_autosave;

                await validateForm()
                if (form_error.value.length === 0) {
                    let response = await saveUrlDataWS(form_data.value, draft, 'editor-course');
                    try {
                        if (typeof response === "boolean" && !response) {
                            showNotification('error', 'Se ha producido un error en el servidor')
                        } else {
                            if (response.result) {
                                if (response.data.success) {
                                    if (draft) {
                                        if (auto === false) {
                                            showNotification('success', 'Borrador guardado correctamente')
                                            changed = true
                                        } else {
                                            received_data = _.cloneDeep(form_data.value)
                                        }
                                    } else {
                                        showNotification('success', 'Se ha editado correctamente el curso')
                                        changed = true
                                    }
                                    if (changed && auto === false) {
                                    props.updateForm()
                                    }
                                } else {
                                    showNotification('info', response.data.message)
                                }
                            } else {
                                showNotification('error', response.errors)
                            }
                        }
                    } catch (e) {
                        showNotification('error', 'Se ha producido un error en el servidor')
                    }
                }
            } else {
                if (auto === false) {
                    showNotification('info', 'No se han detectado cambios que guardar')
                }
            }

            loading_small.value = false;
        }

        const hasDifferentData = (draft) => {
            if (draft) {
                let cloned_form_data = _.cloneDeep(form_data.value)
                delete cloned_form_data.finalizacion_descuento
                delete received_data.finalizacion_descuento
                return JSON.stringify(received_data) !== JSON.stringify(cloned_form_data);
            }
            return true;

        }

        const navigateToTop = () => {
            window.scrollTo(0, 0);
        }

        return {
            htmlDecode,
            sendForm,
            navigateToTop,
            updateCourseData,
            displayDialog,
            chooseGPTWS,
            form_data,
            form_error,
            general_error_msg,
            loading_small,
            editor_admin,
            display_dialog_seo,
            gpt_selecction,
            message_history,
            autosaved,
            loading_script,
            received_fields,
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/components/form-seo-course.scss";
</style>