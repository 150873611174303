<template>
    <v-container class="container-list-copywriter">
        <v-row>
            <v-col cols="12" sm="7" md="8" lg="9" xl="10">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/users.svg')" alt="icono de redactor" width="28" height="28">
                    Listado de redactores
                </span>
            </v-col>
            <v-col cols="12" sm="5" md="4" lg="3" xl="2" v-if="!pending_language && !loading">
                <v-btn class="btn button-bg-blue main-title-page float-end" @click="showForm('insert')">
                    Crear redactor
                </v-btn>
            </v-col>
        </v-row>

        <template v-if="loading">
            <Loading/>
        </template>

        <template v-else>
            <template v-if="pending_language">
                <v-row>
                    <v-col cols="12">
                        <span>Selecciona un idioma para continuar.</span>
                    </v-col>
                </v-row>
            </template>
            <template v-else>
                <v-row align="center">
                    <v-col cols=12 xs="12" sm="8" lg="9">
                        <v-text-field
                            class="text-field-secondary"
                            v-model="search"
                            label="¿Qué quieres buscar?"
                            @keyup.enter="searchCopywriters"
                            append-inner-icon="mdi-magnify"
                            single-line
                            bg-color="white"
                            color="transparent"
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col xs="12" sm="4" lg="3">
                        <SelectTertiary
                            :items="filter_options"
                            v-model="filter_view"
                            @update:modelValue="filterCopywriters"
                            :default_values="filter_view"
                        >
                        </SelectTertiary>
                    </v-col>
                </v-row>
                <template v-if="copywriters.length > 0">
                    <v-row>
                        <v-col class="col-lg-2 col-md-4 col-xs-6 p-1" v-for="(item) in copywriters">
                            <GenericUserProfile
                                :displayDialogConfirmationMessage="displayDialogConfirmationMessage"
                                :profile="item"
                                :showForm="showForm"
                                :user_image="item.profile_image"
                                :user_name="item.name"
                                :user_active="item.active"
                                :can_delete="false"
                                :can_edit="true"
                                :can_activate="true"
                                :can_view="true"
                            ></GenericUserProfile>
                        </v-col>
                    </v-row>
                </template>
                <template v-else>
                    <v-row>
                        <v-col cols="12">
                            <span>No se han encontrado redactores para este portal e idioma</span>
                        </v-col>
                    </v-row>
                </template>
            </template>

        </template>

        <v-dialog v-model="dialog" scrollable persistent>
            <FormCopywriter
                :displayDialog="displayDialog"
                :title_modal="title_modal"
                :disable_update="disable_update"
                :copywriter="copywriter_select"
                :updateAuthorsList="filterCopywriters"
            ></FormCopywriter>
        </v-dialog>

    </v-container>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import FormCopywriter from "@/components/Forms/FormCopywriter.vue";
import GenericUserProfile from "@/components/Generic/GenericUserProfile.vue";
import Loading from "@/components/Generic/Loading.vue";
import {showNotification} from "@/utils/aux_functions";
import {changeAuthorsStatus, getAllAuthorsWS} from "@/utils/copywriters";
import {hasPermission} from "@/utils/auth";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import SelectTertiary from "@/components/Generic/SelectTertiary.vue";

export default {
    name: "ListCopywriterView",
    components: {
      SelectTertiary,
        Loading,
        FormCopywriter,
        GenericUserProfile,
    },

    setup: function () {
        const store = useStore();
        const route = useRoute();
        let copywriter_select = ref('');
        let dialog = ref(false);
        let title_msg_drop = ref("");
        let loading = ref();
        let search = ref();
        let title_modal = ref();
        let disable_update = ref(false);
        let all_authors = ref();
        let copywriters = ref([]);
        let filter_options = ['Todos', 'Activos', 'No activos'];
        let filter_view = ref({title: 'Todos'});
        let pending_language = ref(false);
        let language_active_iso = computed(() => store.getters.getLanguageISO);
        let portal = computed(() => store.getters.getPortalID);

        onBeforeMount(async () => {
            loading.value = true;
            await hasPermission(route);
            (language_active_iso.value !== 0) ? await loadCopywriters() : pending_language.value = true
            initSweetAlert()
            loading.value = false;
        })

        watch(portal, async () => {
            await loadCopywriters();
        });

        async function loadCopywriters() {
            let response = await getAllAuthorsWS();
            if (response['result']) {
                all_authors.value = response['data'];
            } else {
                showNotification('error', response['errors'])
            }
            copywriters.value = all_authors.value;
        }

        function displayDialog() {
            dialog.value = !dialog.value;
        }

        function displayDialogConfirmationMessage(copywriter) {
            let action;

            copywriter_select.value = _.cloneDeep(copywriter);
            copywriter_select.value.active === 1 ? action = 'desactivar' : action = 'activar';
            title_msg_drop.value = '¿Quieres ' + action + ' a ' + copywriter_select.value.name + '?';

            fireSweetAlert( title_msg_drop.value, disableSpecificCopywriter, 'Podrá revertir los cambios más adelante')
        }

        async function showForm(method, copywriter = {}) {
            switch (method) {
                case 'update':
                    title_modal.value = "Editar"
                    disable_update.value = false;
                    copywriter_select.value = copywriter;
                    break;
                case 'insert':
                    title_modal.value = "Nuevo"
                    disable_update.value = false;
                    copywriter_select.value = {};
                    break;
                case 'view':
                    title_modal.value = "Vista"
                    disable_update.value = true;
                    copywriter_select.value = copywriter;
                    $(document).ready(function () {
                        $('body .box_get_file').hide()
                    })
                    break;
            }

            displayDialog();
        }

        function searchCopywriters() {
            let result_search = [];
            all_authors.value.forEach(author => {
                if (author['name'].toLowerCase().includes(search.value.toLowerCase())) {
                    result_search.push(author);
                }
            });
            copywriters.value = result_search;
        }


        // Eliminar un docente especifico de un curso
        async function disableSpecificCopywriter() {
            let status;
            copywriter_select.value.active === 0 ? status = 1 : status = 0;
            const response = await changeAuthorsStatus([copywriter_select.value.id_usuario], status);

            if (response['result'] && response['data']) {
                showNotification('success', 'Cambio de estado realizado correctamente.');
                await filterCopywriters();
            } else {
                showNotification('error', response['errors']);
            }
        }

        async function filterCopywriters() {
            let filter_active;
            if (filter_view.value === 'Activos') {
                filter_active = 1;
            } else if (filter_view.value === 'No activos') {
                filter_active = 0;
            }

            let response = await getAllAuthorsWS(filter_active);
            if (response['result']) {
                all_authors.value = response['data'];
                copywriters.value = all_authors.value;
            } else {
                showNotification('error', response['errors'])
            }

            if (search.value !== '' && search.value !== undefined) {
                searchCopywriters();
            }
        }

        return {
            searchCopywriters,
            showForm,
            displayDialog,
            displayDialogConfirmationMessage,
            filterCopywriters,
            copywriter_select,
            dialog,
            loading,
            title_modal,
            disable_update,
            search,
            copywriters,
            filter_options,
            filter_view,
            pending_language,
        }
    }
}
</script>

<style lang="scss">
</style>