import {userHaveAllowedRol} from "@/utils/auth";

export function systemRoute() {
    return [
        {
            name: "Inicio",
            alt: "icono tag",
            path: "/",
            image: require('@/assets/icons/sidebar/house-icon.svg'),
            mobile_image: require('@/assets/icons/sidebar/home-mobile-icon.svg'),
            access: true,
            active: true,
            children: []
        },
        {
            name: "Publicaciones",
            alt: "icono tag",
            path: "/publicaciones",
            image: require('@/assets/icons/sidebar/publish-icon.svg'),
            mobile_image: require('@/assets/icons/sidebar/publish-mobile-icon.svg'),
            access: true,
            active: true,
            children: []
        },
        {
            name: "Bridge",
            alt: "icono tag",
            path: "/bridge",
            image: require('@/assets/icons/sidebar/bridge-icon.svg'),
            mobile_image: require('@/assets/icons/sidebar/bridge-mobile-icon.svg'),
            access: true,
            active: true,
            children: []
        },
        {
            name: "AAFF",
            alt: "icono tag",
            path: "/aaff",
            image: require('@/assets/icons/sidebar/aaff-icon.svg'),
            mobile_image: require('@/assets/icons/sidebar/aaff-mobile-icon.svg'),
            access: true,
            active: true,
            children: [
                {
                name: "Propuesta Keywords",
                alt: "icono tag",
                path: "/bridge",
                image: require('@/assets/icons/navbar/tag.svg'),
                access: true,
                active: true,
                children: [
                    {
                        name: "Propuesta Keywords",
                        alt: "icono tag",
                        path: "/bridge/propuesta-keywords",
                        image: require('@/assets/icons/navbar/tag.svg'),
                        access: true,
                        active: true,
                        children: []
                    },
                    {
                        name: "Propuestas encoladas",
                        alt: "icono tag",
                        path: "/bridge/cola-propuestas-keywords",
                        image: require('@/assets/icons/navbar/tag.svg'),
                        access: true,
                        active: true,
                        children: []
                    },
                    {
                        name: "Volver",
                        alt: "Atras",
                        path: "/bridge",
                        image: require('@/assets/icons/navbar/arrow-back.svg'),
                        access: true,
                        children: [],
                        active: true
                    }
                ]

            },
                {
                    name: "Subir volcado SEMrush",
                    alt: "icono nube",
                    path: "/bridge/upload-semrush",
                    image: require('@/assets/icons/navbar/upload.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Traductor",
                    alt: "icono traductor",
                    path: "/bridge/traductor",
                    image: require('@/assets/icons/navbar/translate.svg'),
                    access: userHaveAllowedRol("PERMISO_TRADUCTOR"),
                    children: [],
                    active: true
                },]
        },
        {
            name: "Blogs",
            alt: "icono blog",
            path: "/blogs",
            image: require('@/assets/icons/sidebar/icon-blog.svg'),
            mobile_image: require('@/assets/icons/sidebar/icon-blog.svg'),
            access: true,
            active: true,
            children: []
        },
        {
            name: "Analíticas",
            alt: "icono blog",
            path: "/analiticas",
            image: require('@/assets/icons/sidebar/icon-analiticas.svg'),
            mobile_image: require('@/assets/icons/sidebar/icon-analiticas.svg'),
            access: true,
            active: false,
            children: []
        },
    ]
}

export function oldAISEOSystemRoute(){
    return [
        {
            name: "Propuesta Keywords",
            alt: "icono tag",
            path: "/bridge",
            image: require('@/assets/icons/navbar/tag.svg'),
            access: true,
            active: true,
            children: [
                {
                    name: "Propuesta Keywords",
                    alt: "icono tag",
                    path: "/bridge/propuesta-keywords",
                    image: require('@/assets/icons/navbar/tag.svg'),
                    access: true,
                    active: true,
                    children: []
                },
                {
                    name: "Propuestas encoladas",
                    alt: "icono tag",
                    path: "/bridge/cola-propuestas-keywords",
                    image: require('@/assets/icons/navbar/tag.svg'),
                    access: true,
                    active: true,
                    children: []
                },
                {
                    name: "Volver",
                    alt: "Atras",
                    path: "/bridge",
                    image: require('@/assets/icons/navbar/arrow-back.svg'),
                    access: true,
                    children: [],
                    active: true
                }
            ]

        },
        {
            name: "Subir volcado SEMrush",
            alt: "icono nube",
            path: "/bridge/upload-semrush",
            image: require('@/assets/icons/navbar/upload.svg'),
            access: true,
            children: [],
            active: true
        },
        {
            name: "Traductor",
            alt: "icono traductor",
            path: "/bridge/traductor",
            image: require('@/assets/icons/navbar/translate.svg'),
            access: userHaveAllowedRol("PERMISO_TRADUCTOR"),
            children: [],
            active: true
        },
        {
            name: "Generador mapa conceptual",
            alt: "icono diagrama",
            path: "/bridge/mapa-conceptual",
            image: require('@/assets/icons/navbar/diagram.svg'),
            access: true,
            children: [],
            active: true
        },
        {
            name: "Buscador",
            alt: "icono lupa",
            path: "/bridge/buscador/eduseo-cursos/es-se",
            image: require('@/assets/icons/navbar/search.svg'),
            access: true,
            children: [],
            active: true
        },
        {
            name: "Configuración GADS",
            alt: "icono diagrama",
            path: "/bridge/listado-configuracion-google-adwords",
            image: require('@/assets/icons/navbar/google-ads.svg'),
            access: true,
            children: [],
            active: true
        },
        {
            name: "Listado de redactores",
            alt: "icono usuarios",
            path: "/bridge/redactores",
            image: require('@/assets/icons/navbar/users.svg'),
            access: userHaveAllowedRol("AUTOR"),
            children: [],
            active: true
        },
        {
            name: "Biblioteca de imágenes",
            alt: "icono imagen",
            path: "/bridge/imagenes",
            image: require('@/assets/icons/navbar/library.svg'),
            access: true,
            children: [],
            active: true
        },
        {
            name: "Listado de palabras negativas",
            alt: "icono palabras negativas",
            path: "/bridge/gestion-palabras-negativas",
            image: require('@/assets/icons/navbar/strikethrough.svg'),
            access: true,
            children: [],
            active: true

        },
        {
            name: "Docentes",
            alt: "icono usuarios",
            path: "/bridge",
            image: require('@/assets/icons/navbar/teacher.svg'),
            access: true,
            active: true,
            children: [
                {
                    name: "Gestor de docentes",
                    alt: "personalizar docente",
                    path: "/bridge/docentes",
                    image: require('@/assets/icons/navbar/edit-user.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Docentes sin vincular",
                    alt: "listado docentes",
                    path: "/bridge/docentes-listado",
                    image: require('@/assets/icons/navbar/folder.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Listado docentes externos",
                    alt: "docentes externos",
                    path: "/bridge/docentes-externos",
                    image: require('@/assets/icons/external-user.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Volver",
                    alt: "Atras",
                    path: "/bridge",
                    image: require('@/assets/icons/navbar/arrow-back.svg'),
                    access: true,
                    children: [],
                    active: true
                }
            ]

        },
        {
            name: "Redirecciones",
            alt: "icono redirección",
            path: "/bridge",
            image: require('@/assets/icons/navbar/redirection.svg'),
            access: true,
            children: [
                {
                    name: "Redirecciones generales",
                    alt: "icono redirección",
                    path: "/bridge/redirecciones-generales",
                    image: require('@/assets/icons/navbar/create_redirect.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Redirecciones encoladas",
                    alt: "icono redirección",
                    path: "/bridge/cola-redirecciones",
                    image: require('@/assets/icons/navbar/queue_redirection.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Eliminación de redirecciones",
                    alt: "icono eliminar redirección",
                    path: "/bridge/eliminar-redirecciones",
                    image: require('@/assets/icons/navbar/queue_redirection.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Volver",
                    alt: "Atras",
                    path: "/bridge",
                    image: require('@/assets/icons/navbar/arrow-back.svg'),
                    access: true,
                    children: [],
                    active: true
                },
            ],
            active: true
        },
        {
            name: "Lista de Typeforms",
            alt: "icono typeform",
            path: "/bridge/listado-typeform",
            image: require('@/assets/icons/navbar/form_icon.svg'),
            access: true,
            children: [],
            active: true
        },
        {
            name: "Comentarios",
            alt: "icono urls",
            path: "/bridge",
            image: require('@/assets/icons/navbar/chat.svg'),
            access: true,
            active: true,
            children: [
                {
                    name: "Listado comentarios",
                    alt: "icono comentarios",
                    path: "/bridge/listado-comentarios",
                    image: require('@/assets/icons/navbar/chat.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Gestión de estados para comentarios",
                    alt: "icono estados",
                    path: "/bridge/gestion-estados",
                    image: require('@/assets/icons/navbar/chat-settings.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Volver",
                    alt: "Atras",
                    path: "/bridge",
                    image: require('@/assets/icons/navbar/arrow-back.svg'),
                    access: true,
                    children: [],
                    active: true
                },

            ],

        },
        {
            name: "Cursos",
            alt: "icono curso",
            path: "/bridge",
            image: require('@/assets/icons/sidebar/aaff-icon.svg'),
            access: true,
            active: true,
            children: [
                {
                    name: "Gestión versiones cursos",
                    alt: "icono urls",
                    path: "/bridge/gestion-versiones",
                    image: require('@/assets/icons/navbar/course-version.svg'),
                    access: userHaveAllowedRol("PERMISO_CONTROL_VERSIONES_CURSO"),
                    children: [],
                    active: true
                },
                {
                    name: "Generación automática ficha-producto",
                    alt: "icono diagrama",
                    path: "/bridge/generacion-curso",
                    image: require('@/assets/icons/navbar/book.svg'),
                    access: userHaveAllowedRol("PERMISO_GENERADOR_CURSO"),
                    children: [],
                    active: true
                },
                {
                    name: "Lista URLs",
                    alt: "icono urls",
                    path: "/bridge/listado-urls?type=Cursos",
                    image: require('@/assets/icons/navbar/link.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Cursos prioritarios para docentes",
                    alt: "icono curso prioritario",
                    path: "/bridge/cursos-prioritarios",
                    image: require('@/assets/icons/navbar/priority-course.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Generación de campos para ficha de producto",
                    alt: "icono curso prioritario",
                    path: "/generacion-campos-ficha-producto",
                    image: require('@/assets/icons/navbar/course-general.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Documentos de campos ficha producto encolados",
                    alt: "icono cola campos ficha producto",
                    path: "/cola-campos-ficha-producto",
                    image: require('@/assets/icons/navbar/course-general.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Volver",
                    alt: "Atras",
                    path: "/bridge",
                    image: require('@/assets/icons/navbar/arrow-back.svg'),
                    access: true,
                    children: [],
                    active: true
                },

            ]
        },
        {
            name: "Blogs",
            alt: "icono blog",
            path: "/bridge",
            image: require('@/assets/icons/navbar/blogs-general.svg'),
            access: true,
            active: true,
            children: [
                {
                    name: "Blogs encolados para copia SEO",
                    alt: "icono cola",
                    path: "/bridge/cola-copias",
                    image: require('@/assets/icons/navbar/queue-files.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Lista URLs",
                    alt: "icono urls",
                    path: "/bridge/listado-urls?type=Blogs",
                    image: require('@/assets/icons/navbar/link.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Volver",
                    alt: "Atras",
                    path: "/bridge",
                    image: require('@/assets/icons/navbar/arrow-back.svg'),
                    access: true,
                    children: [],
                    active: true
                },

            ]
        },
        {
            name: "Media Valet",
            alt: "icono mediaValet",
            path: "/bridge/mediaValet",
            image: require('@/assets/icons/navbar/chat-settings.svg'),
            aria_current: "Media Valet",
            access: true,
            children: [],
            active: true
        },
        {
            name: "Logos y diplomas",
            alt: "icono logos y diplomas",
            path: "/bridge",
            image: require('@/assets/icons/navbar/logo-diploma.svg'),
            aria_current: "Logos y diplomas",
            access: true,
            children: [
                {
                    name: "Gestión de logos y diplomas",
                    alt: "icono imagen",
                    path: "/bridge/gestion-logos-diplomas",
                    image: require('@/assets/icons/navbar/library.svg'),
                    access: true,
                    children: [],
                    active: true

                },
                {
                    name: "Asignación de logos y diplomas a cursos",
                    alt: "icono curso prioritario",
                    path: "/bridge/asignacion-logos-diplomas",
                    image: require('@/assets/icons/navbar/course_list_logo.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Gestión logos encolados",
                    alt: "icono logos encolados",
                    path: "/bridge/cola-logos",
                    image: require('@/assets/icons/navbar/cola-logos.svg'),
                    aria_current: "Gestión logos encolados",
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Gestión diplomas encolados",
                    alt: "icono diplomas encolados",
                    path: "/bridge/cola-diplomas",
                    image: require('@/assets/icons/navbar/cola-diplomas.svg'),
                    aria_current: "Gestión diplomas encolados",
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Volver",
                    alt: "Atras",
                    path: "/bridge",
                    image: require('@/assets/icons/navbar/arrow-back.svg'),
                    access: true,
                    children: [],
                    active: true
                },
            ],
            active: true
        },
        {
            name: "Listado programas formativos",
            alt: "icono programa formativo",
            path: "/bridge/programas-formativos",
            image: require('@/assets/icons/navbar/training-program.svg'),
            access: true,
            active: true,
            children: []
        },
        {
            name: "Búsqueda y reemplazo",
            alt: "icono búsqueda",
            path: "/bridge",
            image: require('@/assets/icons/navbar/search-replace.svg'),
            access: false,
            active: false,
            children: [
                {
                    name: "Búsqueda y reemplazo de texto de manera masiva",
                    alt: "icono búsqueda reemplazo",
                    path: "/bridge/busqueda-reemplazo-masivo",
                    image: require('@/assets/icons/navbar/find-replace.svg'),
                    aria_current: "Búsqueda y reemplazo de texto de manera masiva",
                    access: userHaveAllowedRol("BUSCAR_Y_REEMPLAZAR"),
                    children: [],
                    active: false
                },
                {
                    name: "Reemplazos encolados",
                    alt: "icono búsqueda reemplazo",
                    path: "/bridge/cola-reemplazos",
                    image: require('@/assets/icons/replacement-tail.svg'),
                    aria_current: "Reemplazos encolados",
                    access: userHaveAllowedRol("BUSCAR_Y_REEMPLAZAR"),
                    children: [],
                    active: false
                },
                {
                    name: "Volver",
                    alt: "Atras",
                    path: "/bridge",
                    image: require('@/assets/icons/navbar/arrow-back.svg'),
                    access: true,
                    children: [],
                    active: false
                },
            ]
        },
        {
            name: "Volver",
            alt: "Atras",
            path: "/",
            image: require('@/assets/icons/navbar/arrow-back.svg'),
            access: false,
            children: [],
            active: false
        }
    ]
}

