<template>
    <v-container>
        <template v-if="loading">
            <Loading/>
        </template>
        <template v-if="error && !pending_language">
            <v-row>
                <div class="justify-content-center mt-6">
                    <v-col cols="12">
                        <img :src="require('@/assets/icons/info.svg')" class="icon-info"
                             alt="scheme-maps"> <span class="error-msg fs-6 mt-4 text-center">{{ error_msg }}</span>
                    </v-col>
                </div>
            </v-row>
        </template>
        <template v-if="pending_language">
            <p>Selecciona un idioma para continuar</p>
        </template>
        <template v-if="!loading && !error && !pending_language">
            <v-row id="root">
                <v-col cols="4" lg=3 class="box-general">
                    <div class="sidebar-timeline">
                        <ul class="timeline list-group" id="list-timeline">
                            <li><a class="timeline-item list-group-item list-group-item-action"
                                   href="#commercial-data">Datos
                                comerciales</a></li>
                            <li><a class="timeline-item list-group-item list-group-item-action"
                                   href="#keywords-data">Datos
                                Keywords</a></li>
                            <li><a class="timeline-item list-group-item list-group-item-action"
                                   href="#additional-info">Información complementaria</a>
                            </li>
                            <li><a class="timeline-item list-group-item list-group-item-action"
                                   href="#media-content-data">Datos
                                multimedia</a>
                            </li>
                            <li><a class="timeline-item list-group-item list-group-item-action"
                                   href="#upload-img">Subida de imágenes</a></li>
                            <li><a class="timeline-item list-group-item list-group-item-action"
                                   href="#categorization">Categorización</a>
                            </li>
                            <li v-if="data.tipo_url === 'ficha_producto'"><a
                                class="timeline-item list-group-item list-group-item-action"
                                href="#review">Revisiones</a></li>
                            <li><a class="timeline-item list-group-item list-group-item-action"
                                   href="#options-review">Opciones de visualización</a></li>
                            <li><a class="timeline-item list-group-item list-group-item-action"
                                   href="#actions">Acciones</a></li>
                        </ul>
                    </div>
                </v-col>
                <v-col cols="12" md="8" lg="9" class="box-content personalize-scroll">
                    <v-row>
                        <v-col cols="12" class="text-start">
                            <v-img :src=" 'https://cdn.mediavalet.com/eunl/euroinnova/'+logo_portal" class="logo-enterprise text-start" alt="Logo empresa"></v-img>
                        </v-col>
                        <v-col cols="12" v-if=draft_message class="draft-message">
                            <p>BORRADOR RECUPERADO: Estás editando un contenido <u>NO PUBLICADO</u></p>
                        </v-col>

                        <v-col cols="12" class="title-course">Editor SEO de: {{ title }}</v-col>

                        <v-col cols="12" v-if="data.tipo_url === 'ficha_producto'">
                            <p class="subtitle-course">
                                Código innotutor: {{
                                    data.innotutorCode
                                }}</p>
                        </v-col>

                        <v-col cols="12">
                            <div v-if="!url_input">
                                    <span class="subtitle-course">
                                        URL: <a :href="'https://'+url_empresa+'/'+data.url.toLowerCase()+'?reset=1'"
                                                target="_blank">{{ data.url }}</a>
                                         <v-btn @click=convertToInput(true) class="btn button-bg-white ml-3"
                                                v-if="enabled_change_url"><v-icon>mdi-pencil</v-icon></v-btn>
                                    </span>
                            </div>
                            <div v-else>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model.trim="new_url"
                                            variant="underlined"
                                            class="text-field-primary field-with-label d-block input-error"
                                            :error="error_url" hide-details>
                                        </v-text-field>
                                        <span v-if="error_url"
                                              class="input-error msg-error">{{ error_url_msg }}</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-btn class="btn button-bg-white mr-3" @click="changeURL">Guardar</v-btn>
                                        <v-btn class="btn button-bg-white" @click="convertToInput(false)">Cancelar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>

                        <v-col cols="12" v-if="data.tipo_url === 'ficha_producto'">
                            <v-btn class="btn button-bg-blue mr-3 my-2"
                                   :href="'http://innotutor.com/AccionesFormativas/CursoVisualizar.aspx?id='+data.innotutorID"
                                   target="_blank">VER EN INNOTUTOR
                            </v-btn>
                        </v-col>
                    </v-row>
                    <FormSeo :data="data" :draft="draft_message" :update-form="loadPage" ></FormSeo>
                </v-col>
            </v-row>

        </template>
    </v-container>
</template>


<script>
import FormSeo from "@/components/Forms/FormSeo";
import {computed, onBeforeMount, ref, watch} from "vue";
import {changeURLWS, getUrlDataWS} from "@/utils/editor_course_blog";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {hasPermission, userHaveAllowedRol} from "@/utils/auth";
import Loading from "@/components/Generic/Loading.vue";
import FormCourse from "@/components/Forms/FormCourse.vue";

export default {
    name: "EditorSeo",
    components: {FormCourse, Loading, FormSeo},
    setup() {
        const store = useStore();
        let route = useRoute();
        const router = useRouter();

        let url_empresa = computed(() => store.getters.getURLEmpresa).value;
        let language = computed(() => store.getters.getLanguageISO);
        let current_portal = computed(()=> store.getters.getPortalID).value;
        let portals = computed(() => store.getters.getStoredPortals).value;
        let tipo_url = ref();
        let data = ref([]);
        let title = ref("");
        let loading = ref(false);
        let error = ref(false);
        let draft_message = ref(false);
        let error_msg = ref("");
        let first_visit = true;
        let pending_language = ref(true);
        let server_error = false;

        let enabled_change_url = ref(false)
        let url_input = ref(false)
        let new_url = ref()
        let url_origen;
        let error_url = ref(false);
        let error_url_msg = ref('');
        let logo_portal = ref('')

        onBeforeMount(async () => {
            store.commit('setShowBreadCrumb', false)
            localStorage.setItem('show_bread_crumb', 'false');
            await hasPermission(route);
            await loadPage()
            await logoPortal()
        })

        watch(language, async (newValue, oldValue) => {
            pending_language.value = true;
            if (!first_visit && variableIsDefined(newValue)) {
                if (language.value !== 0) {
                    await getSeoData(true);
                    if (error.value && !server_error) {
                        await getSeoData(false);
                    }
                }
            }
            first_visit = false;
        });

        async function logoPortal(){
            portals.forEach((portal)=>{
                if (portal.id_portal === current_portal){
                    logo_portal.value = portal.logo
                }
            })
        }


        /*
        *
        * Carga todos los datos necesarios
        * del contenido SEO de la URL, se llama
        * cuando se publica la URL para "recargar" el componente
        *
        * */
        const loadPage = async () => {

            if(language.value !== 0){
                await getSeoData(true);
                if (error.value && !server_error) {
                    await getSeoData(false);
                }
            }else{
                pending_language.value = true;
            }
            url_origen = _.cloneDeep(data.value.url)
        }

        async function getSeoData(search_draft) {
            await router.isReady();
            pending_language.value = false;
            loading.value = true;

            error.value = false;
            let url = route.query.url;
            let query_portal_id = route.query.portal;
            let language_iso = route.query.language;

            if (url && query_portal_id && language_iso) {
                let response = await getUrlDataWS(url, 'blog', search_draft);

                if (!variableIsDefined(response.server_error)) {
                    if (response.result) {
                        draft_message.value = search_draft;
                        if (variableIsDefined(response.data['articles'][0]) && variableIsDefined(response.data['articles'][0].tipo_url)) {

                            data.value = response.data['articles'][0];
                            tipo_url.value = response.data['articles'][0].tipo_url

                            enabled_change_url.value = getUrlEditPermission(response.data['articles'][0]['isActive'], response.data['articles'][0]['isPublished'])

                            if (response.data['articles'][0].tipo_url === 'ficha_producto') {
                                title.value = response.data['articles'][0].titulo_curso;
                                localStorage.setItem('type_url', 'Cursos');
                                store.commit('setTypeUrl', 'Cursos');
                            } else {
                                title.value = response.data['articles'][0].title;
                                localStorage.setItem('type_url', 'Blogs');
                                store.commit('setTypeUrl', 'Blogs');
                            }

                            store.commit('setShowBreadCrumb', true)
                            localStorage.setItem('show_bread_crumb', 'true');

                        } else {
                            error.value = true;
                            error_msg.value = 'No se ha encontrado la URL para el portal e idioma seleccionados'
                        }
                    } else {
                        error.value = true;
                        error_msg.value = 'No se ha encontrado la URL para el portal e idioma seleccionados'
                        showNotification('error', response.errors)
                    }
                } else {
                    error.value = true;
                    error_msg.value = 'Se ha producido un error al realizar la petición'
                    server_error = true;
                }

            } else {
                error.value = true;
                error_msg.value = 'No se han recibido los datos necesarios para editar una url'
            }
            loading.value = false
        }

        function convertToInput(value) {
            error_url.value = false
            new_url.value = url_origen
            url_input.value = value
        }

        async function changeURL() {
            let regex_url
            error_url.value = false;
            error_url_msg.value = '';
            if(variableIsDefined(new_url.value)){
                if (data.value.tipo_url==='contenido_seo'){
                    regex_url = /^[a-z0-9\/\-_.]+$/
                }else if(data.value.tipo_url==='ficha_producto'){
                    regex_url = /^[a-zA-Z0-9\/\-_.]+$/i
                }
                if (regex_url.test(new_url.value)) {
                    let response = await changeURLWS(url_origen, new_url.value);
                    if (response['result']) {
                        data.value.url = new_url.value
                        url_origen = new_url.value
                        url_input.value = false

                        await router.push({
                            query: {
                                url: url_origen,
                                portal: store.getters.getPortalID,
                                language: store.getters.getLanguageISO
                            }
                        })
                    } else {
                        showNotification('error', response['errors'])
                        error_url.value = true;
                        error_url_msg.value = 'Esta URL ya está creada y no puede sobreescribirse';
                    }
                }else{
                    error_url.value = true;
                    error_url_msg.value = 'El formato de la URL no es válido';
                }
            }
            else {
                error_url.value = true;
                error_url_msg.value = 'La URL no puede estar vacía';
            }
        }

        function getUrlEditPermission(active, published) {
            switch (tipo_url.value) {
                case 'contenido_seo':
                    return (userHaveAllowedRol('MODIFICAR_URL_BLOG') && active && published)
                case 'ficha_producto':
                    return (userHaveAllowedRol('MODIFICAR_URL_CURSO') && active && published)
            }
        }

        return {
            changeURL,
            convertToInput,
            loadPage,

            data,
            draft_message,
            enabled_change_url,
            error,
            error_msg,
            error_url,
            error_url_msg,
            loading,
            new_url,
            pending_language,
            title,
            url_empresa,
            url_input,
            logo_portal,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/views/editor-seo-course.scss";
</style>