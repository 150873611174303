<template>
    <BaseModalTemplate
        :title="`${title_modal} docente`"
        :open-close-dialog="displayDialog"
        :loading="general_loading"
        :hide_actions="disable_update"
    >
        <template #body-modal-content>
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <v-form ref="form" :displayDialog="displayDialog" :disabled="disable_update">
                            <v-row>
                                <v-col cols="12" v-if="teacher.externo">
                                        <span class="input-title external-teacher-warning">
                                            Este docente es un usuario externo*
                                        </span>
                                </v-col>
                                <!--IMAGEN DOCENTE-->
                                <v-col cols="12">
                                        <span class="input-title">
                                            Imagen de perfil *
                                        </span>
                                </v-col>
                                <v-col cols="12" sm="8" md="4">
                                    <v-img v-if="image" class="preview" :src="image"></v-img>
                                    <v-file-input v-if="!disable_update"
                                                  class="text-field-primary field-with-label"
                                                  accept="image/webp"
                                                  prepend-icon="mdi-camera"
                                                  label="Ingrese su imagen"
                                                  type="file"
                                                  show-size
                                                  :rules="file_rules"
                                                  v-model="form_data.imagen"
                                                  @change="previewImage(form_data.imagen)"
                                                  @click:clear="clearImage"
                                                  :loading="uploading"
                                                  hide-details="auto"
                                                  variant="underlined"
                                                  chips
                                    ></v-file-input>
                                    <span class="input-error msg-error" v-if="form_error.imagen">
                                    {{ form_error.imagen }}</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                        <span class="input-title">
                                            Identificador innotutor *
                                        </span>
                                    <template v-if="form_type === 'nuevo'">
                                        <v-autocomplete
                                            class="input-error select-primary"
                                            placeholder="Pulse para asignar usuario"
                                            v-model="selected_user"
                                            :items="list_available_users"
                                            item-title="name"
                                            item-value="username"
                                            @update:modelValue="selectUser()"
                                            variant="underlined"
                                            return-object
                                            hide-details
                                            :disabled="disable_update || disable_username"
                                            :class="{error :variableIsDefined(form_error.usuario)}"
                                            no-data-text="No hay datos disponibles"
                                            :menu-props="{width:400}"
                                            :custom-filter="customFilter"
                                        >
<!--                                            <template v-slot:item="{ props, item }">
                                                <v-list-item
                                                    v-bind="props"
                                                    :title="item?.raw?.name"
                                                >
                                                </v-list-item>
                                            </template>
                                            <template v-slot:selection="{ item }">
                                                <div>
                                                    <span>{{ item?.raw?.username }}</span>
                                                </div>
                                            </template>-->
                                        </v-autocomplete>
                                        <span class="input-error msg-error" v-if="form_error.usuario">
                                                {{ form_error.usuario }}
                                            </span>
                                    </template>
                                    <template v-else>
                                        <span>{{ form_data.usuario }}</span>
                                    </template>
                                </v-col>
                                <v-col cols="12" md="6">
                                        <span class="input-title">
                                            Nombre y apellidos *
                                        </span>
                                    <template v-if="form_type == 'nuevo'">
                                        <v-text-field
                                            v-model="form_data.nombre"
                                            :class="{error :form_error.nombre}"
                                            :error="form_error.nombre"
                                            class="text-field-primary input-error"
                                            placeholder="Seleccione un docente de la lista anterior"
                                            hide-details="auto"
                                            variant="underlined"
                                            disabled
                                            required
                                        >
                                        </v-text-field>
                                        <span class="input-error msg-error" v-if="form_error.nombre">
                                            {{ form_error.nombre }}
                                        </span>
                                    </template>
                                    <template v-else>
                                        <span>{{ form_data.nombre }}</span>
                                    </template>
                                </v-col>
                            </v-row>
                            <v-row>
                                <!--TITULACION DOCENTE-->
                                <v-col cols="12">
                                        <span class="input-title">
                                            Titulación *
                                        </span>
                                </v-col>
                                <v-col>
                                    <v-textarea
                                        v-model="form_data.titulacion"
                                        :class="{error :form_error.titulacion}"
                                        :error="form_error.titulacion"
                                        class="text-area-primary input-error "
                                        placeholder="Titulación del docente."
                                        auto-grow
                                        rows="2"
                                        hide-details
                                        bg-color="white"
                                        color="transparent"
                                    >
                                    </v-textarea>
                                    <span class="input-error msg-error" v-if="form_error.titulacion">
                                    {{ form_error.titulacion }}</span>
                                </v-col>
                            </v-row>

                            <v-row>
                                <!--EXPERIENCIA DEL DOCENTE-->
                                <v-col cols="12">
                                        <span class="input-title">
                                            Experiencia
                                        </span>
                                </v-col>
                                <v-col>
                                    <v-textarea
                                        v-model="form_data.experiencia"
                                        :class="{error :form_error.experiencia}"
                                        :error="form_error.experiencia"
                                        class="text-area-primary input-error "
                                        placeholder="Experiencia del docente"
                                        auto-grow
                                        rows="2"
                                        hide-details
                                        bg-color="white"
                                        color="transparent"
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <template v-if="form_type !== 'nuevo'">
                                <v-row>
                                    <v-col cols="12">
                                        <span class="input-title">Áreas actuales del docente</span>
                                    </v-col>
                                </v-row>

                                <v-row v-if="categories_teacher.length===0">
                                    <v-col class="pending-data-text" cols="12">
                                        <span>Este docente no tiene ningún área asignada</span>
                                    </v-col>
                                </v-row>
                                <v-row v-else>
                                    <div v-for="area_present in categories_teacher"
                                         :class="'div-area div-area-'+area_present.id_categoria">
                                        <v-col auto class="boxes-areas">
                                            <v-card class="box_area_teacher">
                                                <v-card-text class="text-area">{{
                                                        area_present.nombre
                                                    }}
                                                </v-card-text>
                                                <div v-if="!disable_update">
                                                    <v-icon class="icons-close"
                                                            @click="removeAreaFromTeacher(area_present.id_categoria)">
                                                        mdi-close
                                                    </v-icon>
                                                    <v-progress-circular
                                                        class="icons-loading"
                                                        indeterminate
                                                        :size="20"
                                                        :width="2"
                                                        color="blue"
                                                    ></v-progress-circular>
                                                </div>
                                            </v-card>
                                        </v-col>
                                    </div>
                                    <v-row class="box-insert-area-msg">
                                        <v-col>
                                            <span class="msg-insert-area">
                                                Para asignar áreas tienes que terminar de crear el docente.
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-row>
                                <v-row>
                                    <!--Selector de categorias-->
                                    <v-col cols="12">
                                            <span class="input-title">
                                                Cursos asignados al docente
                                            </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <!--Cursos asignados al docente -->
                                    <v-col cols="12" v-if="courses_areas.length > 0">
                                            <span>
                                                TIENE CURSOS
                                            </span>
                                    </v-col>
                                    <v-col cols="12" v-else>
                                            <span>
                                                Actualmente sin cursos
                                            </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <!--Linkedin del docente-->
                                    <v-col cols="12">
                                        <p class="input-title mb-0">
                                            Linkedin
                                        </p>
                                        <p>Solo podrá modificar esta dato desde innotutor</p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-if="form_data.linkedin !== null"
                                            v-model="form_data.linkedin"
                                            disabled
                                            class="text-area-primary"
                                            bg-color="white"
                                            hide-details
                                        >
                                        </v-text-field>
                                        <p v-else class="pending-data-text"><i>Sin especificar</i></p>
                                    </v-col>
                                </v-row>
                            </template>
                            <v-row>
                                <!--Estado del docente-->
                                <v-col cols="12"
                                >
                                        <span class="input-title">
                                            Estado docente
                                        </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-checkbox-btn
                                        v-model="form_data.activo"
                                        label="Activado"
                                        class="check-active"
                                        :disabled="disable_update"
                                        :true-value="1"
                                        :false-value="0"
                                    ></v-checkbox-btn>
                                </v-col>
                            </v-row>

                            <v-row>
                                <!--Estado del docente-->
                                <v-col cols="12"
                                >
                                        <span class="input-title">
                                            Linkedin
                                        </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-checkbox-btn
                                        v-model="form_data.showLinkedin"
                                        label="Mostrar linkedin"
                                        class="check-active"
                                        :disabled="disable_update"
                                        :true-value="1"
                                        :false-value="0"
                                    ></v-checkbox-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template #actions-modal>
            <v-row>
                <v-col cols="12">
                    <div class="btn-search-form">
                        <v-btn
                            @click="sendForm"
                            :disabled="disabled_btn_save"
                            class="btn button-bg-blue"
                            type="submit"
                            hide-details
                            typeof="submit">
                            Guardar
                        </v-btn>
                    </div>
                    <p class="general-error-msg" v-if="has_errors">Revise los campos</p>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import * as Yup from "yup";
import {getAreasByTeacherWS, insertTeacherWS} from "@/utils/teachers";
import _ from 'lodash';
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {uploadImgWS} from "@/utils/aiseo_petition";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "FormTeacher",
    components: {BaseModalTemplate},
    methods: {variableIsDefined},
    props: {
        all_teachers: Array,
        disable_update: Boolean,
        displayDialog: Function,
        getAllTeachers: Function,
        list_available_users: Array,
        teacher: Object,
        title_modal: String,
    },

    setup(props) {
        let general_loading = ref(false)
        /* Variables relacionadas con el docente seleccionado */
        let received_data = _.cloneDeep(props.teacher);
        let selected_user = ref(null);

        /* Variables relacionadas con el formulario y su envío */
        let form_type = ref("");
        let form_data = ref({});
        let form_error = ref({});
        let has_errors = ref(false);

        /* Variables para desactivar campos del formulario en modo vista */
        let disable_username = ref(false);
        let disabled_btn_save = ref(false)

        /* Variables relaciondas con la imagen del docente */
        let file_rules = [files => !files || !files.some(file => file.size > 2e6) || 'ERROR. La imagen debe pesar menos de 2 MB']
        let image = ref(null);
        let teacher_image = ref('');
        let uploading = ref(false)

        /* Variables relacionadas con los cursos y areas asignados al docente */
        const courses_areas = ref([])
        const categories_teacher = ref([]);


        onBeforeMount(async () => {
            general_loading.value = true
            form_type.value = props.title_modal.toLowerCase();

            if (form_type.value === 'vista') {
                await loadTeacher();
                //Si el docente tiene algún área asignada se cargan los cursos
                if (categories_teacher.value.length > 0) {
                    await loadTeacherCourses()
                }
            }
            general_loading.value = false
        })


        /*
        *
        * INSERCIÓN DE NUEVO DOCENTE
        *
        * */

        //
        //  RELLENA LOS DATOS DEL DOCENTE SELECCIONADO
        //  EN EL SELECT DE IDENTIFICADOR INNOTUTOR
        //
        function selectUser() {
            form_data.value.usuario = selected_user.value.username;
            form_data.value.nombre = selected_user.value.name;
            form_data.value.id_docente = selected_user.value.id_user;
        }

        //  SUBE LA IMAGEN SELECCIONADA AL CDN
        //  DE EUROINNOVA
        async function uploadImg() {
            uploading.value = true;
            if (variableIsDefined(form_data.value.imagen)) {
                const response = await uploadImgWS(form_data.value.imagen, 'docente', 'imagen', form_data.value.id_docente)
                if (response.data.result) {
                    teacher_image.value = response.data.data['fileID'].toString();
                    showNotification('success', 'Imagen subida correctamente')
                }
            }
            uploading.value = false
        }

        //  LIMPIA EL CAMPO DE IMAGEN
        function clearImage() {
            image.value = '';
        }

        // MUESTRA UNA PREVISUALIZACIÓN DE LA NUEVA
        // IMAGEN DE PERFIL ELEGIDA, NO LA SUBE AL CDN
        function previewImage() {
            let reader = new FileReader();
            const file = form_data.value.imagen;
            reader.readAsDataURL(file);

            reader.onload = e => {
                image.value = e.target.result;
            }
        }

        //  ENVÍA EL FORMULARIO CON LOS DATOS NECESARIOS
        //  PARA CREAR UN NUEVO DOCENTE
        async function sendForm() {
            disabled_btn_save.value = true;

            await dataValidation();

            if (Object.keys(form_error.value).length === 0) {
                has_errors.value = false;
                await uploadImg();
                (teacher_image.value !== null && teacher_image.value !== '') ?
                    form_data.value.imagen_final = teacher_image.value :
                    form_data.value.imagen_final = null;

                const {result, data, errors} = await insertTeacherWS(form_data.value);

                if (result && data) {
                    showNotification('success', '¡Docente subido correctamente!')
                    props.getAllTeachers();
                    props.displayDialog();
                } else {
                    showNotification('error', errors)

                }

            } else {
                has_errors.value = true;
            }
            disabled_btn_save.value = false
        }


        // SE VALIDAN LOS DATOS DEL FORMULARIO
        async function dataValidation() {
            let $error_msg = $('.error-msg')
            $error_msg.hide();
            form_error.value = {};
            const regex = /^[a-zA-ZÀ-ÿ {º}{ª}{-}{;}{.}{,}]+$/;
            const schemaForm = Yup.object().shape({
                nombre: Yup.string().required(true),
                usuario: Yup.string().required(true),
                titulacion: Yup.string().required(true),
            })

            try {
                await schemaForm.validate(form_data.value, {abortEarly: false});
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = 'El campo está vacío';
                })
            }

            Object.keys(form_data.value).forEach(function (key) {
                if (key === 'usuario' && !regex.test(form_data.value[key]) && form_data.value[key] !== "") {
                    form_error.value[key] = 'Introduce un dato válido'
                }
            })

            if (!variableIsDefined(form_data.value.imagen)) {
                form_error.value['imagen'] = 'La imagen es obligatoria'
            } else {
                if (form_data.value.imagen.type !== 'image/webp') {
                    form_error.value['imagen'] = 'La imagen debe estar en WEBP'
                }
            }

            const exist_teacher = props.all_teachers.some(teacher => teacher.usuario === form_data.value.usuario)

            if (exist_teacher) {
                form_error.value.usuario = 'Ya existe un docente con el identificador innotutor seleccionado'
            }

            if (!variableIsDefined(form_data.value.activo)) {
                form_data.value.activo = 0;
            }
        }


        /*
        *
        * VISTA DE DOCENTE YA EXISTENTE
        *
        * */

        //   CARGA LOS CURSOS ASIGNADOS AL DOCENTE
        const loadTeacherCourses = async () => {
            /*const response = await getURLByPortalWS(
                'courses',
                1 ,
                40 ,
                {category: JSON.stringify({type:2, id: categories_teacher.value[0].id_categoria})}
            )*/

            /*
            *
            * Cargar todos los cursos que tiene asignados el docente
            *
            * */

            //courses_areas.value = response['data']['data']
        }

        //   CARGA LOS DATOS DEL DOCENTE SELECCIONADO
        async function loadTeacher() {
            disable_username.value = false
            form_data.value = received_data
            selected_user.value = {}
            selected_user.value.name = form_data.value.nombre
            selected_user.value.username = form_data.value.usuario
            image.value = form_data.value.imagen;
            form_data.value.imagen = null;
            await getAreasByTeacher();
        }

        // CARGA LAS AREAS ACTUALES QUE TIENE ASIGNADAS EL DOCENTE
        async function getAreasByTeacher() {
            const response = await getAreasByTeacherWS(form_data.value.id_docente);
            (response['result'] && response['data']) ? (categories_teacher.value = response['data']) : showNotification('error', response['errors'])
        }

        const customFilter = (item_text, query_text, item) => {
            const query = query_text.toLowerCase();
            const text = item_text.toLowerCase();

            if (item.value.is_group_name || item.value.is_divider){
                return true
            }

            return text.includes(query)
        }

        return {
            customFilter,
            clearImage,
            previewImage,
            selectUser,
            sendForm,
            uploadImg,

            general_loading,
            categories_teacher,
            courses_areas,
            disable_username,
            disabled_btn_save,
            file_rules,
            form_data,
            form_error,
            form_type,
            has_errors,
            image,
            selected_user,
            uploading,
        }
    }
}

</script>

<style scoped lang="scss">
@import "@/styles/components/form-docentes.scss";
</style>