<template>
    <BaseModalTemplate
        :loading="loading"
        :send-form="sendForm"
        :open-close-dialog="showDialog"
        card_extra_classes="w-100"
    >
        <template #modal-header>
            <span class="title-modal" v-if="mode === 'edit'">Edición de Typeform con id: {{ typeform.id_formulario }}</span>
            <span class="title-modal" v-else>Creación de Typeform</span>
            <v-btn class="btn-close-modal" @click="showDialog()" :ripple="false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
        <template #body-modal-content>
            <v-row>
                <v-col cols="12" v-if="mode === 'create'">
                    <p class="input-title">
                        ID*
                    </p>
                    <v-text-field
                        v-model.trim="form_data.typeform_id"
                        placeholder="ID"
                        required
                        class="input-error text-field-secondary"
                        :error="errors.typeform_id"
                        :disabled="disabled_general"
                        bg-color="white"
                        color="transparent"
                        hide-details
                    ></v-text-field>
                    <span v-if="errors.typeform_id" class="input-error msg-error">
                            Introduzca un ID válido
                        </span>
                </v-col>
                <v-col cols="12">
                        <span class="input-title">
                            Nombre
                        </span>
                    <v-text-field
                        v-model.trim="form_data.name"
                        placeholder="Nombre"
                        class="input-error text-field-secondary"
                        :error="errors.name"
                        :disabled="disabled_general"
                        bg-color="white"
                        color="transparent"
                        hide-details
                        required
                    ></v-text-field>
                    <span v-if="errors.name"
                          class="input-error msg-error">El campo no puede estar vacío
                        </span>
                </v-col>
                <v-col cols="12">
                        <span class="input-title">
                            Curso
                        </span>
                    <SelectTertiary
                        :items="course_select_items"
                        v-model="selected_course"
                        item-title="nombre"
                        item-value="id_curso"
                        placeholder="Selecciona un tipo de curso"
                        class="input-error"
                        :error="errors.course"
                        :disabled="disabled_general"
                        return-object
                        :default_values="selected_course"
                    >
                    </SelectTertiary>
                    <span v-if="errors.course"
                          class="input-error msg-error">Debe seleccionar una opción
                        </span>
                </v-col>
                <v-col cols="12" lg="6">
                        <span class="input-title">
                            Remitente
                        </span>
                    <SelectTertiary
                        :items="dispatcher_select_items"
                        v-model="selected_rem"
                        item-title="nombre"
                        item-value="id_remitente"
                        placeholder="Selecciona a un remitente"
                        class="input-error"
                        :error="errors.remitente"
                        :disabled="disabled_general"
                        return-object
                        :default_values="selected_rem"
                    ></SelectTertiary>
                    <span v-if="errors.remitente"
                          class="input-error msg-error">Debe seleccionar una opción
                                            </span>
                </v-col>
                <v-col cols="12" lg="6">
                                            <span class="input-title">
                                                Campaña
                                            </span>
                    <SelectTertiary
                        :items="campaign_select_items"
                        v-model="selected_campaign"
                        item-title="nombre"
                        item-value="id_campanya"
                        placeholder="Selecciona una campaña"
                        class="input-error"
                        :error="errors.campaign"
                        :disabled="disabled_general"
                        return-object
                        :default_values="selected_campaign"
                    ></SelectTertiary>
                    <span v-if="errors.campaign"
                          class="input-error msg-error">Debe seleccionar una opción
                                            </span>
                </v-col>
                <v-col cols="12" lg="6">
                        <span class="input-title">
                            Enviar cupón a ZOHO
                        </span>
                    <SelectTertiary
                        :items="senders_zoho_select_items"
                        v-model="selected_sender_zoho"
                        item-title="nombre"
                        item-value="nombre"
                        placeholder="Selecciona un valor"
                        class="input-error"
                        :error="errors.senders_zoho"
                        :disabled="disabled_general"
                        return-object
                        :default_values="selected_sender_zoho"
                    ></SelectTertiary>
                    <span v-if="errors.senders_zoho"
                          class="input-error msg-error">Debe seleccionar una opción
                                            </span>
                </v-col>
                <v-col cols="12" lg="6">
                        <span class="input-title">
                            ID Canal
                        </span>
                    <SelectTertiary
                        :items="channel_select_items"
                        v-model="selected_channel"
                        item-title="nombre"
                        item-value="id_canal"
                        placeholder="Selecciona un valor"
                        class="input-error"
                        :error="errors.id_canal"
                        :disabled="disabled_general"
                        return-object
                        :default_values="selected_channel"
                    ></SelectTertiary>
                    <span v-if="errors.id_canal"
                          class="input-error msg-error">El campo no puede estar vacío
                    </span>
                </v-col>
                <v-col cols="12" v-if="mode === 'edit'">
                    <v-btn
                        class="btn button-primary"
                        :disabled="disabled_general"
                    >
                        Ver Looker
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>


<script>
import {onBeforeMount, ref} from "vue";
import {
    createTypeformWS,
    editTypeformWS,
    getAvailableCampaignsWS,
    getAvailableCoursesWS,
    getAvailableDispatchersWS, getAvailableSendersZohoWS, getAvailableChannelsWS
} from "@/utils/typeform";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import Loading from "@/components/Generic/Loading.vue";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import SelectTertiary from "@/components/Generic/SelectTertiary.vue";

export default {
    name: "FormTypeform",
    components: {SelectTertiary, BaseModalTemplate, Loading},
    props: {
        typeform: {
            type: Object,
            required: false,
        },
        showDialog: {
            type: Function,
            required: true,
        },
        refreshTable: {
            type: Function,
            required: true,
        },
        mode: {
            type: String,
            required: true
        }
    },

    setup(props) {
        let loading = ref(false)
        let form_data = ref({})
        let errors = ref({})

        let disabled_general = ref(false)

        let selected_course = ref()
        let selected_campaign = ref()
        let selected_rem = ref()
        let selected_sender_zoho = ref()
        let selected_channel = ref()

        let course_select_items = ref([]);
        let senders_zoho_select_items = ref()
        let dispatcher_select_items = ref([]);
        let campaign_select_items = ref([]);
        let channel_select_items = ref([]);

        /*
        * FIN DE VARIABLES
        * *************************************************/


        onBeforeMount(async () => {
            loading.value = true
            await loadAvailableCourses();
            await loadAvailableCampaigns();
            await loadAvailabeDispatchers();
            await loadAvailabeSendersZoho();
            await loadAvailabeChannels();
            if (props.mode === 'edit') {
                loadTypeform()
            }
            loading.value = false
        })


        const loadAvailableCourses = async () => {
            let response = await getAvailableCoursesWS();
            if (response['result']) {
                course_select_items.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
        }

        const loadAvailableCampaigns = async () => {
            let response = await getAvailableCampaignsWS();
            if (response['result']) {
                campaign_select_items.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
        }

        const loadAvailabeSendersZoho = async () => {
            let response = await getAvailableSendersZohoWS();
            if (response['result']) {
                senders_zoho_select_items.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
        }

        const loadAvailabeDispatchers = async () => {
            let response = await getAvailableDispatchersWS();
            if (response['result']) {
                dispatcher_select_items.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
        }

        const loadAvailabeChannels = async () => {
            let response = await getAvailableChannelsWS();
            if (response['result']) {
                channel_select_items.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
        }


        /*
        *
        * Rellena los datos del formulario
        * con los datos actuales del typeform
        * */
        const loadTypeform = () => {
            const {id_formulario, nombre, id_curso, id_campanya, id_remitente, enviar_cupon_a_zoho, id_canal} = props.typeform
            selected_course.value = course_select_items.value.find(item => item.id_curso == id_curso);
            selected_campaign.value = campaign_select_items.value.find(item => item.id_campanya == id_campanya);
            selected_rem.value = dispatcher_select_items.value.find(item => item.id_remitente == id_remitente);
            selected_sender_zoho.value = senders_zoho_select_items.value.find(item => item.nombre == enviar_cupon_a_zoho);
            selected_channel.value = channel_select_items.value.find(item => item.id_canal == id_canal);

            form_data.value = {id_formulario, name: nombre}
        }

        const sendForm = async () => {
            let response;
            if (validateForm()) {
                disabled_general.value = true

                form_data.value['course'] = selected_course.value.id_curso
                form_data.value['remitente'] = selected_rem.value.id_remitente
                form_data.value['campanya'] = selected_campaign.value.id_campanya
                form_data.value['enviar_zoho'] = selected_sender_zoho.value.nombre
                form_data.value['id_canal'] = selected_channel.value.id_canal
                if (props.mode === 'edit'){
                    form_data.value['typeform_id'] = props.typeform.id_formulario
                }

                if (props.mode === 'create'){
                    response = await createTypeformWS(form_data.value)
                }else if(props.mode === 'edit'){
                    response = await editTypeformWS(form_data.value)
                }

                if (response['result']) {
                    props.refreshTable()
                    props.showDialog()
                    showNotification('success', 'Se ha guardado correctamente')
                } else {
                    showNotification('error', response['errors'])
                }

                disabled_general.value = false
            }

        }

        const validateForm = () => {
            let correct = true
            const {name, id_canal} = form_data.value
            errors.value = {}

            if (!variableIsDefined(name)) {
                correct = false
                errors.value.name = true
            }

            if (!variableIsDefined(selected_channel.value)) {
                correct = false
                errors.value.id_canal = true
            }

            if (!variableIsDefined(selected_course.value)) {
                correct = false
                errors.value.course = true
            }

            if (!variableIsDefined(selected_rem.value)) {
                correct = false
                errors.value.remitente = true
            }

            if (!variableIsDefined(selected_campaign.value)) {
                correct = false
                errors.value.campaign = true
            }

            if (!variableIsDefined(selected_sender_zoho.value)) {
                correct = false
                errors.value.senders_zoho = true
            }

            return correct
        }

        return {
            sendForm,

            loading,
            campaign_select_items,
            course_select_items,
            senders_zoho_select_items,
            dispatcher_select_items,
            channel_select_items,
            disabled_general,
            errors,
            form_data,
            selected_campaign,
            selected_course,
            selected_rem,
            selected_sender_zoho,
            selected_channel
        }
    }
}
</script>

<style lang="scss">
@media (max-width: 500px) {
    .v-dialog .v-overlay__content > .v-card {
        width: 100% !important;
    }
}
</style>