<template>
    <v-container class="edit-teacher-container">
        <v-row>
            <v-col cols="12" sm="7" md="8" lg="9" xl="10">
                <span class="main-title-page">Edición de docente</span>
            </v-col>
        </v-row>
        <template v-if="loading">
            <Loading/>
        </template>
        <template v-else-if="show_teacher">
            <v-row>
                <v-col cols="12"
                       :sm="data_inno.formacion_complementaria.length === 0 && data_inno.formacion_reglada.length === 0 ? 12 : 7"
                       class="teacher-form">
                    <v-row>
                        <v-col cols="12" v-if="data_inno.externo">
                            <p>
                                Este usuario es externo, podrá modificar sus datos personales en la pantalla de docentes externos
                            </p>
                        </v-col>
                        <!--IMAGEN DOCENTE-->
                        <v-col cols="12">
                            <p class="input-title">
                                Imagen de perfil *
                            </p>
                            <!--                            SE QUEDA COMENTADO POR SI EL NAVEGADOR NO VUELVE A ACTUALIZAR LA IMAGEN-->
                            <v-img v-if="selected_image" class="preview" :src="viewFile()"></v-img>

                            <!--                            <v-img v-if="selected_image" class="preview" :src="selected_image"></v-img>-->
                            <v-file-input label="Arrastra aquí tus archivos o pulsa para subir imagen"
                                          v-model="file"
                                          variant="underlined"
                                          :loading="uploading"
                                          :rules="file_rules"
                                          @change="previewImage()"
                                          show-size
                                          accept="image/webp"
                                          prepend-icon="mdi-camera"
                                          type="file"
                                          chips=""
                                          class="text-field-primary field-with-label">
                            </v-file-input>
                        </v-col>

                        <v-col cols="12" lg="4">
                            <p class="input-title">
                                Identificador innotutor *
                            </p>
                            <v-text-field
                                class="text-field-secondary"
                                variant="outlined"
                                bg-color="white"
                                color="transparent"
                                hide-details
                                disabled
                            >
                                {{ data_inno.usuario }}
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" lg="8">
                            <p class="input-title">
                                Nombre y apellidos *
                            </p>
                            <v-text-field
                                class="text-field-secondary"
                                variant="outlined"
                                bg-color="white"
                                color="transparent"
                                hide-details
                                disabled
                            >
                                {{ data_inno.nombre_completo }}
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="7" lg="8">
                            <span class="input-title"> Linkedin</span>
                            <v-checkbox class="check-active" label="Mostrar linkedin"
                                        v-model="data_personalization.show_linkedin"
                                        hide-details>
                            </v-checkbox>

                            <p style="word-break: break-word" v-if="data_inno.linkedin !== null">{{
                                    data_inno.linkedin
                                }}</p>
                            <p v-else class="pending-data-text"><i>Sin especificar</i></p>

                        </v-col>
                        <v-col cols="12" md="5" lg="4">
                            <span class="input-title">Estado docente</span>
                            <v-checkbox-btn
                                v-model="data_personalization.activo"
                                label="Activado"
                                class="check-active"
                                :true-value="1"
                                :false-value="0"
                            ></v-checkbox-btn>
                        </v-col>
                        <v-col cols="12">
                            <p class="input-title">
                                Titulación *
                            </p>
                            <v-textarea
                                v-model.trim="data_personalization.titulacion"
                                class="text-area-primary input-error "
                                :error="form_errors.titulation"
                                placeholder="Titulación del docente."
                                auto-grow
                                rows="2"
                                hide-details
                                bg-color="white"
                                color="transparent"
                            >
                            </v-textarea>
                            <span v-if="form_errors.titulation" class="input-error msg-error">
                            Debe añadir una titulación para el docente.
                    </span>
                        </v-col>

                        <v-col
                            cols="12">
                            <p class="input-title">
                                Experiencia
                            </p>
                            <v-textarea
                                v-model.trim="data_personalization.experiencia"
                                class="text-area-primary input-error "
                                placeholder="Experiencia del docente"
                                auto-grow
                                rows="2"
                                hide-details
                                bg-color="white"
                                color="transparent"
                            >
                            </v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <span class=" box-select-area input-title">Añadir área formativa</span>
                            <span class="pending-data-text">Estos cambios se guardan automáticamente</span>
                        </v-col>
                        <v-col xs="12" lg="8" class="box-select-area">
                            <SelectTertiary
                                class="input-error select-area"
                                v-model="all_categories.id_categoria"
                                :items="all_categories"
                                :error="form_errors.area_selected"
                                placeholder="Selecciona el área formativa"
                                item-value="id_categoria"
                            >
                                <template v-slot:selection="{ props, item }">
                                    {{ `${item?.raw?.nombre}` }}
                                </template>
                                <template v-slot:item="{ props, item }">
                                    <v-list-item
                                        class="select-items"
                                        v-bind="props"
                                        :title="item?.raw?.nombre"
                                    >
                                    </v-list-item>

                                </template>
                            </SelectTertiary>
                            <span v-if="form_errors.area_selected" class="input-error msg-error">
                        {{ error_message_select }}
                    </span>
                        </v-col>
                        <v-col cols="12" lg="3"
                               class="box-btn-area"
                        >
                            <v-btn
                                class="btn button-primary w-100 mt-sm-3"
                                @click="setCategoryToTeacher"
                                :loading="loading_category"
                            >Añadir área
                            </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <span class="input-title">Áreas actuales del docente</span>
                            <span v-if="categories_teacher.length===0" class="pending-data-text">Este docente no tiene ningún área asignada</span>
                            <span v-else class="pending-data-text">Seleccione área prioritaria</span>
                        </v-col>

                        <v-col cols="12">
                            <!--Etiquetas con las áreas del docente-->
                            <v-radio-group hide-details
                                           v-model="primary_area"
                            >
                                <v-row>
                                    <v-col cols="12"
                                           class="boxes-areas" :class="{error_boxs_areas :form_errors.priority_area}"
                                           v-for="area_present in categories_teacher"
                                           :key="area_present.id_categoria"
                                    >
                                        <v-card class="box_area_teacher">
                                            <v-card-text class="text-area"
                                                         @click="saveCategoryArea(area_present.id_categoria)">
                                                <v-radio
                                                    :value="area_present.id_categoria"
                                                >
                                                    <template v-slot:label>
                                                        <span class="area-label">{{ area_present.nombre }}</span>
                                                    </template>
                                                </v-radio>
                                            </v-card-text>
                                            <div class="align-self-center p-2">
                                                <v-icon class="icons-close" v-if="categories_teacher.length>1"
                                                        @click="selectTeacherCategory('delete', area_present.id_categoria)">
                                                    mdi-close
                                                </v-icon>
                                                <v-progress-circular
                                                    class="icons-loading"
                                                    indeterminate
                                                    :size="20"
                                                    :width="2"
                                                    color="blue"
                                                ></v-progress-circular>
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12" sm="8" lg="6">
                            <span v-if="form_errors.priority_area" class="input-error msg-error">Debe seleccionar un área como prioritaria</span>
                        </v-col>

                        <v-col cols="12">
                            <span class="input-title">Cursos destacados del docente</span>
                            <v-btn
                                class="btn button-bg-blue mt-3"
                                @click="selectTeacherCategory('add_course')"
                            >
                                Añadir cursos
                            </v-btn>
                            <span v-if="clone_courses.length===0"
                                  class="pending-data-text mt-3">Sin cursos asignados</span>
                            <v-row class="mt-2">
                                <v-col v-for="course in array_to_show_courses" :key="course.id_url" cols="12" xs="12"
                                       sm="auto"
                                       class="boxes-areas">
                                    <v-card class="box_area_teacher">
                                        <v-card-text class="text-area align-self-center">
                                            {{ course.url }}
                                        </v-card-text>
                                        <div class="align-self-center p-2">
                                            <v-icon
                                                class="icons-close"
                                                @click="deselectCourse(course, true)"
                                            >
                                                mdi-close
                                            </v-icon>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" class="text-center" v-if="Object.keys(form_errors).length > 0">
                            <p v-if="form_errors.general" class="general-error">
                                Faltan campos obligatorios.
                            </p>
                        </v-col>
                        <v-col class="text-center my-2">
                            <v-btn
                                class="btn button-bg-blue"
                                @click="sendForm"
                                :loading="loading_save_form"
                            >
                                Guardar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <div
                class="absolute-position"
                v-if="data_inno.formacion_complementaria.length > 0 || data_inno.formacion_reglada.length > 0"
            >
                <p class="input-title">
                    Datos en innotutor
                </p>
                <v-row class="teacher-formation personalize-scroll">
                    <v-col class="col-title-formation"><span class="title-formation">Formación Reglada</span></v-col>
                    <v-col cols="12" v-for="formacion in data_inno.formacion_reglada">
                        <div class="data-inno-container personalize-scroll">
                            <ul class="mt-2">
                                <li v-for="entry in Object.entries(formacion)">
                                        <span>
                                            <strong>{{
                                                    entry[0].toUpperCase() === 'TIPO_CENTRO' ? 'TIPO DE CENTRO' : entry[0].toUpperCase()
                                                }}:</strong>
                                        </span>
                                    <p>{{ entry[1] }}</p>
                                </li>
                            </ul>
                        </div>
                    </v-col>
                    <v-col class="col-title-formation"><span class="title-formation">Formación Complementaria</span>
                    </v-col>

                    <v-col cols="12" v-for="formacion in data_inno.formacion_complementaria">
                        <div class="data-inno-container personalize-scroll">
                            <ul class="mt-2">
                                <li v-for="entry in Object.entries(formacion)">
                                        <span> <strong>{{
                                                entry[0].toUpperCase() === 'TIPO_CENTRO' ? 'TIPO DE CENTRO' : entry[0].toUpperCase()
                                            }}:</strong></span>
                                    <p>{{ entry[1] }}</p>
                                </li>
                            </ul>
                        </div>
                    </v-col>
                </v-row>

            </div>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12">
                    <span>El nombre de usuario no es válido o no existe como docente.</span>
                </v-col>
            </v-row>
        </template>
    </v-container>

    <v-dialog v-model="display_dialog_courses">
        <TeachersCoursesModal
            :display-dialog="displayDialogCourses"
            :teacher_id="data_personalization.id_tecnico"
            :teacher_areas="categories_teacher"
            :array_to_show_courses="array_to_show_courses"
            :teacher_courses="teacher_courses"
            :save-selected-courses="getSelectedCourses"
            :all_courses="all_courses"
        ></TeachersCoursesModal>
    </v-dialog>

</template>

<script>

import {onBeforeMount, ref, watch} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {
    getAllTeachersWS,
    getAreasByTeacherWS, getTeacherAvailableCoursesWS,
    removeAreaFromTeacherWS,
    setAreaToTeacherWS, updateTeacherWS,
} from "@/utils/teachers";
import Loading from "@/components/Generic/Loading.vue";
import {getCategoriesWS} from "@/utils/web_categorization";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import TeachersCoursesModal from "@/components/Teachers/TeachersCoursesModal.vue";
import {uploadImgWS} from "@/utils/aiseo_petition";
import SelectTertiary from "@/components/Generic/SelectTertiary.vue";

export default {
    name: "TeachersEditView",
    methods: {variableIsDefined},
    components: {SelectTertiary, TeachersCoursesModal, Loading},
    props: {
        username: {
            type: String,
            required: true,
        }
    },
    setup(props) {
        const uploading = ref(false)
        const loading = ref(false)
        let loading_category = ref(false);
        let loading_save_form = ref(false);

        /* Variables relacionadas con los datos del docente */
        const data_inno = ref()
        const data_personalization = ref()
        const show_teacher = ref(false)
        const teacher = ref()
        const teacher_courses = ref([])

        /* Variables relacionadas con la imagen de perfil del docente */
        const file = ref([])
        const file_rules = [files => !files || !files.some(file => file.size > 2e6) || 'ERROR. La imagen debe pesar menos de 2 MB']
        const selected_image = ref()
        const teacher_image = ref()
        let view_img = false

        /* Variables relacionadas con las categorías y cursos*/
        const all_categories = ref([])
        const categories_teacher = ref([])
        const display_dialog_courses = ref(false)
        const selected_category_id = ref()
        const primary_area = ref()
        let clicked_area = ref(null)
        let params_data_teacher
        let clone_data_teacher = ref()
        let array_to_show_courses = ref([])
        let all_courses = ref({})
        let courses_to_return = []
        let clone_courses = ref({})
        let priority_area = null

        /* Variables relacionadas con la gestión de errores */

        const error_message_select = ref('')
        let form_errors = ref({
            'general': false,
            'area_selected': false,
            'priority_area': false,
            'titulation': false,
        })


        onBeforeMount(async () => {
            loading.value = true
            initSweetAlert()
            await loadTeacher()
            await getCategories()
            clone_data_teacher.value = await saveTeacherData(clone_data_teacher.value)
            params_data_teacher = await saveTeacherData(params_data_teacher)
            loading.value = false
        })

        async function saveTeacherData(data_teacher) {
            let array_cursos = []
            array_to_show_courses.value.forEach((course) => {
                array_cursos.push(course.id_url)
            })
            data_teacher = {
                activo: data_personalization.value.activo,
                experiencia: data_personalization.value.experiencia,
                id_docente: data_personalization.value.id_tecnico,
                titulacion: data_personalization.value.titulacion,
                usuario: data_inno.value.usuario,
                show_linkedin: data_personalization.value.show_linkedin || false,
                /* PARAMETROS RELACIONADOS CON LAS ÁREAS Y LOS CURSOS*/
                area_prioritaria: primary_area.value,
                cursos_asignados: array_cursos.sort((a, b) => a - b)
            }
            return data_teacher
        }


        /*
        *
        * SI HAY UN NOMBRE DE USUARIO DEFINIDO
        * CARGA TODOS LOS DATOS NECESARIOS
        * DEL DOCENTE
        * */
        const loadTeacher = async () => {
            let courses_to_search
            if (variableIsDefined(props.username)) {
                show_teacher.value = await getSpecificTeacher()
                if (show_teacher.value) {
                    data_personalization.value = teacher.value.datos_personalizaciones[0]
                    data_inno.value = teacher.value.datos_innocualificados
                    selected_image.value = data_personalization.value.imagen
                    await getAreasByTeacher()
                    courses_to_search = variableIsDefined(data_personalization.value.cursos_prioritarios)
                        ? data_personalization.value.cursos_prioritarios.split(',')
                        : [];
                    all_courses.value = await loadAvailableCourses()
                    if (variableIsDefined(all_courses.value)) {
                        teacher_courses.value = findCourses(all_courses.value.specific, all_courses.value.category, courses_to_search)
                        /*Genera el objeto con la clave*/
                        teacher_courses.value.forEach((course) => {
                            if (clone_courses.value.hasOwnProperty(course.id_category)) {
                                clone_courses.value[course.id_category].push({
                                    id_url: course.id_url,
                                    url: course.url,
                                    id_category: course.id_category
                                });
                            } else {
                                clone_courses.value[course.id_category] = [{
                                    id_url: course.id_url,
                                    url: course.url,
                                    id_category: course.id_category
                                }];
                            }
                            if (!array_to_show_courses.value.some((course_to_show) => course_to_show.id_url === course.id_url)) {
                                array_to_show_courses.value.push(course)
                            }
                        });
                    }
                }
            }
        }

        const findCourses = (specif, by_category, ids_to_search) => {
            courses_to_return = []
            Object.keys(by_category).forEach((category) => {
                ids_to_search.forEach(
                    (id) => {
                        let course = by_category[category].find(
                            course => course.id_url.toString() === id.toString()
                        )
                        if (variableIsDefined(course)) {
                            courses_to_return.push({...course, id_category: `${category}`})
                        }
                    }
                )
            })
            ids_to_search.forEach(
                (id) => {
                    let course = specif.find(
                        course => course.id_url.toString() === id.toString()
                    )
                    if (variableIsDefined(course)) {
                        courses_to_return.push({...course, id_category: `specific`})
                    }
                }
            )
            return courses_to_return
        }


        /* Carga los cursos específicos y todos los cursos por categorías pero solo asigna los específicos*/
        const loadAvailableCourses = async () => {
            try {
                const {result, data, errors} = await getTeacherAvailableCoursesWS(data_personalization.value.id_tecnico)
                if (result) {
                    return data
                } else {
                    showNotification('error', errors)
                }
            } catch (e) {
                showNotification('error', 'Error al hacer la petición')
            }
        }

        /*OBTIENE EL DOCENTE A PARTIR DE LOS DATOS DE LA URL*/
        const getSpecificTeacher = async () => {
            try {
                const {result, data, errors} = await getAllTeachersWS(1, 1, [props.username]);
                if (result) {
                    teacher.value = Object.values(data)[0]
                } else {
                    showNotification('error', errors)
                }
                return variableIsDefined(teacher.value)
            } catch (e) {
                showNotification('error', 'Error al hacer la petición')
            }

        }

        /*OBTIENE TODAS LAS CATEGORIÁS Y RELLENA EL AUTOCOMPLETE*/
        const getCategories = async () => {
            try {
                const {result, data, errors} = await getCategoriesWS();
                (result && data) ? all_categories.value = data : showNotification('error', errors)
            } catch (e) {
                showNotification('error', 'Error al hacer la petición')
            }
        }

        /*AREAS ACTUALES DEL DOCENTE*/
        async function getAreasByTeacher() {
            try {
                const {result, data, errors} = await getAreasByTeacherWS(data_personalization.value.id_tecnico);
                if (result && data) {
                    categories_teacher.value = data
                    categories_teacher.value.forEach(category => {
                        if (variableIsDefined(category.pivot.es_prioritaria)) {
                            primary_area.value = category.id_categoria
                            priority_area = category.id_categoria
                        }
                    })
                } else {
                    showNotification('error', errors)
                }
            } catch (e) {
                showNotification('error', 'Error al hacer la petición')
            }
        }


        /*ASIGNA UN ÁREA A UN DOCENTE*/
        const setCategoryToTeacher = async () => {
            form_errors.value.area_selected = false
            loading_category.value = true;

            if (!variableIsDefined(all_categories.value.id_categoria)) {
                form_errors.value.area_selected = true
                error_message_select.value = 'Tienes que seleccionar un área formativa'

            } else {
                const exist_category = categories_teacher.value.some((category) => category.id_categoria === all_categories.value.id_categoria)
                if (exist_category) {
                    form_errors.value.area_selected = true
                    error_message_select.value = "El área seleccionada ya está incluida"
                } else {
                    if (variableIsDefined(clicked_area.value) && clicked_area.value !== priority_area) {
                        await fireSweetAlert('Añadir área', await addCategoryToTeacher, 'Se ha detectado una modificación en el área prioritaria. Guarde los cambios para evitar pérdidas.')
                    } else {
                        await addCategoryToTeacher()
                    }
                }
            }
            loading_category.value = false;
        }

        async function addCategoryToTeacher() {
            const {
                result,
                errors
            } = await setAreaToTeacherWS(data_personalization.value.id_tecnico, all_categories.value.id_categoria)
            if (result) {
                all_categories.value.id_categoria = null
                await getAreasByTeacher()
                params_data_teacher = await saveTeacherData(params_data_teacher)
                all_courses.value = await loadAvailableCourses()
                form_errors.value.general = false
                showNotification('success', "Área añadida")
            } else {
                showNotification('error', errors)
            }
        }


        /*MUESTRA EL MODAL PARA ELIMINAR AL DOCENTE DE UNA CATEGORÍA
        * O PARA ASIGANARLE CURSOS DE LA CATEGORÍA SELECCIONADA*/
        const selectTeacherCategory = (action, id_category = null) => {
            selected_category_id.value = id_category
            switch (action) {
                case 'add_course':
                    displayDialogCourses()
                    break;
                case 'delete':
                    displayModalDelete()
                    break;
            }
        }

        const displayDialogCourses = () => {
            display_dialog_courses.value = !display_dialog_courses.value
        }

        const displayModalDelete = () => {
            fireSweetAlert('Quitar área a docente', removeAreaFromTeacher, 'Esta acción borrará también los cursos de dicha área que estén asignados como destacados al docente. No podrás deshacer esta acción.')
        }

        const removeAreaFromTeacher = async () => {
            for (const clave in courses_to_return) {
                if (courses_to_return.hasOwnProperty(clave) && courses_to_return[clave] === selected_category_id.value) {
                    delete courses_to_return[clave];
                }
            }
            teacher_courses.value = courses_to_return
            const {
                result,
                data,
                errors
            } = await removeAreaFromTeacherWS(data_personalization.value.id_tecnico, selected_category_id.value);
            if (result && data) {
                if (variableIsDefined(clone_courses.value[selected_category_id.value])) {
                    while (variableIsDefined(clone_courses.value[selected_category_id.value]) && clone_courses.value[selected_category_id.value].length > 0) {
                        deselectCourse(clone_courses.value[selected_category_id.value][0], false)
                    }
                }
                await getAreasByTeacher()
                all_courses.value = await loadAvailableCourses()
                params_data_teacher = await saveTeacherData(params_data_teacher)
            } else {
                showNotification('error', errors)
            }
        }

        function viewFile() {
            return view_img
                ? `${selected_image.value}`
                : selected_image.value + '?' + new Date()
        }


        /*CAMBIA LA IMAGEN DE PERFIL MOSTRADA
        * NO ACTUALIZA EN LA BBDD*/
        const previewImage = () => {
            if (Object.keys(file.value).length > 0) {
                view_img = true
                try {
                    let reader = new FileReader();
                    const new_file = file.value;
                    reader.readAsDataURL(new_file[0]);

                    reader.onload = e => {
                        selected_image.value = e.target.result;
                    }
                } catch (e) {
                    showNotification('error', 'Error al hacer la petición')
                }
            }
        }

        async function uploadImg() {
            uploading.value = true;
            if (variableIsDefined(file.value) && file.value.length > 0) {
                try {
                    const response = await uploadImgWS(file.value, 'docente', 'imagen', data_personalization.value.id_tecnico)
                    if (response.data.result) {
                        teacher_image.value = response.data.data['fileID'].toString();
                        view_img = false
                        showNotification('success', 'Imagen subida correctamente')

                    }
                } catch (e) {
                    showNotification('error', 'Error al hacer la petición')
                }
            }
            uploading.value = false
        }


        /*ENVÍA LOS NUEVOS DATOS DEL DOCENTE PARA ACTUALIZARLO EN LA BBDD*/
        const sendForm = async () => {
            loading_save_form.value = true
            if (await validateFormData()) {
                params_data_teacher = await saveTeacherData(params_data_teacher)
                if (file.value.length > 0) {
                    await uploadImg();
                    variableIsDefined(teacher_image.value)
                        ? params_data_teacher.imagen_final = teacher_image.value
                        : params_data_teacher.imagen_final = [];
                } else {
                    params_data_teacher.imagen_final = [];
                }
                if (!_.isEqual(params_data_teacher, clone_data_teacher.value)) {
                    try {
                        const response = await updateTeacherWS(params_data_teacher);
                        if (response['result'] && response['data']) {
                            priority_area = params_data_teacher.area_prioritaria
                            file.value = []
                            view_img = !!(/webp(.+)$/.exec(selected_image.value) && /webp(.+)$/.exec(selected_image.value)[1]);
                            clone_data_teacher.value = _.cloneDeep(params_data_teacher);
                            showNotification('success', '¡Docente actualizado correctamente!')
                        } else {
                            if (response['errors'][0]['title'] === 'No se ha realizado ninguna operación al no encontrarse cambios en el docente') {
                                showNotification('info', 'No se han detectado cambios nuevos')
                            } else {
                                showNotification('error', response['errors'])
                            }
                        }

                    } catch (e) {
                        showNotification('error', 'Error al hacer la petición')
                    }
                } else {
                    showNotification('info', 'No se han detectado cambios nuevos')
                }
            }
            loading_save_form.value = false
        }

        const validateFormData = async () => {
            let correct = true
            form_errors.value = {
                'general': false,
                'area_selected': false,
                'priority_area': false,
                'titulation': false
            }
            if (!variableIsDefined(data_personalization.value.titulacion)) {
                form_errors.value.general = true
                form_errors.value.titulation = true
                correct = false
            }
            if (categories_teacher.value.length === 0) {
                form_errors.value.general = true
                form_errors.value.area_selected = true
                error_message_select.value = 'Debe tener un área seleccionada'
                correct = false
            } else {
                if (!variableIsDefined(params_data_teacher.area_prioritaria) && !variableIsDefined(clicked_area.value)) {
                    form_errors.value.general = true
                    form_errors.value.priority_area = true
                    correct = false
                }
            }
            return correct
        }

        /*Recibe los cursos seleccionados en el modal, 5 como mucho*/
        const getSelectedCourses = async (courses) => {
            let ids_to_check = []

            clone_courses.value = courses.reduce((acc, course) => {
                const key = course.id_category;
                ids_to_check.push(course.id_url)
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push({
                    id_url: course.id_url,
                    url: course.url,
                    id_category: course.id_category
                });
                return acc;
            }, {});

            let courses_specific = await loadAvailableCourses()
            if (!variableIsDefined(clone_courses.value['specific'])) {
                clone_courses.value['specific'] = []
            }


            ids_to_check.forEach(
                (id) => {
                    let course = courses_specific.specific.find(
                        course => course.id_url.toString() === id.toString()
                    )
                    if (variableIsDefined(course) && !clone_courses.value['specific']
                        .some(course_specific => course_specific.id_url.toString() === course.id_url.toString())) {
                        clone_courses.value['specific'].push({...course, id_category: 'specific'})
                    }
                }
            )
            array_to_show_courses.value = courses
        }

        const deselectCourse = ({id_url, id_category}, delete_especific) => {
            let id_to_delete = -1
            if (variableIsDefined(array_to_show_courses.value)) {
                id_to_delete = array_to_show_courses.value.findIndex(course => course.id_url === id_url)
            }
            let course_area = -1
            if (variableIsDefined(clone_courses.value[id_category])) {
                course_area = clone_courses.value[id_category].findIndex(course => course.id_url === id_url)
            }
            let course_specific = -1
            if (variableIsDefined(clone_courses.value['specific'])) {
                course_specific = clone_courses.value['specific'].findIndex(course => course.id_url === id_url)
            }

            if (course_area > -1) {
                clone_courses.value[id_category].splice(course_area, 1)
            }
            if (delete_especific) {
                if (course_specific > -1) {
                    clone_courses.value['specific'].splice(course_specific, 1)
                }
            }
            if (course_specific === -1 || delete_especific) {
                array_to_show_courses.value.splice(id_to_delete, 1)
            }
        }

        async function saveCategoryArea(area_selected) {
            clicked_area.value = area_selected
        }

        return {
            deselectCourse,
            displayDialogCourses,
            displayModalDelete,
            getSelectedCourses,
            previewImage,
            selectTeacherCategory,
            sendForm,
            setCategoryToTeacher,
            validateFormData,
            saveCategoryArea,
            viewFile,

            all_categories,
            categories_teacher,
            data_inno,
            data_personalization,
            display_dialog_courses,

            error_message_select,
            file,
            file_rules,
            form_errors,
            loading,
            primary_area,
            selected_category_id,
            selected_image,
            show_teacher,
            teacher,
            teacher_courses,
            uploading,
            clone_courses,
            clicked_area,
            loading_category,
            array_to_show_courses,
            all_courses,
            loading_save_form,
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/views/teachers.scss";
</style>