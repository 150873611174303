<template>
    <v-expansion-panels>
        <v-expansion-panel class="expansion-personalized">
            <v-expansion-panel-title>Opciones de filtrado</v-expansion-panel-title>
            <v-expansion-panel-text>
                <v-row>
                    <!--FILTRAR POR CURSO-->
                    <v-col cols="12" md="6">
                        <v-text-field
                            class="text-field-secondary"
                            placeholder="Buscar por nombre del curso"
                            single-line
                            bg-color="white"
                            color="transparent"
                            hide-details
                            :disabled="form_filters.generic_comment === 'generic'"
                            v-model.trim="form_filters.name_course"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <SelectTertiary
                            placeholder="Buscar por tipo de comentario"
                            :items="all_type_comment"
                            item-title="name"
                            item-value="value_type"
                            v-model="form_filters.generic_comment"
                        ></SelectTertiary>
                    </v-col>
                    <!--FILTRAR POR ESTADO-->
                    <v-col cols="12" sm="6">
                        <SelectTertiary
                            placeholder="Buscar por estado"
                            :items="all_status"
                            item-title="name"
                            item-value="id_status"
                            v-model="form_filters.state"
                        ></SelectTertiary>
                    </v-col>

                    <!--FILTRAR POR PUNTUACIÓN-->
                    <v-col cols="12" md="6">
                        <v-card class="card-score-filter align-self-center">
                            <v-range-slider
                                label="Puntuación"
                                class="slider-modal personalize-slider my-5 align-self-center"
                                v-model.trim="half_score"
                                step="1"
                                :model-value="half_score"
                                thumb-label="always"
                                track-size="3"
                                strict
                                min=0
                                max=10
                                hide-details
                            ></v-range-slider>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <!--FILTRO FECHA CREACIÓN-->
                    <v-col cols="12" md="6">
                        <v-card class="modify-card">
                            <v-card-text>
                                <v-row no-gutters class="mb-2">
                                    <v-col cols="12">
                                        <span class="title-data-filter">Fecha de creación</span>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters>
                                    <v-col cols="12" sm="6">
                                        <span class="me-2">Inicio: </span>
                                        <InputCalendar
                                            :compare_dates="true"
                                            :item_date="filter_create_date.initial"
                                            ref="date_base_create_start"
                                        ></InputCalendar>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <span class="me-2">Final: </span>
                                        <InputCalendar
                                            :compare_dates="true"
                                            :item_date="filter_create_date.final"
                                            ref="date_base_create_end"
                                        ></InputCalendar>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!--FILTRO FECHA MODIFICACIÓN-->
                    <v-col cols="12" md="6">
                        <v-card class="modify-card">
                            <v-card-text>
                                <v-row no-gutters class="mb-2">
                                    <v-col cols="12">
                                        <span class="title-data-filter">Fecha de modificación</span>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters>
                                    <v-col cols="12" sm="6">
                                        <span class="me-2">Inicio: </span>
                                        <InputCalendar
                                            :compare_dates="true"
                                            :item_date="filter_modify_date.initial"
                                            ref="date_base_modify_start"
                                        ></InputCalendar>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <span class="me-2">Final: </span>
                                        <InputCalendar
                                            :compare_dates="true"
                                            :item_date="filter_modify_date.final"
                                            ref="date_base_modify_end"
                                        ></InputCalendar>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <!--   BOTÓN DE FILTRAR Y DE LIMPIAR FILTROS   -->
                <v-row justify="end" no-gutters class="pt-2 sm:pt-0">
                    <v-col cols="12" class="text-end align-self-center">

                        <v-btn class="btn button-bg-blue me-4" @click="validateFilters"
                               :loading="loading_get_filters">Filtrar
                        </v-btn>
                        <img :src="require('@/assets/icons/drop-filter.svg')" alt="Eliminar filtros"
                             class="delete-filter" @click="deleteFilter">

                    </v-col>
                </v-row>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import {
    checkDefinedObject,
    variableIsDefined
} from "@/utils/aux_functions";
import {ref} from "vue";
import InputCalendar from "@/components/Generic/InputCalendar.vue";
import {validateDate} from "@/utils/input_date";
import SelectTertiary from "@/components/Generic/SelectTertiary.vue";

export default {
    name: "CommentsFilters",
    methods: {variableIsDefined},
    components: {SelectTertiary, InputCalendar},
    props: {
        getFilters: Function,
        deleteFilters: Function,
        getAllComments: Function,
        all_status: Array,
    },
    setup(props) {
        let loading_get_filters = ref(false)
        let filter_create_date = ref({})
        let filter_modify_date = ref({})
        let form_filters = ref({})
        let filters_search = {}
        let half_score = ref([0, 0]);
        let all_type_comment = ref([
            {'name': 'Todos los comentarios', 'value_type': 'all'},
            {'name': 'Comentarios genéricos', 'value_type': 'generic'},
            {'name': 'Comentarios no genéricos', 'value_type': 'not_generic'},
        ])
        let date_base_create_start = ref(null)
        let date_base_create_end = ref(null)
        let date_base_modify_start = ref(null)
        let date_base_modify_end = ref(null)
        let response_date_create = []
        let response_date_modify = []


        async function validateFilters() {
            filters_search = {}

            /*FILTRO ESTADO*/
            if (variableIsDefined(form_filters.value.state)) {
                filters_search.status = [form_filters.value.state]
            }

            /*FILTRO PUNTUACIÓN*/
            if (variableIsDefined(half_score.value)) {
                if (half_score.value[0] === 0 && half_score.value[1] === 0) {
                    if (variableIsDefined(filters_search.score)) {
                        delete filters_search.score;
                    }
                } else if (half_score.value[0] === 0 && half_score.value[1] !== 0
                    || half_score.value[0] !== 0 && half_score.value[1] !== 0) {
                    filters_search.score = {
                        "initial": half_score.value[0],
                        "final": half_score.value[1]
                    }
                }
            }

            /*FECHA SELECCIONADA DEL COMPONENTE DATEBASE*/
            response_date_create = validateDate(date_base_create_start.value, date_base_create_end.value)
            if (response_date_create[0] && variableIsDefined(response_date_create[1])) {
                filters_search['create_date'] = response_date_create[1]
            }

            response_date_modify = validateDate(date_base_modify_start.value, date_base_modify_end.value)
            if (response_date_modify[0] && variableIsDefined(response_date_modify[1])) {
                filters_search['update_date'] = response_date_modify[1]
            }

            /*FILTROS SELECCIONADOS*/
            if ((response_date_create[0] && variableIsDefined(response_date_create[1]))
                || (response_date_modify[0] && variableIsDefined(response_date_modify[1]))
                || checkDefinedObject(filters_search)
                || variableIsDefined(form_filters.value.name_course)
                || variableIsDefined(form_filters.value.generic_comment)) {
                loading_get_filters.value = true
                await props.getFilters(filters_search, form_filters.value.name_course, form_filters.value.generic_comment)
                loading_get_filters.value = false
            }

        }


        async function deleteFilter() {
            if (
                checkDefinedObject(filters_search)
                || checkDefinedObject(form_filters.value)
                || variableIsDefined(date_base_create_start.value.input_date)
                || variableIsDefined(date_base_create_end.value.input_date)
                || variableIsDefined(date_base_modify_start.value.input_date)
                || variableIsDefined(date_base_modify_end.value.input_date)
            ) {
                form_filters.value = {}
                filters_search = {}
                filter_create_date.value = {}
                filter_modify_date.value = {}
                half_score.value = [0, 0]
                date_base_create_start.value.deleteDate()
                date_base_create_end.value.deleteDate()
                date_base_modify_start.value.deleteDate()
                date_base_modify_end.value.deleteDate()
                await props.deleteFilters()
            }
        }


        return {
            validateFilters,
            deleteFilter,

            loading_get_filters,
            filter_create_date,
            filter_modify_date,
            all_type_comment,
            form_filters,

            date_base_create_start,
            date_base_create_end,
            date_base_modify_start,
            date_base_modify_end,
            half_score,

        }
    }
}
</script>


<style lang="scss">
@import "@/styles/views/list-comments.scss";
</style>