<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12" sm="9" lg="10">
        <span class="main-title-page">
            <img :src="require('@/assets/icons/navbar/link.svg')" alt="icono de lista urls" width="28" height="28">
            Lista URLs
        </span>
      </v-col>
      <v-col cols="12" sm="3" lg="2" class="text-end"
             v-if="!pending_language && search_url_type==='Blogs' && !general_loading">
        <v-btn class="btn button-bg-blue" @click="addURL()">
          Añadir URL
        </v-btn>
      </v-col>

    </v-row>
    <template v-if="general_loading">
      <Loading/>
    </template>
    <template v-else>
      <template v-if="!pending_language">
        <v-row>
          <v-col>
            <FilterUrl
                :filter_search="filter_search"
                :url_type="url_type"
                :exportData="exportData"
                :deleteFilter="deleteFilter"
                :disabled_export="disabled_export"
                :getApplyFilter="getApplyFilter"
            ></FilterUrl>
          </v-col>
        </v-row>
        <v-row justify="end" class="mt-2">
          <v-col cols="12" md="9" class="text-end align-self-center">
            <v-btn
                class="btn button-primary mt-2 mt-sm-0 ms-sm-3"
                @click="setQueueExcelElement"
                :loading="create_redirection_loading"
                :disabled="items.length === 0"
            >Descargar Excel {{ search_url_type }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row justify="end" class="mt-2">

          <v-col cols="8" sm="5" md="4" lg="3">
            <v-select
                label="Tipo de url"
                class="select-primary field-with-label"
                v-model="search_url_type"
                @update:modelValue="searchUrlType(search_url_type)"
                :items="['Blogs', 'Cursos']"
                hide-details
                :disabled="loading"
                variant="underlined"
                no-data-text="No hay datos disponibles"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row v-if="can_update_course && (search_url_type !=='Blogs')">
          <!--todo - Si se activa esta función, hay que obligar al usuario a rellenar también el ID simo-->
          <v-col cols="12">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-title>Actualizar curso</v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-form @submit.prevent="searchCodeSincro">
                    <v-row align="center">
                      <v-col cols="6" xs="12">
                        <v-text-field
                            label="Código innotutor"
                            v-model="code_innotutor" variant="underlined"
                            class="text-field-primary field-with-label"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="6" xs="12">
                        <v-btn
                            type="submit"
                            class="btn button-primary"
                            :loading="loading_sincro"
                        >
                          Actualizar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <Vue3EasyDataTable
                table-class-name="primary-table courses-table-especific"
                :class="isTableBlog()"
                theme-color="#F4A649"
                buttons-pagination
                :headers="headers"
                :items="items"
                v-model:server-options="server_options"
                :server-items-length="server_items_length"
                :loading="loading"
                rowsPerPageOptions="10"
                rows-per-page-message="Elementos por página: "
                rows-of-page-separator-message="de"
                empty-message="No hay datos disponibles"
            >

              <template #item-edit_url="{url, active_edit_url, tipo_url}" v-if="getEditPermissions()">
                <img :src="require('@/assets/icons/pencil-edit.svg')" alt="" class="pencil-edit-url"
                     @click="editUrl(url, tipo_url)" v-if="active_edit_url">
              </template>

              <template #item-count_claustro="{count_claustro, url}">
                <div :class="[count_claustro===0 ? 'box-teachers-red' : 'box-teachers-orange']">
                  <div class="num-teachers">{{ count_claustro }}</div>
                  <v-btn class="btn-see-teacher-course"
                         @click=showTeachers(url)>
                    <img :src="require('@/assets/icons/eye.svg')"
                         :class="[count_claustro===0 ? 'icons-eye-red' : 'icons-eye-orange']"
                         alt="eye icon">
                  </v-btn>
                </div>
              </template>

              <template #item-fecha_creacion="{fecha_creacion}">
                {{ formatDate(fecha_creacion) }}
              </template>

              <template #item-published="{published}">
                <span v-if="published === 1">SÍ</span>
                <span v-else>NO</span>
              </template>

              <template #item-fecha_actualizacion="{fecha_actualizacion}">
                <span v-if="variableIsDefined(fecha_actualizacion)"> {{
                    formatDate(fecha_actualizacion)
                  }}
                </span>
                <span v-else>-</span>
              </template>
              <template #item-btn_actions="item">
                <v-menu class="table-blog">
                  <template v-slot:activator="{ props }">
                    <img :src="require('@/assets/icons/more-options.svg')"
                         class="btn button-bg-white icon-actions"
                         alt="Icono puntos"
                         v-bind="props">
                  </template>
                  <v-list>
                    <!--Ver/Editar rankings-->
                    <!--
                        <router-link
                                :to=ranking_url(item)
                                target="_blank"
                                class="link-unestyled"
                                v-if="url_type === 'courses'"
                            >
                                <v-list-item>
                                    <v-list-item-title>
                                        <span>Ranking</span>
                                    </v-list-item-title>
                                </v-list-item>
                            </router-link>-->

                    <!--Editar contenido SEO de url-->
                    <router-link
                        :to=editItem(item,true)
                        target="_blank"
                        class="link-unestyled"
                    >
                      <v-list-item>
                        <v-list-item-title>
                          <span>Edición SEO</span>
                        </v-list-item-title>
                      </v-list-item>
                    </router-link>

                    <router-link
                        :to=editItem(item,false)
                        target="_blank"
                        class="link-unestyled"
                    >
                      <v-list-item v-if="url_type === 'courses'">
                        <v-list-item-title>
                          <span>Edición curso</span>
                        </v-list-item-title>
                      </v-list-item>
                    </router-link>


                    <!--Ver/Editar titulaciones-->
                    <router-link :to=courseUrl(item.url)
                                 target="_blank"
                                 class="link-unestyled"
                                 v-if="url_type === 'courses'"
                    >
                      <v-list-item>
                        <v-list-item-title>
                          <span>Titulaciones</span>
                        </v-list-item-title>
                      </v-list-item>
                    </router-link>

                    <!--Ver/Editar titulaciones-->
                    <router-link :to=courseFaq(item)
                                 target="_blank"
                                 class="link-unestyled"
                                 v-if="url_type === 'courses'"
                    >
                      <v-list-item>
                        <v-list-item-title>
                          <span>FAQ</span>
                        </v-list-item-title>
                      </v-list-item>
                    </router-link>

                    <!--Crear url a partir de otra-->
                    <v-list-item @click="showDialog(item.url, item.id_idioma, item.id_portal)">
                      <v-list-item-title>
                        <span>Url relacionadas</span>
                      </v-list-item-title>
                    </v-list-item>

                    <!--Crear url a partir de otra-->
                    <v-list-item @click="selectSEOURL(item.url)" v-if="url_type === 'blogs'">
                      <v-list-item-title>
                        <span>Copiar a</span>
                      </v-list-item-title>
                    </v-list-item>

                    <!--Creación de comentario-->
                    <!--                                        <v-list-item @click="showForm(item.url)" v-if="url_type === 'courses'">
                                                                <v-list-item-title>
                                                                    <span>Crear comentario</span>
                                                                </v-list-item-title>
                                                            </v-list-item>-->


                    <!--Activar/Desactivar url-->
                    <v-list-item @click="displayDialogActive(item, item.activa)"
                                 v-if="url_type === 'blogs'">
                      <v-list-item-title>
                        <span v-if="item.activa===1">Desactivar url</span>
                        <span v-else>Activar url</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </Vue3EasyDataTable>
          </v-col>
        </v-row>

      </template>
      <template v-else>
        <v-row>
          <v-col cols="12">
            <span>Selecciona un idioma para continuar.</span>
          </v-col>
        </v-row>
      </template>
      <v-dialog
          v-model="open_modal_url" persistent
      >
        <ModalUrl
            :showDialog="showDialog"
            :url_data="selected_url"
            :portals="portals"
            :url_type="url_type"
        >
        </ModalUrl>
      </v-dialog>

      <v-dialog v-model="open_modal_comment" persistent>
        <FormComment title_modal="Crear comentario"
                     :displayDialog="displayDialogComment"
                     :url_course="indentifier_course"
        >
        </FormComment>
      </v-dialog>

      <v-dialog v-model="open_modal_edit_url" persistent>
        <ModalEditUrl :url_select_edit="url_select_edit"
                      :type_url="type_url_selected"
                      :loading="loading_modal_edit_url"
                      :displayDialogEdit="displayDialogEdit"
                      :saveUrlEdit="saveUrlEdit"
        ></ModalEditUrl>
      </v-dialog>

      <v-dialog v-model="open_modal_teacher" persistent>
        <TeachersModal :getCourses="getURLsByPortal"
                       :getCourseTeachers="getCourseTeachers"
                       :teachers_specific="specific_teacher_by_course"
                       :teachers_areas="areas_teachers_by_course"
                       :displayDialog="displayDialogTeacher"
                       :indentifier_course="indentifier_course"
                       title="Añadir Docente"/>
      </v-dialog>
      <v-dialog v-model="open_modal_seo_copy" persistent>
        <CopySEOModal
            :displayDialog="displayDialogCopySEO"
            :url="selected_url"
            :url_type="url_type"
            :portals="portals"
        >
        </CopySEOModal>
      </v-dialog>
    </template>
  </v-container>

</template>

<script>
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import {computed, onBeforeMount, ref, watch} from "vue";
import ModalUrl from "@/components/UrlList/ModalUrl.vue";
import {useStore} from "vuex";
import {exportXLSX, formatDate, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {getTeachersFromCourseWS, getURLByPortalWS, changeStatusUrlWS, updateCourseSincro} from "@/utils/courses";
import {useRoute, useRouter} from "vue-router";
import {hasPermission, userHaveAllowedRol} from "@/utils/auth";
import FormRanking from "@/views/Ranking/RankingCourseView.vue";
import FormComment from "@/components/Comments/FormComment.vue";
import TeachersModal from "@/components/Teachers/TeachersModal.vue";
import ModalEditUrl from "@/components/UrlList/ModalEditUrl.vue";
import {changeURLWS, queueExportBlogsFileWS} from "@/utils/editor_course_blog";
import FilterUrl from "@/components/UrlList/FilterUrl.vue";
import BreadCrumb from "@/components/Navigation/BreadCrumb.vue";
import CopySEOModal from "@/components/CopySEO/CopySEOModal.vue";
import Loading from "@/components/Generic/Loading.vue";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import {queueExportRedirectionsFileWS} from "@/utils/url_redirections";
import {toUpperCase} from "uri-js/dist/esnext/util";


export default {
  name: "UrlListView",
  methods: {toUpperCase, variableIsDefined, formatDate},
  components: {
    Loading,
    BreadCrumb,
    FilterUrl,
    CopySEOModal,
    ModalEditUrl,
    TeachersModal,
    FormRanking,
    FormComment,
    ModalUrl,
    Vue3EasyDataTable,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter()
    const create_redirection_loading = ref(false);
    let general_loading = ref(false);
    const check_exact_search = ref(false);

    let loading = ref(false);
    let open_modal_url = ref(false);
    let selected_url = ref({});
    let server_items_length = ref(0);
    let items = ref([]);
    let filter_search = ref({url_search: "", check_get_draft: 0, state_urls: 1});
    let language = computed(() => store.getters.getLanguageISO);
    let portal = computed(() => store.getters.getPortalID);
    let portals = ref([]);
    let pending_language = ref(false);
    let search_url_type = ref('')
    let url_type = ref('courses');
    let headers = ref([]);
    let id_url = ref();
    const table_items = ref([]);

    let indentifier_course = ref()
    let open_modal_comment = ref(false);
    let open_modal_teacher = ref(false);
    let open_modal_seo_copy = ref(false);
    let open_modal_active = ref(false);
    let areas_teachers_by_course = ref([]);
    let specific_teacher_by_course = ref([]);
    let disabled_export = ref(true);
    let code_innotutor = ref();
    let loading_sincro = ref(false);
    let can_update_course = ref(false);
    let open_modal_edit_url = ref(false)
    let url_select_edit = ref()
    let loading_modal_edit_url = ref(false)

    let can_edit_blog_url = ref(false)
    let can_edit_course_url = ref(false)

    let portals_can_edit = []
    let url_selected_active = {}
    let type_url_selected = ref()

    let server_options = ref({
      page: 1,
      rowsPerPage: 20,
    })


    onBeforeMount(async () => {
      general_loading.value = true
      initSweetAlert()
      if (variableIsDefined(route.query.type)) {
        search_url_type.value = route.query.type
      } else {
        search_url_type.value = store.getters.getTypeUrl
      }

      can_update_course.value = userHaveAllowedRol('actualizacion_cursos')
      portals.value = computed(() => store.getters.getStoredPortals).value
      portals.value.forEach((portal) => {
        if (portal.nombre.toLowerCase().includes('euroinnova')) {
          portals_can_edit.push(portal)
        }
      })

      can_edit_blog_url.value = userHaveAllowedRol('MODIFICAR_URL_BLOG')
      can_edit_course_url.value = userHaveAllowedRol('MODIFICAR_URL_CURSO')

      await hasPermission(route);

      language.value !== 0 ? await searchUrlType(search_url_type.value) : pending_language.value = true
      general_loading.value = false
    });


    watch(server_options, async () => {
          await getURLsByPortal();
        },
        {deep: true}
    );

    watch(portal, async () => {
        items.value = [];
        await searchUrlType(search_url_type.value);
    });

    function isTableBlog() {
      let table_class;
      url_type.value === 'blogs' ? table_class = 'table-blog' : table_class = '';
      return table_class;
    }

    function setDatatableHeader() {
      if (url_type.value === 'blogs') {
        headers.value = [
          {text: 'URL', value: 'url', sortable: true},
          {text: ' ', value: 'edit_url'},
          {text: ' ', value: 'btn_active'},
          {text: 'FECHA CREACIÓN', value: 'fecha_creacion', sortable: true},
          {text: 'FECHA ACTUALIZACIÓN', value: 'fecha_actualizacion', sortable: true},
          {text: 'PUBLICADO', value: 'published'},

          {text: 'ACCIONES', value: 'btn_actions'},
        ]
      } else if (url_type.value === 'courses') {
        headers.value = [
          {text: 'URL', value: 'url', sortable: true},
          {text: ' ', value: 'edit_url'},
          {text: ' ', value: 'btn_active'},
          {text: 'CÓDIGO INNOTUTOR', value: 'codigo_innotutor'},
          {text: 'ID SIMO', value: 'id_simo'},
          {text: 'FECHA CREACIÓN', value: 'fecha_creacion', sortable: true},
          {text: 'FECHA ACTUALIZACIÓN', value: 'fecha_actualizacion', sortable: true},
          {text: 'NOMBRE CURSO', value: 'titulo_curso', sortable: true},
          {text: 'DOCENTES', value: 'count_claustro'},
          {text: 'PUBLICADO', value: 'published'},

          {text: 'ACCIONES', value: 'btn_actions'},
        ]
      }
    }

    async function showTeachers(url) {
      displayDialogTeacher();
      await getCourseTeachers(url)
    }

    function displayDialogTeacher() {
      open_modal_teacher.value = !open_modal_teacher.value
    }

    async function getURLsByPortal() {

      loading.value = true;
      let order_by = '';
      let order_direction = '';

      if (variableIsDefined(server_options.value.sortBy) && variableIsDefined(server_options.value.sortType)) {
        order_by = server_options.value.sortBy;
        order_direction = server_options.value.sortType;
      }
      let response_all_urls = await getURLByPortalWS(url_type.value, server_options.value.page, server_options.value.rowsPerPage, filter_search.value, order_direction, order_by);

      if (response_all_urls['data'] && response_all_urls['result']) {
        items.value = response_all_urls['data'].data;
        items.value.forEach((url) => {
          url.active_edit_url = false
          if (url.activa === 1 && url.published === 1) {
            portals_can_edit.forEach((portal) => {
              if (url.id_portal === portal.id_portal) {
                url.active_edit_url = true
              }
            })
          }
        })
        server_items_length.value = response_all_urls['data'].total;
      } else {
        showNotification("error", response_all_urls['errors'])
      }
      disabled_export.value = server_items_length.value === 0
      loading.value = false
    }

    async function editUrl(url_selected, type_url) {
      loading_modal_edit_url.value = true
      displayDialogEdit()
      url_select_edit.value = url_selected
      type_url_selected.value = type_url
      loading_modal_edit_url.value = false
    }

    async function saveUrlEdit(new_url) {
      let response = await changeURLWS(url_select_edit.value, new_url)
      if (response['result']) {
        await getURLsByPortal();
        showNotification('success', 'Se ha renombrado la URL correctamente')
      } else {
        showNotification('error', response['errors'])
      }
    }

    async function searchUrlType(type) {
      if (type.toLowerCase() === 'cursos') {
        url_type.value = 'courses';
        search_url_type.value = 'Cursos'
        await router.push({path: '/bridge/listado-urls', query: {type: 'Cursos'}})

      } else if (type.toLowerCase() === 'blogs') {
        url_type.value = 'blogs';
        search_url_type.value = 'Blogs'
        await router.push({path: '/bridge/listado-urls', query: {type: 'Blogs'}})
      }

      store.commit('setTypeUrl', route.query.type);
      localStorage.setItem('type_url', (route.query.type).toString())

      setDatatableHeader();
      await getURLsByPortal();
    }

    async function showForm(url_course) {
      indentifier_course.value = url_course
      displayDialogComment();
    }

    function displayDialogComment() {
      open_modal_comment.value = !open_modal_comment.value
    }

    async function setQueueExcelElement() {
      await fireSweetAlert('Descargar Excel', queueExportFiles, 'Se enviará el fichero por correo al finalizar el proceso de creación')
    }

    async function queueExportFiles() {

      create_redirection_loading.value = true
      let response = await queueExportBlogsFileWS(filter_search.value, search_url_type);

      if (response['result']) {
        showNotification('success', 'Proceso encolado con éxito')
      } else {
        showNotification('error', response['errors'])
      }
      create_redirection_loading.value = false
    }

    function displayDialogEdit() {
      open_modal_edit_url.value = !open_modal_edit_url.value
    }

    function displayDialogCopySEO() {
      open_modal_seo_copy.value = !open_modal_seo_copy.value;
    }

    function selectSEOURL(url) {
      selected_url.value = {
        'url': url,
        'url_type': url_type.value,
      };

      displayDialogCopySEO();
    }

    function showDialog(url, id_idioma, id_portal) {
      selected_url.value = {
        'url': url,
        'id_language': id_idioma,
        'id_portal': id_portal,
      }
      open_modal_url.value = !open_modal_url.value;
    }

    function editItem(item, seo = true) {
      const portal = store.getters.getPortalID;
      const language = store.getters.getLanguageISO;
      if (seo) {
        if (item.tipo_url === 'contenido_seo') {
          store.commit('setTypeUrl', 'Blogs');
          localStorage.setItem('type_url', 'Blogs');
        } else {
          store.commit('setTypeUrl', 'Cursos');
          localStorage.setItem('type_url', 'Cursos');
        }

        // const content = item.tipo_url === 'contenido_seo' ? 'Blogs' : 'Cursos';
        return '/bridge/editor-seo?url=' + item.url + '&portal=' + portal + '&language=' + language;
      }
      return '/bridge/editor-curso?url=' + item.url + '&portal=' + portal + '&language=' + language;
    }

    function ranking_url(item) {
      return '/bridge/ranking-curso?url=' + item.url;
    }

    function courseUrl(id_url) {
      return '/bridge/titulaciones-curso?url-curso=' + id_url
    }

    function courseFaq(item) {
      return '/bridge/faqs-curso?url=' + item.url;
    }

    function addURL() {
      selected_url.value = [];
      open_modal_url.value = !open_modal_url.value;
    }

    function exportData() {
      exportXLSX("Cursos", url_type.value + "-exportados.xlsx", items.value)
    }

    async function searchCodeSincro() {
      loading_sincro.value = true;
      let response = await updateCourseSincro(code_innotutor.value);
      if (response['result']) {
        showNotification('success', 'Se ha actualizado satisfactoriamente el curso con código innotutor: ' + code_innotutor.value)
        await getURLsByPortal();
      } else {
        showNotification('error', response['errors'])
      }
      loading_sincro.value = false;
    }

    async function getCourseTeachers(url) {
      areas_teachers_by_course.value = []
      specific_teacher_by_course.value = []
      let response = await getTeachersFromCourseWS(url)
      if (response['data'] && response['result']) {
        if (response['data']['specific'] !== undefined) {
          Object.entries(response['data']['specific']).forEach(course_teacher => {
            specific_teacher_by_course.value.push(course_teacher[1])
          })
        }
        if (response['data']['category'] !== undefined) {
          Object.entries(response['data']['category']).forEach(area_teacher => {
            areas_teachers_by_course.value.push(area_teacher[1])
          })
        }
        indentifier_course.value = url
      } else {
        showNotification('error', response['errors'])
      }
    }

    async function getApplyFilter(filter) {
      server_options.value.page = 1
      filter_search.value = filter
      await getURLsByPortal();
    }

    async function deleteFilter(cleanFilter) {
      filter_search.value = cleanFilter
      await getURLsByPortal();
    }

    function displayDialogActive(item) {
      let state
      url_selected_active = item
      state = item.activa === 1 ? 'desactivar' : 'activar';
      fireSweetAlert(`¿Quieres ${state} el estado de la url?`, changeStateUrl)
    }

    async function changeStateUrl() {
      let state
      if (url_selected_active.activa === 1) {
        state = 0
      } else {
        state = 1
      }

      let response = await changeStatusUrlWS(url_selected_active, state)
      if (response['result']) {
        showNotification('success', response['data']['message'])
        await getURLsByPortal()
      } else {
        showNotification('error', response['errors'])
      }
    }

    // Si el tipo de url es blog devuelve si el usuario tiene permiso para editar las url de los blog
    // si no devuelve el permiso para editar la url de los cursos
    function getEditPermissions() {
      return url_type.value === 'blogs' ? can_edit_blog_url.value : can_edit_course_url.value
    }

    return {

      addURL,
      courseFaq,
      courseUrl,
      deleteFilter,
      displayDialogActive,
      displayDialogComment,
      displayDialogCopySEO,
      displayDialogEdit,
      displayDialogTeacher,
      editItem,
      editUrl,
      exportData,
      getApplyFilter,
      getCourseTeachers,
      getEditPermissions,
      getURLsByPortal,
      isTableBlog,
      ranking_url,
      saveUrlEdit,
      searchCodeSincro,
      searchUrlType,
      selectSEOURL,
      setQueueExcelElement,
      showDialog,
      showForm,
      showTeachers,

      areas_teachers_by_course,
      can_edit_blog_url,
      can_edit_course_url,
      can_update_course,
      code_innotutor,
      create_redirection_loading,
      disabled_export,
      filter_search,
      general_loading,
      headers,
      id_url,
      indentifier_course,
      items,
      loading,
      loading_modal_edit_url,
      loading_sincro,
      open_modal_active,
      open_modal_comment,
      open_modal_edit_url,
      open_modal_seo_copy,
      open_modal_teacher,
      open_modal_url,
      pending_language,
      portals,
      search_url_type,
      selected_url,
      server_items_length,
      server_options,
      specific_teacher_by_course,
      type_url_selected,
      url_select_edit,
      url_type,
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/views/url-list.scss";
@import "@/styles/views/courses.scss";
</style>