import axios from "axios";
import {getAicoreToken} from "@/utils/aux_functions";

export async function aicoreBackendPetition(type, url, data, {petition = 'ajax', content_type = 'application/x-www-form-urlencoded; charset=UTF-8', nlp = false} = {}) {

    const response = await getAicoreToken({});
    let token = response['access_token']

    let base_url = nlp ? process.env.VUE_APP_AICORE_NLP_URL_API : process.env.VUE_APP_AICORE_URL_API;
    
    switch (petition.toLowerCase()) {
        case 'ajax':
            return $.ajax({
                type: type,
                url: base_url + url,
                data: data,
                headers: {
                    'content-type': content_type,
                    'Authorization': 'Bearer ' + token
                }
            });
        case 'axios':
            switch (type.toLowerCase()) {
                case 'post':
                    return axios.post(process.env.VUE_APP_AICORE_URL_API + url,
                        data,
                        {
                            headers: {
                                'content-type': content_type,
                                'Authorization': 'Bearer ' + token
                            }
                        }
                    )
                case 'get':
                    return axios.get(process.env.VUE_APP_AICORE_URL_API + url + data,
                        {
                            headers: {
                                'content-type': content_type,
                                'Authorization': 'Bearer ' + token
                            }
                        }
                    )
            }
    }
}

