<template>
    <div v-if="general_loading" class="general-loading">
        <Loading class_loading="loading-gif-s"/>
    </div>
    <template v-else>
        <v-row>
            <v-col cols="12" md="4" class="align-self-center">
                <v-select variant="underlined"
                          class="input-error select-primary"
                          auto-select-first
                          hide-details
                          :items="all_portals"
                          item-title="nombre"
                          item-value="id_portal"
                          v-model="form_data_customization.id_portal"
                          @update:modelValue="changePortalSelection()"
                          placeholder="PORTAL"
                          :menu-props="{ width: '500'}"
                          no-data-text="No hay datos disponibles"
                          :error="variableIsDefined(form_error.id_portal)"
                          :disabled="form_data_customization.locked"
                >
                </v-select>
                <span v-if="variableIsDefined(form_error.id_portal)"
                      class="input-error msg-error">{{ form_error.id_portal }}</span>
            </v-col>

            <v-col cols="12" md="4" class="align-self-center">
                <v-select variant="underlined"
                          class="input-error select-primary"
                          auto-select-first
                          hide-details
                          :items="all_languages"
                          item-title="nombre"
                          item-value="iso_6391"
                          v-model="form_data_customization.iso_6391"
                          placeholder="IDIOMA"
                          :menu-props="{ width: '200px'}"
                          no-data-text="No hay datos disponibles"
                          :error="variableIsDefined(form_error.iso_6391)"
                          :disabled="!variableIsDefined(form_data_customization.id_portal) ||  form_data_customization.locked"
                >
                </v-select>
                <span v-if="variableIsDefined(form_error.iso_6391)"
                      class="input-error msg-error">{{ form_error.iso_6391 }}</span>
            </v-col>
            <v-col cols="12" md="4" class="align-self-center text-center personalize-btn-degree">
                <img :src="require('@/assets/icons/box-pencil.svg')"
                     alt="Editar"
                     class="box-pensil"
                     title="Personalización"
                     id="copy"
                     @click="optionsCustomization()"
                >
                <v-btn v-if="disabled_general" class="btn button-bg-white" @click="addCustomize"
                       :loading="loading_get_files">AÑADIR
                </v-btn>
                <v-btn v-if="!disabled_general" class="btn button-bg-white" @click="editCustomize(id_field)"
                       :loading="loading_save_file">ACTUALIZAR
                </v-btn>
                <img
                    v-if="(!disabled_general && !contains_data) || ((variableIsDefined(form_data_customization.locked) && !form_data_customization.locked) && contains_data)"
                    class="btn-erase ms-3" @click="eraseElement"
                    :src="require('@/assets/icons/close-circle.svg')" alt="Cancelar" title="Cancelar">

                <img :src="require('@/assets/icons/delete.svg')"
                     v-if="!disabled_general && contains_data && form_data_customization.locked"
                     alt="Eliminar"
                     class="btn-delete-customization ms-3"
                     title="Eliminar"
                     @click="confirmDelete(id_field)"
                     :loading="loading_delete_degree"
                >
            </v-col>
        </v-row>
        <v-row v-if="options_customization_file" class="box-general-img personalize-scroll">
            <v-col cols="12" sm="6" md="4" lg="3" class="box-files" v-for="image in all_img_active">
                <v-checkbox v-model="form_data_customization.img_selected" class="check-active"
                            :value="image.id_file" :class="{error :form_error.img_selected}" hide-details>
                    <template v-slot:label>
                        <v-row>
                            <v-col cols="12" class="box-title">
                                <span class="title-check-customization"> {{ image.fileName }}</span>
                            </v-col>
                            <v-col cols="12">
                                <v-tooltip location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <div class="box-img">
                                            <v-img class="image-check-customization" :src=image.public_route></v-img>
                                        </div>
                                    </template>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>

        <v-row v-if="options_customization" class="personalize-btn-degree">
            <v-col cols="12" class="text-start">
                 <span v-if="form_error.img_selected"
                       class="input-error msg-error">{{ form_error.img_selected }}</span>
            </v-col>

            <v-col cols="12" sm="2" md="2" class="text-center align-self-center">
                <img :src="require('@/assets/icons/navbar/library.svg')"
                     alt="Editar"
                     class="box-pensil"
                     title="Cambiar imagen"
                     id="copy"
                     @click="optionsAddImg(options_file)"
                >
            </v-col>

            <v-col cols="12" sm="10" md="4">
                <v-text-field variant="underlined"
                              placeholder="Título"
                              :class="{error :form_error.title}"
                              class="input-error text-field-primary"
                              v-model.trim="form_data_customization.title"
                              hide-details
                ></v-text-field>
                <span v-if="form_error.title"
                      class="input-error msg-error">{{ form_error.title }}</span>
            </v-col>

            <v-col cols="12" :sm="contains_data ? 9 : 12" :md="contains_data ? 4 : 6">
                <v-text-field variant="underlined"
                              placeholder="Texto alternativo"
                              :class="{error :form_error.txt_alt}"
                              class="input-error text-field-primary"
                              v-model.trim="form_data_customization.txt_alt"
                              hide-details
                ></v-text-field>
                <span v-if="form_error.txt_alt"
                      class="input-error msg-error">{{ form_error.txt_alt }}</span>
            </v-col>
          <v-col v-if="contains_data" cols="12" sm="3" md="2">
            <div style="display: inline-block; width: auto ">
              <v-checkbox label="Estado"
                          v-model="form_data_customization.status"
                          :true-value="1"
                          :false-value="0"
                          color="blue"
                          hide-details></v-checkbox>
            </div>
          </v-col>
            <v-col cols="12" :sm="contains_data ? 9 : 12" :md="contains_data ? 4 : 6">
              <v-select
                  class="input-error select-secondary"
                  v-model="form_data_customization.id_tag"
                  :items="all_tags"
                  item-title="tag"
                  item-value="id_tag"
                  placeholder="Tag"
                  hide-details
                  bg-color="white"
                  color="transparent"
                  :error="form_error.id_tag"
                  :menu-props="{
                      minWidth: 'auto',
                      maxWidth: '100%'
                  }"
                  no-data-text="No hay datos disponibles"
              ></v-select>
              <span v-if="form_error.id_tag"
                    class="input-error msg-error">El campo no puede estar vacío</span>
            </v-col>
        </v-row>
    </template>
</template>

<script>
import {onBeforeMount, ref, watch} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {getNameLanguage, getNamePortal} from "@/utils/portal";
import Loading from "@/components/Generic/Loading.vue";
import * as Yup from "yup";
import store from "@/store";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import {deleteDegreeWS} from "@/utils/crud_degrees";

export default {
    name: "DegreeCustomization",
    methods: {variableIsDefined},
    components: {Loading},
    props: {
        increaseID: Function,
        all_img_active: Array,
        all_portals: Array,
        all_tags: Array,
        type_entity: Object,
        degree_data: Object,
        fields: Map,
        id_field: Number,
        contains_data: Boolean
    },

    setup(props) {
        let general_loading = ref(false)
        let loading_save_file = ref(false)
        let loading_delete_degree = ref(false)
        let loading_get_files = ref(false)
        let disabled_general = ref(true)
        let form_data_customization = ref({'status': 1})
        let form_error = ref({})
        let all_languages = ref([])
        let all_options_customization = []
        let all_tags = ref([])
        let options = ref(true)
        let options_file = ref(true)
        let options_customization = ref(false)
        let options_customization_file = ref(false)
        let degree_delete

        onBeforeMount(async () => {
            general_loading.value = true
            await autocompleteValues()
            initSweetAlert()
            all_tags.value = props.all_tags
            general_loading.value = false
        })

        /*OBTIENE LOS IDIOMAS REFERENTES AL PORTAL SELECCIONADO*/
        function showLanguages() {
            props.all_portals.forEach(portal => {
                if (portal.id_portal === form_data_customization.value.id_portal) {
                    all_languages.value = portal.languages;
                }
            })
        }

      function showTags() {
        props.all_portals.forEach(tag => {
          if (tag.id_tag === form_data_customization.value.id_tag) {
            all_tags.value = tag.id_tag;
          }
        })
      }

        /*VACÍA EL IDIOMA AL CAMBIAR EL PORTAL*/
        function changePortalSelection() {
            form_data_customization.value.iso_6391 = null;
            showLanguages();
        }

        /*VALIDACIÓN PORTAL E IDIOMA*/
        async function validateOptions(update = false) {
            form_error.value = {}
            if (!variableIsDefined(form_data_customization.value.id_portal)) {
                form_error.value.id_portal = 'El campo no puede estar vacío'
            } else {
                if (form_data_customization.value.id_portal === store.getters.getPortalID) {
                    form_error.value.id_portal = 'El portal seleccionado es el principal'
                }
            }
            if (!variableIsDefined(form_data_customization.value.iso_6391)) {
                form_error.value.iso_6391 = 'El campo no puede estar vacío'
            } else {
                if (form_data_customization.value.id_portal === store.getters.getPortalID && variableIsDefined(form_data_customization.value.iso_6391)) {
                    form_error.value.iso_6391 = 'El idioma seleccionado es el principal'
                }
            }

            if (!update) {
                /*POR SI AÑADE DOS PORTALES IGUALES*/
                for (let [key, value] of props.fields.entries()) {
                    if (variableIsDefined(value)) {
                        if (value['id_portal'] === form_data_customization.value.id_portal &&
                            value['language'] === form_data_customization.value.iso_6391
                        ) {
                            form_error.value.id_portal = 'El portal seleccionado ya está añadido';
                            form_error.value.iso_6391 = 'El idioma seleccionado ya está añadido';
                            break;
                        }
                    }
                }
            }

            if (variableIsDefined(props.degree_data.id)) {
                const schemaForm = Yup.object().shape({
                    title: Yup.string().nullable().required('Debe rellenar el título'),
                    txt_alt: Yup.string().nullable().required('Debe rellenar el texto alternativo'),
                    id_tag: Yup.string().nullable().required('Debe rellenar el tag'),
                    img_selected: Yup.mixed()
                        .nullable()
                        .notOneOf([false], 'Debe seleccionar una imagen')
                        .notOneOf([undefined], 'Debe seleccionar una imagen')
                });

                try {
                    await schemaForm.validate(form_data_customization.value, {abortEarly: false});
                } catch (error) {
                    error.inner.forEach((error) => {
                        form_error.value[error.path] = error.message;
                    })
                }
            }

            return Object.keys(form_error.value).length <= 0;
        }

        /*OPCIONES PARA EDITAR PERSONALIZACIÓN*/
        function optionsCustomization() {
            if (!options_customization.value) {
                options_customization.value = !options_customization.value
            } else {
                options_customization.value = !options_customization.value
                options_customization_file.value = false
                options_file.value = !options_file.value
            }
            options.value = !options.value
        }

        /*OPCIONES IMAGENES*/
        function optionsAddImg(item) {
            // options_customization_file.value = item;
            // options_file.value = !item
            options_customization_file.value = !options_customization_file.value;
            options_file.value = !options_file.value
        }

        /*AÑADE LA PERSONALIZACIÓN POR PORTAL E IDIOMA*/
        async function addCustomize() {
            if (await validateOptions()) {
                loading_get_files.value = true
                form_data_customization.value.locked = false
                all_options_customization.push({
                    "locked": false,
                    "id_portal": form_data_customization.value.id_portal,
                    "language": form_data_customization.value.iso_6391,
                    "id_file": form_data_customization.value.img_selected,
                    "alt_text": form_data_customization.value.txt_alt,
                    "title": form_data_customization.value.title,
                    "active": form_data_customization.value.status ?? 1,
                    "id_tag": form_data_customization.value.id_tag,
                })
                let id = props.increaseID();
                let next_id = id + 1;
                all_options_customization.forEach((degree) => {
                    props.fields.set(id, degree);
                })

                props.fields.set(next_id, '');
                disableBtn();
                loading_get_files.value = false
            } else {
                if (variableIsDefined(props.degree_data.id)) {
                    options_customization.value = true
                }
            }
        }

        /*RELLENA LOS CAMPOS DE LA PERSONALIZACIÓN*/
        function autocompleteValues() {
            if (variableIsDefined(props.fields)) {
                /*COMPRUEBA SI HAY DATOS EN EL MAP, PARA RELLENAR LA VISTA*/
                if (variableIsDefined(props.fields.get(props.id_field))) {
                    form_data_customization.value = {
                        'locked': props.fields.get(props.id_field).locked,
                        'id_portal': props.fields.get(props.id_field).id_portal,
                        'iso_6391': props.fields.get(props.id_field).id_idioma,
                        'img_selected': props.fields.get(props.id_field).id_file,
                        'title': props.fields.get(props.id_field).title,
                        'txt_alt': props.fields.get(props.id_field).alt_text,
                        'status': props.fields.get(props.id_field).activo,
                        'id_tag': props.fields.get(props.id_field).id_tag,
                    };
                    showLanguages();
                    showTags();

                    let language_index = all_languages.value.findIndex((element) => element.iso_6391 === props.fields.get(props.id_field).language);

                    if (variableIsDefined(all_languages.value[language_index])) {
                        form_data_customization.value.iso_6391 = all_languages.value[language_index].iso_6391;
                        disableBtn();
                    }
                }
            }
        }

        function disableBtn() {
            return disabled_general.value = !disabled_general.value;
        }

        function eraseElement() {
            props.fields.delete(props.id_field);
        }

        /*ACTUALIZA EL MAPA (BOTÓN ACTUALIZAR)*/
        async function editCustomize(id_map) {
            if (await validateOptions(true)) {
                if (await validateEditCustomize(id_map)) {
                    loading_save_file.value = true;
                    setTimeout(() => {
                        if (variableIsDefined(props.fields) && props.fields.has(id_map)) {
                            let data_degree_selected = {
                                id_portal: form_data_customization.value.id_portal,
                                language: form_data_customization.value.iso_6391,
                                id_file: form_data_customization.value.img_selected,
                                alt_text: form_data_customization.value.txt_alt,
                                title: form_data_customization.value.title,
                                active: form_data_customization.value.status,
                                id_tag: form_data_customization.value.id_tag,
                            }
                            props.fields.set(id_map, data_degree_selected);
                        }
                        loading_save_file.value = false;
                    }, 500);
                } else {
                    props.fields.set(id_map, '');
                }
            } else {
                if (variableIsDefined(props.degree_data.id)) {
                    options_customization.value = true
                }

            }
        }

        async function validateEditCustomize(id_map) {
            form_error.value = {}
            for (let [clave, valor] of props.fields) {
                if (clave !== id_map && valor['id_portal'] === form_data_customization.value['id_portal'] && valor['language'] === form_data_customization.value['iso_6391']) {
                    form_error.value.id_portal = 'El portal seleccionado ya está añadido';
                    form_error.value.iso_6391 = 'El idioma seleccionado ya está añadido';
                }
            }
            if (variableIsDefined(props.degree_data.id)) {
                const schemaForm = Yup.object().shape({
                    title: Yup.string().nullable().required('Debe rellenar el título'),
                    txt_alt: Yup.string().nullable().required('Debe rellenar el texto alternativo'),
                    id_tag: Yup.string().nullable().required('Debe rellenar el tag'),
                    img_selected: Yup.mixed()
                        .nullable()
                        .notOneOf([false], 'Debe seleccionar una imagen')
                        .notOneOf([undefined], 'Debe seleccionar una imagen')
                });

                try {
                    await schemaForm.validate(form_data_customization.value, {abortEarly: false});
                } catch (error) {
                    error.inner.forEach((error) => {
                        form_error.value[error.path] = error.message;
                    })
                }
            }

            return Object.keys(form_error.value).length <= 0;
        }

        async function confirmDelete(id_map) {
            degree_delete = id_map
            let data_degree_selected = props.fields.get(degree_delete);
            let title_delete = `¿Desea eliminar el ${props.type_entity.singular} para el portal ${getNamePortal(data_degree_selected.id_portal)} e idioma ${getNameLanguage(data_degree_selected.language, 'iso_6391')} ?`
            await fireSweetAlert(title_delete, deleteDegree)
        }


        /*ELIMINA UN LOGO DE UN PORTAL DE LAS PERSONALIZACIONES*/
        async function deleteDegree() {
            loading_delete_degree.value = true
            setTimeout(async () => {
                if (variableIsDefined(props.fields) && props.fields.has(degree_delete)) {
                    let data_degree_selected = props.fields.get(degree_delete);
                    let response = await deleteDegreeWS(props.degree_data.id, data_degree_selected.id_portal, data_degree_selected.language, props.type_entity.related_entity)
                    if (response['result']) {
                        showNotification('success', response['data'])
                    } else {
                        showNotification('error', response['errors'])
                    }
                    props.fields.delete(degree_delete);
                }
                loading_delete_degree.value = false
            }, 500);
        }


        return {
            addCustomize,
            optionsCustomization,
            optionsAddImg,
            eraseElement,
            changePortalSelection,
            editCustomize,
            confirmDelete,

            general_loading,
            loading_get_files,
            loading_save_file,
            loading_delete_degree,
            disabled_general,
            form_data_customization,
            form_error,
            all_languages,
            all_tags,
            options,
            options_file,
            options_customization,
            options_customization_file,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/components/degree-customization.scss";
</style>